import { TableContainer, Table, TableBody } from "@material-ui/core";

import { THCPTableRow, TPagination } from "Shared";
import { VerificationTaskFragment } from "Shared/graphql/generated";

import { Header, Row, HCPTableContextProvider, Pagination } from "./index";

export type HCPTableProps = {
  readonly?: boolean;
  count: number;
  rows: THCPTableRow[];
  onHCPTaskSubmit?: (task: VerificationTaskFragment) => void;
  pagination: TPagination;
};

const tableStyles = {
  maxWidth: "1232px"
};

export const HCPTable = ({
  readonly = false,
  onHCPTaskSubmit,
  count,
  pagination,
  rows = []
}: HCPTableProps) => {
  return (
    <HCPTableContextProvider readonly={readonly}>
      <fieldset disabled={readonly} style={{ border: "none" }}>
        <TableContainer style={tableStyles}>
          <Table>
            <Header />
            <TableBody>
              {rows.map((row) => (
                <Row key={row.id} {...row} onHCPTaskSubmit={onHCPTaskSubmit} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination totalItems={count} pagination={pagination} />
      </fieldset>
    </HCPTableContextProvider>
  );
};
