import { NOT_AVAILABLE } from "Shared";

type partialInitialValues = {
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
};
export const getFullNameFromInitialValues = ({
  firstName,
  middleName,
  lastName
}: partialInitialValues) =>
  [firstName, middleName, lastName].filter((name) => !!name).join(" ") ||
  NOT_AVAILABLE;
