import * as Yup from "yup";

export const npiInitialValues = { npi: "" };

export const npiValidationSchema = Yup.object().shape({
  npi: Yup.string()
    .required("NPI required")
    .test("len", "NPI must be 10 digits", (val) => {
      const patter = new RegExp("^[0-9]{10}$");
      return !!val?.match(patter);
    })
});

export type TNpiValues = typeof npiInitialValues;
