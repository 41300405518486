import { HCPTable } from "OrganizationValidation/components";
import { ExpandSection, GenericContainer } from "Shared";
import { HCPTaskData } from "OrganizationValidation/hooks";
import { VerifyCallContextProvider } from "OrganizationValidation/context";
import { VerificationTaskFragment } from "Shared/graphql/generated";

import { CallAndVerifyDetailsForm } from "./CallAndVerifyDetailsForm";

export type VerifyHCPTaskProps = Omit<HCPTaskData, "initialValues"> & {
  onHCPTaskSubmit: (task: VerificationTaskFragment) => void;
};

export const VerifyHCPTask = ({
  isLoading,
  initialPhoneNumbers,
  rows,
  count,
  onHCPTaskSubmit,
  pagination
}: VerifyHCPTaskProps) => {
  return (
    <ExpandSection title="Verify HCP Affiliations Task">
      <GenericContainer
        isLoading={isLoading}
        data={rows}
        noDataMessage="No Organization Task Found"
      >
        {(rows) => (
          <VerifyCallContextProvider>
            <CallAndVerifyDetailsForm
              initialPhoneNumbers={initialPhoneNumbers}
            />
            <HCPTable
              rows={rows}
              count={count}
              pagination={pagination}
              onHCPTaskSubmit={onHCPTaskSubmit}
            />
          </VerifyCallContextProvider>
        )}
      </GenericContainer>
    </ExpandSection>
  );
};
