import { createContext, useContext, useState } from "react";

import { TNewAffiliation } from "Shared";

type TContextNewAffiliationValues = {
  setNewAffiliation: (value: TNewAffiliation) => void;
  newAffiliationWork: TNewAffiliation;
  isDirty: boolean;
  setIsDirty: (value: boolean) => void;
};

const initialValues: TContextNewAffiliationValues = {
  setNewAffiliation: () => {},
  newAffiliationWork: null,
  isDirty: false,
  setIsDirty: () => {}
};

const newAffiliationContext =
  createContext<TContextNewAffiliationValues>(initialValues);

const { Provider } = newAffiliationContext;

export const NewAffiliationProvider: React.FC = ({ children }) => {
  const [newAffiliationWork, setNewAffiliation] =
    useState<TNewAffiliation>(null);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const value: TContextNewAffiliationValues = {
    setNewAffiliation,
    newAffiliationWork,
    isDirty,
    setIsDirty
  };

  return <Provider value={value}>{children}</Provider>;
};

export const useNewAffiliationContext = () => useContext(newAffiliationContext);
