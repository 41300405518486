import { FieldArray, useField } from "formik";

import { TFromPhoneNumber, FieldArrayItem } from "Shared";

export type VerifyPhoneContactsProps = {
  readonly?: boolean;
  canDelete?: boolean;
  sectionBorder?: boolean;
};

const fieldName = "phoneNumbers";

export const VerifyPhoneContacts = ({
  readonly,
  canDelete,
  sectionBorder
}: VerifyPhoneContactsProps) => {
  const [{ value }] = useField<TFromPhoneNumber[]>(fieldName);

  return (
    <FieldArray name={fieldName}>
      {({ push, remove }) => (
        <FieldArrayItem
          value={value}
          fieldName={fieldName}
          readonly={readonly}
          canDelete={canDelete}
          sectionBorder={sectionBorder}
          push={push}
          remove={remove}
        />
      )}
    </FieldArray>
  );
};
