import { Box, Typography } from "@material-ui/core";

import { getVerificationTaskStatusData } from "../helpers";

export type VerificationTaskStatusProps = {
  completed: boolean;
  isSelected: boolean;
  status?: string;
};

export const VerificationTaskStatus = ({
  completed,
  isSelected,
  status
}: VerificationTaskStatusProps) => {
  const { status: currentStatus, color } = getVerificationTaskStatusData(
    completed,
    status
  );

  return (
    <Box display="flex" justifyContent="end">
      <Typography
        variant="body2"
        color={color}
        style={{ fontWeight: isSelected ? 600 : "normal", width: "100%" }}
      >
        {currentStatus}
      </Typography>
    </Box>
  );
};
