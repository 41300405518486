import { Modal } from "Shared";
import { AlertButtonType } from "Shared/types";

import { AlertPopup } from "./AlertPopup";

const MODAL_TITLE = "Complete the task?";
const CONFIRM_TEXT = "Complete";
const DESCRIPTION_TEXT = "Are you sure you want to complete the task?";

type ConfirmAlertPopupProps = {
  onConfirm: () => void;
  onCancel: () => void;
  isOpen: boolean;
};

export const ConfirmAlertPopup = ({
  onConfirm,
  onCancel,
  isOpen
}: ConfirmAlertPopupProps): JSX.Element => {
  return (
    <Modal isOpen={isOpen} onClose={onCancel} title={MODAL_TITLE}>
      <AlertPopup
        onConfirm={onConfirm}
        onCancel={onCancel}
        confirmButtonText={CONFIRM_TEXT}
        description={DESCRIPTION_TEXT}
        confirmButtonType={AlertButtonType.BLUE}
      />
    </Modal>
  );
};
