import { FormikAutocompleteSingle } from "Shared";
import { useAllowedStatesQuery } from "Shared/graphql/generated";

export const AddStates = ({ name }: { name: string }) => {
  const { loading, data } = useAllowedStatesQuery();

  return (
    <FormikAutocompleteSingle
      label="State"
      name={name}
      options={data?.allowedStates || []}
      disabled={loading}
    />
  );
};
