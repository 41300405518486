import { Formik } from "formik";

import { TOrgVerificationInitialValues } from "Shared";

import {
  OrganizationSectionForm,
  OrganizationSectionFormProps
} from "OrganizationValidation/components";
import { orgTaskValidation } from "OrganizationValidation/helpers";

export type OrganizationVerificationSectionProps =
  OrganizationSectionFormProps & {
    initialValues: TOrgVerificationInitialValues;
  };

export const OrganizationVerificationSection: React.FC<
  OrganizationVerificationSectionProps
> = ({ initialValues, ...rest }) => (
  <Formik
    initialValues={initialValues}
    // since we have 3 different actions
    // we handle all submit logic inside OrganizationActionsContainer component
    onSubmit={() => {}}
    validationSchema={orgTaskValidation}
    validateOnMount
  >
    <OrganizationSectionForm {...rest} />
  </Formik>
);
