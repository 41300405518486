import { Link, useLocation } from "react-router-dom";
import { makeStyles, Typography } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";

import { RouteName } from "App";
import { getCurrentPathWithoutLastPart } from "Shared";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    marginLeft: 10
  },

  link: {
    display: "flex",
    textDecoration: "none",
    marginLeft: 15
  }
}));

const backToPersonNPISearchMessage = "Back to Person NPI search";
const backToOrdIdSearchMessage = "Back to Organization ID search";

export const GoBackLink = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const path = getCurrentPathWithoutLastPart(pathname);

  const AffiliationValidationPath = `/${RouteName.AffiliationValidation}`;
  const OrganizationValidationPath = `/${RouteName.OrganizationValidation}`;

  const goBackPaths = {
    [AffiliationValidationPath]: {
      message: backToPersonNPISearchMessage,
      path: RouteName.NPISearch
    },
    [OrganizationValidationPath]: {
      message: backToOrdIdSearchMessage,
      path: RouteName.OrgIdSearch
    }
  };

  const goBackPath = goBackPaths[path];

  if (!goBackPath) {
    return null;
  }

  return (
    <Link to={goBackPath.path} className={classes.link}>
      <ArrowBack color="primary" />
      <Typography className={classes.title} variant="body2">
        {goBackPath.message}
      </Typography>
    </Link>
  );
};
