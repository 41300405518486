import { useFormikField, RadioGroupProps, RadioGroupField } from "Shared";

type FormikRadioGroupProps = Omit<RadioGroupProps, "setFieldValue" | "value">;

export const FormikRadioGroup = (props: FormikRadioGroupProps): JSX.Element => {
  const { name } = props;
  const { value, setFieldValue } = useFormikField<string>(name);

  const passedProps = { ...props, value, setFieldValue };

  return <RadioGroupField {...passedProps} />;
};
