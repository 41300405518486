import { useNavigate } from "react-router-dom";
import { useCallback } from "react";

import { VerificationTaskFragment } from "Shared/graphql/generated";
import { generateRoutePath, NestedRouteName, RouteName } from "App";

export const usePathTaskHandler = () => {
  const navigate = useNavigate();

  const pathTaskHandler = useCallback(
    (npi: string, tasks: VerificationTaskFragment[]) => {
      if (tasks.length) {
        const firstTask = tasks[0];
        const firstId = firstTask.id;
        navigate(
          generateRoutePath({
            name: [RouteName.AffiliationValidation, NestedRouteName.Task],
            params: { npi, taskId: firstId }
          })
        );
      } else {
        navigate(
          generateRoutePath({
            name: RouteName.AffiliationValidation,
            params: { npi }
          })
        );
      }
    },
    [navigate]
  );

  return {
    pathTaskHandler
  };
};
