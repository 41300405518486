import { Container, Box, Typography, useTheme } from "@material-ui/core";

import { useNavigate, useParams } from "react-router-dom";

import { useEffect } from "react";

import { Theme } from "Shared";
import { NestedRouteName, generateRoutePath } from "App/routes/routesHelpers";
import { usePathTaskHandler } from "Shared/hooks/usePathTaskHandler";
import { VerificationTaskFragment } from "Shared/graphql/generated";

import { VerificationTask, AddNewWorkAffiliation } from "../components";

export type AffiliationTasksListProps = {
  tasks: VerificationTaskFragment[];
};

// TODO: this hidden functionality till new design and logic on back-end
const taskNova310IsCompleted = false;

export const AffiliationTasksList = ({ tasks }: AffiliationTasksListProps) => {
  const { palette } = useTheme<Theme>();
  const navigate = useNavigate();
  const { taskId = "", npi = "" } = useParams();
  const { pathTaskHandler } = usePathTaskHandler();

  const onTaskSelect = (taskId: string) => {
    navigate(
      generateRoutePath({
        isRelative: true,
        name: NestedRouteName.Task,
        params: {
          taskId
        }
      })
    );
  };

  useEffect(() => {
    const isExistTask = tasks.some(({ id }) => id === taskId);

    if (tasks.length && !isExistTask) {
      pathTaskHandler(npi, tasks);
    }
  }, [pathTaskHandler, tasks, taskId, npi]);

  return (
    <Box>
      <Box borderBottom={`1px solid ${palette.grey["300"]}`}>
        <Container>
          <Typography variant="h5">Verify Work Affiliation(s):</Typography>
          <Box marginY={3}>
            {taskNova310IsCompleted && <AddNewWorkAffiliation />}
          </Box>
        </Container>
      </Box>
      <Box maxHeight="100vh" overflow="auto">
        {tasks.map(({ completed, affiliation, id, lastSession }) => (
          <Box
            style={{
              cursor: "pointer"
            }}
            key={id}
            onClick={() => onTaskSelect(id)}
          >
            <VerificationTask
              status={lastSession?.status}
              completed={completed}
              title={affiliation.personInstitution.institution.name || ""}
              orgType={
                affiliation.personInstitution.institution.orgType.orgTypeLevel1
              }
              address={
                affiliation.personInstitution.institution.institutionAddress
                  .address
              }
              isSelected={taskId === id}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
