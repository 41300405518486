import { Outlet, useParams } from "react-router-dom";
import { Box, Typography, CircularProgress } from "@material-ui/core";

import { useSetDocumentTitle, NoSelectedTask, PageProps } from "Shared";

import { useGetVerifications } from "./hooks";
import { Aside } from "./sections";
import { NewAffiliationProvider } from "./context";

export const AffiliationValidation = ({ title }: PageProps) => {
  const { npi, taskId } = useParams<{ npi: string; taskId?: string }>();

  const {
    isLoading,
    person,
    tasks,
    selectedTaskId,
    setSelectedTaskId,
    updateTasks
  } = useGetVerifications(npi);

  useSetDocumentTitle(
    `${title} ${person?.firstName || ""} ${person?.lastName || ""} | ${
      npi || ""
    }
    `
  );

  if (isLoading) {
    return (
      <Box mt={8} display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (person) {
    return (
      <Box flexGrow={1} display="flex">
        <NewAffiliationProvider>
          <Aside
            person={person}
            tasks={tasks}
            setSelectedTaskId={setSelectedTaskId}
            selectedTaskId={selectedTaskId}
          />
          {!taskId && <NoSelectedTask label="No Selected Affiliation" />}
          <Outlet context={{ updateTasks }} />
        </NewAffiliationProvider>
      </Box>
    );
  }

  return (
    <Box mt={8} display="flex" justifyContent="center">
      <Typography variant="h3">NPI not found</Typography>
    </Box>
  );
};
