import { Box } from "@material-ui/core";
import capitalize from "lodash/capitalize";
import { FieldArray } from "formik";

import { DateRangeValues, useFormikField } from "Shared";
import { OperationHourType } from "Shared/graphql/generated";

import {
  initialDateRangeFieldData,
  twentyFourSevenDateRangeData
} from "./hoursOfOperation";
import { HoursOfOperationHeader } from "./HoursOfOperationHeader";
import { OperationHours } from "./OperationHours";

type FormikAddHoursOfOperationsProps = {
  type: OperationHourType;
};

export const FormikAddHoursOfOperations = ({
  type
}: FormikAddHoursOfOperationsProps) => {
  const dateRangeBasename = `hoursOfOperation${capitalize(type)}`;

  const { value, setFieldValue } =
    useFormikField<DateRangeValues[]>(dateRangeBasename);

  const setTwentyFourSeven = (index: number) => {
    const newValue = [...value];
    newValue[index] = twentyFourSevenDateRangeData;
    setFieldValue(newValue);
  };

  return (
    <FieldArray
      name={dateRangeBasename}
      render={({ push, remove }) => (
        <Box>
          <HoursOfOperationHeader
            type={type}
            onAdd={() => push(initialDateRangeFieldData)}
          />
          {value?.map((_, index) => {
            const dateRangeName = `${dateRangeBasename}.${index}`;
            return (
              <OperationHours
                key={dateRangeName}
                dateRangeName={dateRangeName}
                disableDelete={value.length <= 1}
                onRemove={() => remove(index)}
                onApply={() => setTwentyFourSeven(index)}
              />
            );
          })}
        </Box>
      )}
    />
  );
};
