import { createContext, useContext, useMemo, useRef, useCallback } from "react";

import { OrganizationSessionUpdateInput } from "Shared/graphql/generated";

type OrganizationContext = {
  setOrgData: (values: OrganizationSessionUpdateInput) => void;
  orgData: OrganizationSessionUpdateInput;
};

export const OrganizationContext = createContext<OrganizationContext | null>(
  null
);

type OrganizationContextProps = {
  initialValues: OrganizationSessionUpdateInput;
};

// this context is used to pass in organization data to the HCP rows
export const OrganizationContextProvider: React.FC<
  OrganizationContextProps
> = ({ initialValues, children }) => {
  const orgData = useRef<OrganizationSessionUpdateInput>(initialValues);

  const setOrgData = useCallback((value: OrganizationSessionUpdateInput) => {
    orgData.current = value;
  }, []);

  const value = useMemo(
    () => ({ orgData: orgData.current, setOrgData }),
    [setOrgData]
  );

  return (
    <OrganizationContext.Provider value={value}>
      {children}
    </OrganizationContext.Provider>
  );
};

export const useOrgDataContext = () => {
  const context = useContext(OrganizationContext);

  if (!context) {
    throw new Error(
      "Use OrganizationContext inside OrganizationContextProvider"
    );
  }

  return context;
};
