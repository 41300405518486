import { memo, useCallback } from "react";
import intersectionWith from "lodash/intersectionWith";

import { TAutocompleteOption, AutocompleteField, TAutocomplete } from "Shared";

import { FormikMultipleAutocompleteProps } from "./";

type FormikMultipleAutocompleteComponentProps =
  FormikMultipleAutocompleteProps & {
    setFieldValue: (value: string[]) => void;
    value: string[];
    onBlur: TAutocomplete["onBlur"];
  };

export const FormikAutocompleteMultipleComponent = memo(
  (props: FormikMultipleAutocompleteComponentProps): JSX.Element => {
    const { value, options, setFieldValue, onBlur } = props;

    const autocompleteValue: TAutocompleteOption[] = intersectionWith(
      options,
      value,
      (sorted, b) => sorted.value === b
    );

    const handleChange = useCallback(
      (_: React.ChangeEvent<Record<string, unknown>>, options) => {
        setFieldValue(
          (options as TAutocompleteOption[])?.map(({ value }) => value)
        );
      },
      [setFieldValue]
    );

    const passedProps = {
      ...props,
      multiple: true,
      autocompleteValue,
      handleChange,
      onBlur
    };

    return <AutocompleteField {...passedProps} />;
  }
);
