import { useCallback, useEffect, useState } from "react";

import { transformTaskToRowData } from "Shared";
import { VerificationTaskFragment } from "Shared/graphql/generated";

import { useGetHCPTaskData } from "./useGetHCPTaskData";

type UseHCPTaskContainerActionsInput = {
  orgId: string;
};

export const useHCPTaskContainerActions = ({
  orgId
}: UseHCPTaskContainerActionsInput) => {
  const {
    initialPhoneNumbers: incompleteInitialPhoneNumbers,
    isLoading: incompleteLoading,
    rows: incompleteRows,
    pagination: incompletePagination,
    count: incompleteRowsCount
  } = useGetHCPTaskData({
    orgId,
    completed: false
  });

  const {
    isLoading: completedLoading,
    rows: completedRows,
    pagination: completedPagination,
    count: completedRowsCount
  } = useGetHCPTaskData({
    orgId,
    completed: true
  });

  const [verifiedTasks, setVerifiedTasks] = useState(completedRows);
  const [unVerifiedTasks, setUnVerifiedTasks] = useState(incompleteRows);

  const [verifiedTasksCount, setVerifiedTasksCount] =
    useState(completedRowsCount);
  const [unVerifiedTasksCount, setUnVerifiedTasksCount] =
    useState(incompleteRowsCount);

  useEffect(() => {
    setVerifiedTasks(completedRows);
    setUnVerifiedTasks(incompleteRows);
    setVerifiedTasksCount(completedRowsCount);
    setUnVerifiedTasksCount(incompleteRowsCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setUnVerifiedTasks,
    setVerifiedTasks,
    completedRows.length,
    incompleteRows.length,
    setUnVerifiedTasksCount,
    setVerifiedTasksCount,
    completedRowsCount,
    incompleteRowsCount
  ]);

  const onHCPTaskSubmit = useCallback(
    (task: VerificationTaskFragment) => {
      const rowData = transformTaskToRowData(task);
      const updatedUnVerifiedTasks = unVerifiedTasks.filter(
        (task) => task.id !== rowData.id
      );
      setUnVerifiedTasks(updatedUnVerifiedTasks);
      setVerifiedTasks([...verifiedTasks, rowData]);
      setUnVerifiedTasksCount(unVerifiedTasksCount - 1);
      setVerifiedTasksCount(verifiedTasksCount + 1);
    },
    [
      verifiedTasks,
      setVerifiedTasks,
      unVerifiedTasks,
      setUnVerifiedTasks,
      setUnVerifiedTasksCount,
      setVerifiedTasksCount,
      verifiedTasksCount,
      unVerifiedTasksCount
    ]
  );

  return {
    onHCPTaskSubmit,
    verifiedTasks,
    verifiedTasksCount,
    unVerifiedTasks,
    unVerifiedTasksCount,
    completedLoading,
    incompleteLoading,
    completedPagination,
    incompletePagination,
    incompleteInitialPhoneNumbers
  };
};
