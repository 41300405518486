import { Container, Typography, Grid, Box } from "@material-ui/core";

import { Person } from "Shared/graphql/generated";

import { InfoItem } from "../components";
import { transformCorrectPhone } from "../helpers";

type PersonDetailsProps = Person;

const getSpecialtiesStr = (specialties: string[]) => {
  if (specialties.length > 1) {
    return specialties.join(", ");
  }
  if (specialties.length === 1) {
    return specialties[0];
  }
  return "No specialties";
};

export const PersonDetails = ({
  firstName,
  lastName,
  npi,
  phoneNumber,
  specialties,
  designation
}: PersonDetailsProps) => {
  const fullName = `${firstName || ""} ${lastName || ""}`;
  const specialtiesStr = getSpecialtiesStr(specialties);

  return (
    <Container component={Box} paddingY={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">{fullName}</Typography>
        </Grid>
        <Grid item xs={6}>
          <InfoItem label="NPI" content={npi || "No NPI"} />
        </Grid>
        <Grid item xs={6}>
          <InfoItem
            label="Phone Number"
            content={transformCorrectPhone(phoneNumber || "No phone numbers")}
          />
        </Grid>
        <Grid item xs={6}>
          <InfoItem label="Specialities" content={specialtiesStr} />
        </Grid>
        <Grid item xs={6}>
          <InfoItem
            label="Designation(s)"
            content={designation || "No designations"}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
