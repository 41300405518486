import { Box, Grid, makeStyles } from "@material-ui/core";

import {
  FormikTextField,
  FormikInputMask,
  UrlTextField,
  FormikAcceptNewPatients,
  FormikAddHoursOfOperations,
  Fieldset,
  FormikAutocompleteSingle,
  AddLanguagesSpoken,
  workStatusOptions
} from "Shared";
import { OperationHourType } from "Shared/graphql/generated";
import { SectionContainer } from "AffiliationValidation/components";

const useStyles = makeStyles({
  hoursOfOperation: {
    marginBottom: "28px"
  }
});

export const ConfirmPersonWork = () => {
  const { hoursOfOperation } = useStyles();
  return (
    <SectionContainer label="Confirm person work details:">
      <Box component={(props) => <Fieldset {...props} />}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormikAutocompleteSingle
              name="affiliationStatus"
              options={workStatusOptions}
              label="Work Affiliation"
            />
          </Grid>
          <Grid item xs={12}>
            <FormikAcceptNewPatients label="Accepting New Patients" />
          </Grid>
          <Grid item xs={6}>
            <FormikTextField required name="firstName" label="First Name" />
          </Grid>
          <Grid item xs={6}>
            <FormikTextField name="middleName" label="Middle Name" />
          </Grid>
          <Grid item xs={6}>
            <FormikTextField required name="lastName" label="Last Name" />
          </Grid>
          <Grid item xs={6}>
            <FormikTextField name="nickname" label="Nickname" />
          </Grid>
          <Grid item xs={12}>
            <AddLanguagesSpoken />
          </Grid>
          <Grid item xs={6}>
            <FormikTextField name="department" label="Department" shrink />
          </Grid>
          <Grid item xs={6}>
            <FormikTextField
              name="departmentRole"
              label="Department Title"
              shrink
            />
          </Grid>
          <Grid item xs={8}>
            <FormikInputMask
              mask="999-999-9999"
              name="appointmentPhoneNumber"
              label="Appointment Phone #"
            />
          </Grid>
          <Grid item xs={4}>
            <FormikTextField
              name="appointmentPhoneNumberExt"
              label="Phone Ext."
            />
          </Grid>
          <Grid item xs={12} className={hoursOfOperation}>
            <FormikAddHoursOfOperations type={OperationHourType.Department} />
          </Grid>
          <Grid item xs={12}>
            <FormikTextField name="email" label="Work Email Address" shrink />
          </Grid>
          <Grid item xs={12}>
            <FormikInputMask
              mask="999-999-9999"
              name="directFaxNumber"
              label="Direct Fax Number"
            />
          </Grid>
          <Grid item xs={12}>
            <UrlTextField name="profileUrl" label="Profile Page" />
          </Grid>
          <Grid item xs={12}>
            <UrlTextField name="listingUrl" label="Listing Page" />
          </Grid>
        </Grid>
      </Box>
    </SectionContainer>
  );
};
