import { MouseEvent } from "react";
import { Box, IconButton, Tooltip } from "@material-ui/core";
import {
  FirstPage,
  LastPage,
  KeyboardArrowLeft,
  KeyboardArrowRight
} from "@material-ui/icons";

type TablePaginationActionsProps = {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: MouseEvent<HTMLButtonElement>, newPage: number) => void;
};

export function PaginationActions({
  count,
  page,
  rowsPerPage,
  onPageChange
}: TablePaginationActionsProps): JSX.Element {
  const disable = page === 0;
  const lastPage = Math.ceil(count / rowsPerPage) - 1;

  return (
    <Box flexShrink={0} ml={2}>
      <IconButton
        onClick={(event) => onPageChange(event, 0)}
        disabled={disable}
        aria-label="first page"
      >
        <Tooltip title="First page">
          <FirstPage />
        </Tooltip>
      </IconButton>
      <IconButton
        onClick={(event) => onPageChange(event, page - 1)}
        disabled={disable}
        aria-label="previous page"
      >
        <Tooltip title="Previous">
          <KeyboardArrowLeft />
        </Tooltip>
      </IconButton>
      <IconButton
        onClick={(event) => onPageChange(event, page + 1)}
        disabled={page >= lastPage}
        aria-label="next page"
      >
        <Tooltip title="Next">
          <KeyboardArrowRight />
        </Tooltip>
      </IconButton>
      <IconButton
        onClick={(event) => onPageChange(event, lastPage)}
        disabled={page >= lastPage}
        aria-label="last page"
      >
        <Tooltip title="Last page">
          <LastPage />
        </Tooltip>
      </IconButton>
    </Box>
  );
}
