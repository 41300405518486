import { makeStyles } from "@material-ui/core";

import { Theme } from "Shared/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  topRow: {
    // NOTE: uncomment if we need to remove border with collapsed row
    // "& > *": { borderBottom: "unset" }
    backgroundColor: "white"
  },
  activeTopRow: {
    backgroundColor: theme.palette.activeColors.warningTableRow,
    "& > td": {
      background: theme.palette.activeColors.warningTableRowSticky
    }
  },
  rowWithoutPaddings: {
    paddingBottom: 0,
    paddingTop: 0
  },
  stickyLeftExpand: {
    whiteSpace: "nowrap",
    position: "sticky",
    left: "0",
    background: "white",
    zIndex: 100
  },
  stickyLeftName: {
    whiteSpace: "nowrap",
    position: "sticky",
    left: "62px",
    background: "white",
    zIndex: 100
  },
  stickyRight: {
    whiteSpace: "nowrap",
    position: "sticky",
    right: "0",
    background: "white",
    zIndex: 101
  },
  link: {
    color: "black",
    fontWeight: "bold"
  }
}));
