import { useFormikContext } from "formik";
import { useCallback } from "react";
import { useOutletContext } from "react-router-dom";

import {
  transformTaskValuesToVariables,
  TValidateTaskFromInitialValues
} from "Shared";

import {
  VerificationTaskFragment,
  TaskStatus,
  CreateAffiliationTaskSessionsInput,
  UpdateAffiliationCallSessionInput
} from "Shared/graphql/generated";
import { canComplete, canSaveAndDefer } from "OrganizationValidation/helpers";

import { useCreateAffiliationTaskSessions } from "./useCreateAffiliationTaskSessions";
import { useUpdateAffiliationTaskSession } from "./useUpdateAffiliationTaskSessions";

export type TUseAffiliationActionsProps = {
  verificationTaskId: string;
  sessionId?: string;
  completed: boolean;
};

type ValidationFormContext = {
  updateTasks: React.Dispatch<React.SetStateAction<VerificationTaskFragment[]>>;
};

export const useAffiliationActions = ({
  verificationTaskId,
  sessionId,
  completed
}: TUseAffiliationActionsProps) => {
  const { isValid, dirty, values, initialValues } =
    useFormikContext<TValidateTaskFromInitialValues>();

  const { updateTasks } = useOutletContext<ValidationFormContext>();

  const onCompleteVerification = useCallback(() => {
    // update current task status from incomplete to complete
    updateTasks((prev) => {
      const updatedTasks = prev.map((task) => ({
        ...task,
        completed: task.id === verificationTaskId ? true : task.completed
      }));
      return updatedTasks;
    });
    // TODO: we need to display some status or information on UI
  }, [verificationTaskId, updateTasks]);

  const { createAffiliationTask, isLoading: isCompleting } =
    useCreateAffiliationTaskSessions();

  const { updateAffiliationTask, isLoading: isUpdating } =
    useUpdateAffiliationTaskSession();

  const isSaveAndDeferEnabled =
    !isCompleting && dirty && isValid && canSaveAndDefer(values.phoneNumbers);
  const isCompleteButtonEnabled =
    !isCompleting && isValid && canComplete(values.phoneNumbers);
  const isUpdateButtonEnabled =
    dirty && isValid && !isUpdating && !isCompleting;

  const onSaveAndDefer = useCallback(async () => {
    const variables = transformTaskValuesToVariables({
      taskId: verificationTaskId,
      sessionId: sessionId,
      newFormValues: values,
      initFormValues: initialValues,
      update: completed,
      status: TaskStatus.Deferred
    }) as CreateAffiliationTaskSessionsInput;
    // TODO: try to get rid of "as CreateAffiliationTaskSessionsInput"

    if (variables) {
      const updatedTaskResponse = await createAffiliationTask({
        variables,
        isComplete: false
      });

      updateTasks((prev) => {
        const updatedTasks = prev.map((task) => ({
          ...task,
          lastSession:
            task.id === verificationTaskId && updatedTaskResponse
              ? updatedTaskResponse.lastSession
              : task.lastSession
        }));

        return updatedTasks;
      });
    }
  }, [
    updateTasks,
    createAffiliationTask,
    verificationTaskId,
    sessionId,
    initialValues,
    completed,
    values
  ]);

  const onComplete = useCallback(async () => {
    const variables = transformTaskValuesToVariables({
      taskId: verificationTaskId,
      sessionId: sessionId,
      newFormValues: values,
      initFormValues: initialValues,
      update: completed,
      status: TaskStatus.Complete
    }) as CreateAffiliationTaskSessionsInput;
    // TODO: try to get rid of "as CreateAffiliationTaskSessionsInput"

    if (variables) {
      await createAffiliationTask({ variables, isComplete: true });
      onCompleteVerification();
    }
  }, [
    createAffiliationTask,
    onCompleteVerification,
    verificationTaskId,
    sessionId,
    initialValues,
    completed,
    values
  ]);

  const onUpdate = useCallback(async () => {
    if (sessionId) {
      const variables = transformTaskValuesToVariables({
        taskId: verificationTaskId,
        sessionId: sessionId,
        newFormValues: values,
        initFormValues: initialValues,
        update: completed
      }) as UpdateAffiliationCallSessionInput;
      // TODO: try to get rid of "as TUpdateAffiliationTaskSessionsInput"

      await updateAffiliationTask(variables);
    }
  }, [
    updateAffiliationTask,
    verificationTaskId,
    sessionId,
    initialValues,
    completed,
    values
  ]);

  return {
    onComplete,
    onSaveAndDefer,
    onUpdate,
    isSaveAndDeferEnabled,
    isCompleteButtonEnabled,
    isUpdateButtonEnabled,
    isLoading: isCompleting || isUpdating
  };
};
