import { Dispatch, memo, SetStateAction } from "react";
import { Box, Typography, Button, Grid, makeStyles } from "@material-ui/core";
import { Delete } from "@material-ui/icons";

import { AlertPopup, Modal } from "Shared";
import { PhoneNumberType } from "Shared/graphql/generated";

type TDeleteAddedPhoneProps = {
  canBeDeleted: boolean;
  isAlertOpen: boolean;
  correctFormatNumber: string;
  onCancel: () => void;
  onConfirm: () => void;
  setIsAlertOpen: Dispatch<SetStateAction<boolean>>;
  type?: PhoneNumberType | null;
  onDelete?: (index: number) => void;
};

const useStyles = makeStyles(() => ({
  sectionNameAlight: {
    display: "flex",
    paddingTop: "12px"
  }
}));

const LeaveMessage = "Are you sure you want to remove this phone number?";

export const DeleteAddedPhone = memo(
  ({
    correctFormatNumber,
    type,
    canBeDeleted,
    isAlertOpen,
    setIsAlertOpen,
    onDelete,
    onCancel,
    onConfirm
  }: TDeleteAddedPhoneProps) => {
    const { sectionNameAlight } = useStyles();

    return (
      <Grid item xs={3} className={sectionNameAlight}>
        <Box display="flex">
          <Box display="flex" flexDirection="column">
            {onDelete && (
              <Modal
                isOpen={isAlertOpen}
                onClose={onCancel}
                title="Are you sure?"
              >
                <AlertPopup
                  onConfirm={onConfirm}
                  onCancel={onCancel}
                  description={LeaveMessage}
                  confirmButtonText="Remove"
                />
              </Modal>
            )}
            <Box minWidth={150}>
              <Box display="flex" alignItems="center">
                <Typography>{correctFormatNumber}</Typography>
                {canBeDeleted && (
                  <Button
                    onClick={() => setIsAlertOpen(true)}
                    style={{ marginLeft: 5 }}
                  >
                    <Delete color="action" />
                  </Button>
                )}
              </Box>
            </Box>
            {type && <Typography variant="caption">{type}</Typography>}
          </Box>
        </Box>
      </Grid>
    );
  }
);
