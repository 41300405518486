import omit from "lodash/omit";

import {
  defaultFormFilledParams,
  listExcludedFormFields,
  DID_NOT_CALL
} from "Shared";
import {
  getDefaultTransformedFormData,
  findDifference,
  convertEmptyStringToUndefinedForPreviouslyUndefined
} from "Shared/utils";
import {
  TaskStatus,
  PhoneNumberType,
  TaskStatusReason,
  AffiliationStatus,
  AffiliationCallSessionInput,
  CreateAffiliationTaskSessionsInput,
  UpdateAffiliationCallSessionInput
} from "Shared/graphql/generated";
import {
  TFromPhoneNumber,
  TFromPhoneCallStatus,
  TNewAffiliationInitialValues,
  TValidateTaskFromInitialValues,
  TVerificationUpdateData
} from "Shared/types";

import { transformAcceptingNewPatientToVariable } from "./transformNewPatient";
import { transformNumberToConcatString } from "./transformNumberToConcatString";
import { transformInitialValuesTOHoursOfOperation } from "./transformHoursOfOperationData";

export const getStatusForCompleteVerification = (
  callStatus: TFromPhoneCallStatus
): TaskStatusReason | undefined => {
  if (callStatus === DID_NOT_CALL) {
    return undefined;
  }

  return callStatus;
};

// TODO: we don't use this helper until AddNewWorkAffiliation new logic will be ready
export const transformNewAffiliation = (
  newAffiliation: TNewAffiliationInitialValues
): TVerificationUpdateData => {
  const { city, name, phone, state, zip } = newAffiliation;

  const transformedNewAffiliation: TVerificationUpdateData = {
    affiliationStatus: AffiliationStatus.Unknown,
    orgName: name,
    phoneNumber: phone,
    orgAddressLine1: city,
    orgAddressPostalCode: zip,
    orgAddressRegion: state
  };

  return transformedNewAffiliation;
};

/**
 * Get variables (input data) ready to be send on back-end with completeVerificationTask mutation
 */
export const getVerifyTaskVariables = ({
  updateData: { notes, ...update },
  phoneNumbers,
  taskId,
  status
}: {
  updateData: TVerificationUpdateData;
  phoneNumbers: TFromPhoneNumber[] | undefined;
  taskId: string;
  status: TaskStatus;
}): CreateAffiliationTaskSessionsInput => {
  const taskSessions: AffiliationCallSessionInput[] =
    phoneNumbers
      ?.filter((phoneNumber) => phoneNumber.called)
      .map((phoneNumber) => {
        const {
          number,
          extension,
          callStatus,
          respondentDepartment,
          respondentFirstName,
          respondentLastName,
          respondentTitle,
          type
        } = phoneNumber;
        const statusReason =
          getStatusForCompleteVerification(callStatus) ||
          TaskStatusReason.DeclinedVerification;

        return {
          phoneNumber: number,
          phoneExtension: extension,
          status,
          phoneType: type || PhoneNumberType.Unknown,
          notes,
          statusReason,
          respondentDepartment,
          respondentFirstName,
          respondentLastName,
          respondentTitle,
          update
        };
      }) || [];

  const verifyTaskVariables: CreateAffiliationTaskSessionsInput = {
    verificationTaskId: taskId,
    taskSessions
  };

  return verifyTaskVariables;
};

/**
 * Transform from verification values to be ready from back-end;
 * some specific filed like: hoursOfOperationOrganization, hoursOfOperationDepartment and directFaxNumber
 * @param changedValues changed values from verification form
 */
export const transformFormDataToVerificationData = (
  changedValues: TValidateTaskFromInitialValues
): TVerificationUpdateData => {
  const {
    directFaxNumber,
    hoursOfOperationOrganization,
    hoursOfOperationDepartment,
    isAcceptNewPatients,
    directPhoneNumber,
    personalPhoneNumber,
    ...restData
  } = changedValues || {};

  const verificationUpdateData: TVerificationUpdateData = {
    ...restData
  };

  // transform and add directFaxNumber ready for back-end (removing "-" characters)
  if (directFaxNumber) {
    const transformedDirectFaxNumber =
      transformNumberToConcatString(directFaxNumber);

    verificationUpdateData.directFaxNumber = transformedDirectFaxNumber;
  }

  // transform and add directPhoneNumber ready for back-end (removing "-" characters)
  if (directPhoneNumber) {
    const transformedDirectPhoneNumber =
      transformNumberToConcatString(directPhoneNumber);

    verificationUpdateData.directPhoneNumber = transformedDirectPhoneNumber;
  }

  // transform and add directPhoneNumber ready for back-end (removing "-" characters)
  if (personalPhoneNumber) {
    const transformedPersonalPhoneNumber =
      transformNumberToConcatString(personalPhoneNumber);

    verificationUpdateData.personalPhoneNumber = transformedPersonalPhoneNumber;
  }

  if (isAcceptNewPatients) {
    verificationUpdateData.isAcceptNewPatients =
      transformAcceptingNewPatientToVariable(isAcceptNewPatients);
  }

  // combine Date Range Operation Hours from verification form in to back-end ready OperationHours filed
  if (hoursOfOperationOrganization || hoursOfOperationDepartment) {
    const transformedOperationHours = transformInitialValuesTOHoursOfOperation(
      hoursOfOperationDepartment,
      hoursOfOperationOrganization
    );

    // need to send empty value instead empty array
    verificationUpdateData.operationHours = transformedOperationHours?.length
      ? transformedOperationHours
      : undefined;
  }

  return verificationUpdateData;
};

/**
 * This function find the diff between initial form values and new form values
 * and returns variables(input) for CompleteVerificationTaskInput mutation
 */
export const transformTaskValuesToVariables = ({
  newFormValues,
  initFormValues,
  taskId,
  update = false,
  sessionId = "",
  status = TaskStatus.Complete
}: {
  newFormValues: TValidateTaskFromInitialValues;
  initFormValues: TValidateTaskFromInitialValues;
  taskId: string;
  sessionId?: string;
  update?: boolean;
  status?: TaskStatus;
}): CreateAffiliationTaskSessionsInput | UpdateAffiliationCallSessionInput => {
  // convert keys with value = `""` to undefined when the value was previously undefined
  const formValues = convertEmptyStringToUndefinedForPreviouslyUndefined(
    omit(newFormValues, listExcludedFormFields),
    initFormValues
  ) as TValidateTaskFromInitialValues;

  // find changed values in verification form
  const formChangedValues = findDifference<TValidateTaskFromInitialValues>(
    initFormValues,
    formValues
  ) as TValidateTaskFromInitialValues;

  // since phoneNumbers is mandatory for Complete and safeAndDefer
  // add phoneNumbers even it was not changed
  formChangedValues.phoneNumbers = newFormValues.phoneNumbers;

  // separate phone numbers array from of the rest changed from data
  const { phoneNumbers, ...restChangedValues } = formChangedValues;

  // add data that was filled by frontend by default
  const excludedInitialValues = getDefaultTransformedFormData(
    formValues,
    defaultFormFilledParams
  );

  // transform specific form fields (like faxNumber and operationHour) for back-end
  const updateData = transformFormDataToVerificationData({
    ...restChangedValues,
    ...excludedInitialValues
  });

  if (!updateData.affiliationStatus) {
    updateData.affiliationStatus = newFormValues.affiliationStatus;
  }

  // TODO: affiliationId should not come from form values
  // we don't have this filed in form
  const affiliationId = initFormValues.affiliationId;
  if (affiliationId) {
    updateData.affiliationId = affiliationId;
  }

  if (update) {
    // update variables
    return {
      id: sessionId,
      update: omit(updateData, "notes")
    };
  }

  // complete variables
  return getVerifyTaskVariables({
    taskId,
    phoneNumbers,
    updateData,
    status
  });
};
