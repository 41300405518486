import { AddressFragment } from "Shared/graphql/generated";

export const getAddressString = (address?: AddressFragment) => {
  if (address) {
    const { line1, city, state, zip } = address;

    const line2 = `${city || ""}, ${state || ""} ${zip || ""}`;

    return (line1 || "") + "\n" + line2;
  }

  return "No address";
};
