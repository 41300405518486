import {
  getFullNameFromInitialValues,
  NOT_AVAILABLE,
  transformAcceptingNewPatientToInitialValue,
  nullValuesToUndefined,
  THCPTableRow,
  THCPTaskFormInitialValues,
  Complete
} from "Shared";
import {
  VerificationDataFragment,
  VerificationTaskFragment
} from "Shared/graphql/generated";

/**
 * Transform orgAffiliationTasks data and get exact fields for front-end
 */
export const transformTaskToRowData = (
  task: VerificationTaskFragment
): THCPTableRow => {
  const { completedBy, updated, id, data, personNpi, person, notes } = task;
  const taskData: VerificationDataFragment = nullValuesToUndefined(data);

  // we need grab only form related data from VerificationData
  const {
    firstName,
    lastName,
    middleName,
    nickname,
    affiliationStatus,
    spokenLanguages,
    email,
    department,
    directFaxNumber,
    isAcceptNewPatients = null,
    departmentRole,
    personalPhoneNumber,
    directPhoneNumber,
    appointmentPhoneNumberExt,
    appointmentPhoneNumber
  } = taskData;

  const hcpName = getFullNameFromInitialValues({
    firstName,
    lastName,
    middleName
  });

  // person.specialties param is built on backend by next way :
  // [person.primarySpecialty, person.secondarySpecialty].filter(Boolean)

  const specialties = person?.specialties || [];
  const specialty = specialties.length ? specialties[0] : NOT_AVAILABLE;
  const designation = person?.designation || NOT_AVAILABLE;
  const npi = personNpi || NOT_AVAILABLE;
  const completedByEmail = completedBy?.email || NOT_AVAILABLE;
  const completedOn = updated.toString();

  const initialAcceptNewPatient =
    transformAcceptingNewPatientToInitialValue(isAcceptNewPatients);

  // TODO: update
  const rowInitialValues: Complete<THCPTaskFormInitialValues> = {
    affiliationStatus: affiliationStatus ?? undefined,
    firstName: firstName ?? undefined,
    lastName: lastName ?? undefined,
    middleName: middleName ?? undefined,
    nickname: nickname ?? undefined,
    spokenLanguages: spokenLanguages ?? undefined,
    email: email ?? undefined,
    department: department ?? undefined,
    directFaxNumber: directFaxNumber ?? undefined,
    isAcceptNewPatients: initialAcceptNewPatient,
    notes: notes ?? undefined,
    personalPhoneNumber: personalPhoneNumber ?? undefined,
    directPhoneNumber: directPhoneNumber ?? undefined,
    departmentRole: departmentRole ?? undefined,
    appointmentPhoneNumber: appointmentPhoneNumber ?? undefined,
    appointmentPhoneNumberExt: appointmentPhoneNumberExt ?? undefined
  };

  return {
    initialValues: rowInitialValues,
    completedBy: completedByEmail,
    completedOn,
    id,
    npi,
    department: department || NOT_AVAILABLE,
    designation,
    hcpName,
    specialty
  };
};

export const transformAffiliationTasksToRows = (
  tasks: VerificationTaskFragment[]
): THCPTableRow[] => {
  const rows = tasks.map(transformTaskToRowData);

  return rows;
};
