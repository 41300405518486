import { createTheme } from "@material-ui/core";
import { ThemeOptions } from "@material-ui/core/styles/createTheme";
import { Palette as MuiPalette } from "@material-ui/core/styles/createPalette";

import { themeOptions } from "./themeOptions";

export type Palette = MuiPalette & {
  primary: {
    main: string;
    light: string;
    ultraLight: string;
    50: string;
    100: string;
    contrastText: string;
  };

  success: {
    50: string;
    light: string;
    main: string;
    contrastText: string;
  };

  warning: {
    dark: string;
    main: string;
    50: string;
    contrastText: string;
  };

  error: {
    main: string;
    50: string;
    contrastText: string;
  };

  merrigold: {
    dark: string;
    main: string;
    50: string;
    contrastText: string;
  };

  violet: {
    main: string;
    50: string;
    contrastText: string;
  };

  koolaid: {
    main: string;
    50: string;
    contrastText: string;
  };

  grey: {
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    750: string;
    900: string;
    inactive: string;
    smoke: string;
  };

  activeColors: {
    blueText: string;
    blueBackground: string;
    blueBackground2: string;
    warningTableRow: string;
    warningTableRowSticky: string;
  };

  visualizations: {
    blue: string;
    red: string;
    grey: string;
    yellow: string;
    green: string;
    darkRed: string;
    orange: string;
    darkBlue: string;
    brown: string;
  };
};

export type Shadows = [
  "none",
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string
];

export type Theme = ThemeOptions & {
  palette: Palette;
  shadows: Shadows;
};

export const theme = createTheme(themeOptions) as ThemeOptions;
