import { useState } from "react";
import {
  Box,
  Collapse,
  Typography,
  Button,
  Container
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

export type ExpandSectionProps = {
  title: string;
};

export const ExpandSection: React.FC<ExpandSectionProps> = ({
  children,
  title
}) => {
  const [isShow, setIsShow] = useState(true);

  const toggleExpand = () => {
    setIsShow(!isShow);
  };

  return (
    <Container
      component={Box}
      mb={2}
      bgcolor="#fff"
      paddingY={2}
      borderRadius={8}
    >
      <Box>
        <Box
          display="flex"
          alignItems="center"
          pt={4}
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="h5" display="inline">
              {title}
            </Typography>
            <Box ml={3} fontSize="12px" display="inline-flex">
              {isShow ? (
                <Button onClick={toggleExpand} endIcon={<ExpandLessIcon />}>
                  hide
                </Button>
              ) : (
                <Button
                  onClick={toggleExpand}
                  endIcon={<KeyboardArrowDownIcon />}
                >
                  show
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        <Collapse in={isShow}>
          <Box pt={2}>{children}</Box>
        </Collapse>
      </Box>
    </Container>
  );
};
