import { Box, Button, Typography } from "@material-ui/core";

export const Confirmation = ({
  email,
  goBack
}: {
  email: string;
  goBack: () => void;
}) => {
  return (
    <Box mt={3} display="flex" alignItems="center" flexDirection="column">
      <Box width="70%" mb={3}>
        <Typography variant="body1" align="center">
          You should have received an email with a password-free single use link
          to access the site.
        </Typography>
      </Box>

      <Typography align="center">The email was sent to:</Typography>
      <Typography color="primary" variant="h6">
        {email}
      </Typography>
      <Box display="flex" mt={6} justifyContent="center"></Box>
      <Box display="flex" justifyContent="center">
        <Button onClick={goBack}>Go Back</Button>
      </Box>
    </Box>
  );
};
