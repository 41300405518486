import * as Yup from "yup";

import { TFromPhoneNumber, DID_NOT_CALL } from "Shared";
import { phoneNumberValidation } from "Shared/utils";
import { PhoneNumberType, TaskStatusReason } from "Shared/graphql/generated";

import type { ArrayHelpers } from "formik";

export type TAddPhone = typeof addPhoneInitialValues & {
  isNotSaved?: boolean;
};

export const PHONE_ROW_CHECKBOXES = [
  { label: "Did not call", value: DID_NOT_CALL },
  { label: "Verified call", value: TaskStatusReason.Verified },
  { label: "Verify via fax", value: TaskStatusReason.VerifyThroughFax },
  { label: "Did not pickup", value: TaskStatusReason.NoAnswer },
  {
    label: "Declined to answer",
    value: TaskStatusReason.DeclinedVerification
  },
  { label: "Wrong Phone Number", value: TaskStatusReason.WrongNumber },
  { label: "Add to Do not call list", value: TaskStatusReason.DoNotCall }
];

const phoneTypeOptionsMatcher: string = Object.values(PhoneNumberType).reduce(
  (acc, item) => {
    return `${acc}${!acc ? "" : "|"}${item}`;
  },
  ""
);

export const addPhoneInitialValues = {
  number: "",
  extension: ""
};

export const addPhoneValidationSchema = Yup.object().shape({
  number: phoneNumberValidation({
    type: "correctLength",
    field: "Phone Number"
  }).required("Phone Number is required"),
  extension: Yup.string().matches(/^[0-9]+$/, "Must be only digits"),
  type: Yup.string()
    .matches(new RegExp(phoneTypeOptionsMatcher))
    .required("Type is required")
});

export const handleAddPhone = (
  phone: TAddPhone,
  push: ArrayHelpers["push"]
) => {
  const newPhone: TFromPhoneNumber = {
    ...phone,
    called: false,
    callStatus: DID_NOT_CALL
  };

  push(newPhone);
};
