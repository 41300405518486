import { memo } from "react";

import { useHCPTaskContainerActions } from "OrganizationValidation/hooks";
import {
  VerifyHCPTask,
  PreviouslyVerifiedHCPTask
} from "OrganizationValidation/components";

export type HCPTasksSectionProps = {
  orgId: string;
};

export const HCPTasksSection = memo(({ orgId }: HCPTasksSectionProps) => {
  const {
    verifiedTasks,
    verifiedTasksCount,
    unVerifiedTasks,
    unVerifiedTasksCount,
    onHCPTaskSubmit,
    incompleteInitialPhoneNumbers,
    incompleteLoading,
    incompletePagination,
    completedLoading,
    completedPagination
  } = useHCPTaskContainerActions({
    orgId
  });

  return (
    <>
      {/* Tasks to verify */}
      <VerifyHCPTask
        isLoading={incompleteLoading}
        initialPhoneNumbers={incompleteInitialPhoneNumbers}
        rows={unVerifiedTasks}
        count={unVerifiedTasksCount}
        onHCPTaskSubmit={onHCPTaskSubmit}
        pagination={incompletePagination}
      />
      {/* Verified tasks */}
      <PreviouslyVerifiedHCPTask
        isLoading={completedLoading}
        rows={verifiedTasks}
        count={verifiedTasksCount}
        pagination={completedPagination}
      />
    </>
  );
});
