import { useCallback } from "react";

import { useResponseHandler, useSnackBarMessage } from "Shared";
import {
  CreateOrganizationTaskSessionMutationVariables,
  FindOrganizationTaskDocument,
  OrganizationTaskFragment,
  useCreateOrganizationTaskSessionMutation
} from "Shared/graphql/generated";

const successSaveAndDeferMessage =
  "Organization task marked as 'save and defer'";
const successCompleteMessage = "Organization task verification completed";

export const useCreateOrganizationTaskSessions = () => {
  const { handleResponse } = useResponseHandler();
  const { showSuccess } = useSnackBarMessage();

  const [createOrganizationTaskSessionMutation, { loading }] =
    useCreateOrganizationTaskSessionMutation({
      fetchPolicy: "no-cache",
      refetchQueries: [FindOrganizationTaskDocument]
    });

  const createOrgTaskSession = useCallback(
    async ({
      variables,
      isComplete
    }: {
      variables: CreateOrganizationTaskSessionMutationVariables;
      isComplete?: boolean;
    }): Promise<OrganizationTaskFragment | undefined | null> => {
      const res = await createOrganizationTaskSessionMutation({
        variables
      });

      const result = res.data?.createOrganizationTaskSessions;

      if (result) {
        const { response, isSuccess } = handleResponse(result);
        if (isSuccess) {
          showSuccess(
            isComplete ? successCompleteMessage : successSaveAndDeferMessage
          );
          return response.task;
        }
      }
    },
    [createOrganizationTaskSessionMutation, handleResponse, showSuccess]
  );

  return {
    createOrgTaskSession,
    isLoading: loading
  };
};
