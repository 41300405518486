import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import { useCallback } from "react";

import { UserFragment } from "Shared/graphql/generated";

export const useFeatureFlags = () => {
  const launchDarkly = useLDClient();
  const flags = useFlags();

  const setUser = useCallback(
    async (user: UserFragment) => {
      if (!launchDarkly) return;
      return launchDarkly.identify({
        key: user.id,
        email: user.email
      });
    },
    [launchDarkly]
  );

  return { flags, setUser };
};
