import React from "react";
import { withLDProvider } from "launchdarkly-react-client-sdk";

const CLIENT_ID = process.env["REACT_APP_LD_CLIENT_ID"] || "";
const isDev = process.env.NODE_ENV === "development";

export const FeatureFlagsProvider: React.ComponentType<{
  children: JSX.Element;
}> = withLDProvider<{ children: JSX.Element }>({
  clientSideID: CLIENT_ID,
  deferInitialization: isDev
})(({ children }) => children);
