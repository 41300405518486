import { useCallback, useState } from "react";

import { Modal, useNavigationBlocker } from "Shared";

import { AlertPopup } from "./AlertPopup";

export const modalTitle = "Leave the form?";

export const PopupOnLeave = ({ dirty }: { dirty: boolean }): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { onConfirm } = useNavigationBlocker({ setIsOpen, shouldBlock: dirty });

  const onCancel = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onCancel} title={modalTitle}>
      <AlertPopup onCancel={onCancel} onConfirm={onConfirm} />
    </Modal>
  );
};
