import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";

import {
  RouteName,
  authenticatedRoutes,
  unauthenticatedRoutes,
  recursiveBuildRoutes
} from "App/routes";
import { NotFound, AuthLayout } from "Auth";

export const AppRoutes = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          // no index page
          element={<Navigate replace to={RouteName.NPISearch} />}
        />

        {recursiveBuildRoutes(unauthenticatedRoutes)}

        <Route path="/" element={<AuthLayout />}>
          {recursiveBuildRoutes(authenticatedRoutes)}
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
