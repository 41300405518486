import { Box, Tooltip } from "@material-ui/core";

type ShortTextProps = {
  width?: string;
  title: string;
};

export const ShortText = ({ width = "100", title }: ShortTextProps) => {
  return (
    <Tooltip title={title}>
      <Box
        style={{
          width: width,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          cursor: "pointer"
        }}
      >
        {title}
      </Box>
    </Tooltip>
  );
};
