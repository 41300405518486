import { Box, Typography } from "@material-ui/core";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import BusinessIcon from "@material-ui/icons/Business";
import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";

import { Button, useSetDocumentTitle, PageProps } from "Shared";
import { RouteName, generateRoutePath } from "App";

import { CardWithLogo } from "./components";

export const TaskSelection = ({ title }: PageProps) => {
  const [route, setRoute] = useState<RouteName | null>(null);
  const navigate = useNavigate();

  useSetDocumentTitle(title);

  const onCardClick = useCallback((route: RouteName) => setRoute(route), []);

  const onGoButtonClick = useCallback(
    () =>
      route &&
      navigate(
        generateRoutePath({
          name: route
        })
      ),
    [navigate, route]
  );

  const isHCPActive = route === RouteName.NPISearch;
  const isOrgActive = route === RouteName.OrgIdSearch;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexGrow={1}
    >
      <Box
        paddingX={10}
        paddingY={8}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Typography variant="h5">Select a Task Type:</Typography>

        <Box
          display="flex"
          alignItems="center"
          flexDirection="row"
          paddingX={10}
          paddingY={8}
        >
          <CardWithLogo
            isActive={isHCPActive}
            onCLick={() => onCardClick(RouteName.NPISearch)}
            label="HCP affiliation validation"
            icon={<PersonOutlineIcon fontSize="small" />}
          />
          <CardWithLogo
            isActive={isOrgActive}
            onCLick={() => onCardClick(RouteName.OrgIdSearch)}
            label="Organization based affiliation validation"
            icon={<BusinessIcon fontSize="small" />}
          />
        </Box>
        <Box>
          <Button
            disabled={!route}
            color="primary"
            variant="contained"
            onClick={onGoButtonClick}
          >
            Go
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
