import { memo } from "react";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";

import { OperationHourType } from "Shared/graphql/generated";

const ORGANIZATION_LABEL = "Org Hours of Operation";
const DEPARTMENT_LABEL = "Department Hours of Operation";

const useStyles = makeStyles(() => ({
  container: {
    paddingLeft: 0
  },
  boldText: {
    fontWeight: 600
  },
  inlineFlex: {
    display: "inline-flex"
  }
}));

type HoursOfOperationHeaderProps = {
  type: OperationHourType;
  onAdd: () => void;
};

export const HoursOfOperationHeader = memo(
  ({ type, onAdd }: HoursOfOperationHeaderProps) => {
    const { container, boldText, inlineFlex } = useStyles();
    return (
      <Box className={container}>
        <Typography className={`${boldText} ${inlineFlex}`}>
          {type === OperationHourType.Department
            ? DEPARTMENT_LABEL
            : ORGANIZATION_LABEL}
        </Typography>
        <Button
          color="primary"
          variant="text"
          onClick={onAdd}
          className={inlineFlex}
        >
          <Typography variant="caption" color="primary" className={boldText}>
            + Add more
          </Typography>
        </Button>
      </Box>
    );
  }
);
