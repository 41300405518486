import { MouseEvent, useState } from "react";
import { Box, Typography, makeStyles, Button } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import { Theme, FormikMenu } from "Shared";

const useStyles = makeStyles((theme: Theme) => ({
  darkGrey: {
    color: theme.palette.grey["600"]
  },
  inlineFlex: {
    display: "inline-flex",
    marginRight: 10
  },
  arrowButton: {
    padding: 0
  },
  menu: {
    transform: "translate(-85px, 35px)",
    maxHeight: "260px"
  }
}));

export type OrgNPIsMenuProps = {
  orgNPIs: string[];
};

export const OrgNPIsMenu = ({ orgNPIs }: OrgNPIsMenuProps) => {
  const { darkGrey, inlineFlex, arrowButton, menu } = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleCarrotClick = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => setAnchorEl(null);

  const open = !!anchorEl;
  const hasNPIs = !!orgNPIs.length;

  return (
    <Box display="flex">
      <Typography variant="h6" className={`${inlineFlex} ${darkGrey}`}>
        Org NPIs
      </Typography>
      {hasNPIs && (
        <>
          {" "}
          <Button
            className={`${inlineFlex} ${darkGrey} ${arrowButton}`}
            onClick={handleCarrotClick}
          >
            <ArrowDropDownIcon fontSize="medium" />
          </Button>
          <FormikMenu
            className={`.MuiMenu-paper ${menu}`}
            items={orgNPIs.map((npi) => ({ label: npi }))}
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            MenuListProps={{
              variant: "menu"
            }}
          />
        </>
      )}
    </Box>
  );
};
