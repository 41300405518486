import { useCreateAffiliationTaskSessions } from "AffiliationValidation/hooks";
import { useSnackBarMessage } from "Shared/hooks";

export const useVerifyHCPTask = (HCPName: string) => {
  const { showSuccess, showError } = useSnackBarMessage();

  const { createAffiliationTask } = useCreateAffiliationTaskSessions({
    onCompleted: () => showSuccess(`${HCPName} affiliation task completed`),
    onError: (error) => showError(error.message)
  });

  return { createAffiliationTask };
};
