import { TablePagination } from "@material-ui/core";

import { TPagination, TPaginationLimit } from "Shared";

import { PaginationActions } from "./index";

export type PaginationProps = {
  totalItems: number;
  pagination: TPagination;
};

const rowsPerPageOptions = [25, 50, 100];

export const Pagination = ({
  totalItems,
  pagination
}: PaginationProps): JSX.Element => {
  const onRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const limit = parseInt(event.target.value, 10) as TPaginationLimit;
    pagination.setLimit(limit);
    pagination.setPage(0);
  };

  return (
    <TablePagination
      component="div"
      count={totalItems}
      page={pagination.page}
      onPageChange={(_, page) => {
        pagination.setPage(page);
      }}
      rowsPerPage={pagination.limit}
      rowsPerPageOptions={rowsPerPageOptions}
      onRowsPerPageChange={onRowsPerPageChange}
      SelectProps={{
        inputProps: { "aria-label": "rows per page" },
        native: false
      }}
      ActionsComponent={PaginationActions}
    />
  );
};
