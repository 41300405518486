import { useState } from "react";
import { Form, Formik } from "formik";
import { makeStyles, Grid } from "@material-ui/core";

import {
  AddStates,
  Theme,
  Modal,
  ModalAction,
  ModalContent,
  Button,
  FormikTextField,
  TNewAffiliationInitialValues
} from "Shared";

import {
  newAffiliationInitialValues,
  newAffiliationValidationSchema
} from "AffiliationValidation/helpers";
import { useNewAffiliationContext } from "AffiliationValidation/context";

const useStyles = makeStyles((theme: Theme) => ({
  addButton: {
    backgroundColor: theme.palette.primary.ultraLight,
    color: theme.palette.primary.main
  }
}));

/**
 * we add new affiliation work to context =
 * and will use it on Verifying by adding in verificationUpdates array
 */
export const AddNewWorkAffiliation = () => {
  const { setNewAffiliation } = useNewAffiliationContext();
  const { addButton } = useStyles();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const onSubmit = (values: TNewAffiliationInitialValues) => {
    setNewAffiliation(values);
    setIsModalOpen(false);
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        className={addButton}
        onClick={handleOpen}
      >
        + Add new work affiliation
      </Button>

      <Modal
        isOpen={isModalOpen}
        onClose={handleClose}
        title="Create new work affiliation"
      >
        <Formik
          initialValues={newAffiliationInitialValues}
          onSubmit={onSubmit}
          validationSchema={newAffiliationValidationSchema}
        >
          {({ isValid, dirty }) => (
            <Form>
              <ModalContent>
                <Grid spacing={3} container>
                  <Grid item xs={12}>
                    <FormikTextField
                      name="name"
                      label="Organization Name"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField name="phone" label="Org Phone Number" />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField name="city" label="City" />
                  </Grid>
                  <Grid item xs={4}>
                    <AddStates name="state" />
                  </Grid>
                  <Grid item xs={4}>
                    <FormikTextField name="zip" label="Zip code" />
                  </Grid>
                </Grid>
              </ModalContent>
              <ModalAction>
                <Button color="default" onClick={handleClose}>
                  Cancel
                </Button>
                <Button disabled={!isValid || !dirty} type="submit">
                  Submit
                </Button>
              </ModalAction>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
