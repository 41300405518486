import {
  Box,
  Container,
  Typography,
  makeStyles,
  Grid
} from "@material-ui/core";
import LocationCityIcon from "@material-ui/icons/LocationCity";

import {
  Theme,
  FormikTextField,
  getAddressString,
  ConfirmAlertPopup,
  useModal,
  Chip
} from "Shared";
import {
  AddressFragment,
  CallTaskSessionFragment,
  UserFragment
} from "Shared/graphql/generated";
import { useAffiliationActions } from "AffiliationValidation/hooks/useAffiliationActions";

import { TopSectionSubmit } from "./TopSectionSubmit";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.primary.ultraLight
  },
  cityIcon: {
    color: theme.palette.grey["500"]
  },
  addressTitle: {
    whiteSpace: "pre-line"
  }
}));

export type TopSectionProps = {
  taskId: string;
  name?: string | null;
  address?: AddressFragment;
  completedBy?: UserFragment | null;
  completed?: boolean;
  readonly?: boolean;
  orgType?: string;
  sessionId?: string;
  lastSession?: CallTaskSessionFragment | null;
  updated?: string;
};

const noteStyles = { backgroundColor: "#fff", borderRadius: "8px" };

export const TopSection = ({
  name,
  address,
  completedBy,
  taskId,
  sessionId,
  completed = false,
  readonly = false,
  orgType = "Unknown",
  lastSession,
  updated
}: TopSectionProps): JSX.Element => {
  const { container, cityIcon, addressTitle } = useStyles();
  const { isOpen, closeModal, openModal } = useModal();

  const addressStr = getAddressString(address);

  const {
    isCompleteButtonEnabled,
    isSaveAndDeferEnabled,
    isUpdateButtonEnabled,
    isLoading,
    onComplete,
    onSaveAndDefer,
    onUpdate
  } = useAffiliationActions({
    verificationTaskId: taskId,
    completed,
    sessionId
  });

  const onModalConfirm = async () => {
    await onComplete();
    closeModal();
  };

  const handleButtonClick = async () => {
    completed ? await onUpdate() : openModal();
  };

  const isSubmitDisabled = completed
    ? !isUpdateButtonEnabled
    : !isCompleteButtonEnabled;

  return (
    <Box paddingY={5} className={container}>
      {!completed ? (
        <ConfirmAlertPopup
          onConfirm={onModalConfirm}
          onCancel={closeModal}
          isOpen={isOpen}
        />
      ) : null}
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={4}>
            <Typography variant="h4">{name}</Typography>
            <Chip label={orgType} />
            <Box mt={2} display="flex" maxWidth="240px">
              <Box mr={1}>
                <LocationCityIcon className={cityIcon} />
              </Box>
              <Typography className={addressTitle}>{addressStr}</Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <FormikTextField
              style={noteStyles}
              name="notes"
              variant="outlined"
              multiline
              rows={4}
              placeholder="Notes"
              disabled={readonly}
            />
          </Grid>
          <Grid item xs={4}>
            <TopSectionSubmit
              readonly={readonly}
              completed={completed}
              completedBy={completedBy}
              isLoading={isLoading}
              isSubmitDisabled={isSubmitDisabled}
              isSaveAndDeferDisabled={!isSaveAndDeferEnabled}
              onSaveAndDefer={onSaveAndDefer}
              onSubmit={handleButtonClick}
              lastSession={lastSession}
              updated={updated}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
