import { Box, Chip as MIChip, Typography } from "@material-ui/core";

import type { ChipProps as MIChipProps } from "@material-ui/core";

export type ChipProps = MIChipProps & {
  subText?: string;
};

export const Chip: React.FC<ChipProps> = ({ subText, ...rest }) => {
  return (
    <Box flexDirection="column" display="inline-flex">
      {subText ? <Typography variant="caption">{subText}</Typography> : null}
      <MIChip {...rest} />
    </Box>
  );
};
