import { ThemeOptions } from "@material-ui/core/styles/createTheme";
import MUIShadows, { Shadows } from "@material-ui/core/styles/shadows";
import transitions from "@material-ui/core/styles/transitions";

// customization of shadows
const shadows: Shadows = MUIShadows;

shadows[1] = "0px 4px 7px rgba(232, 236, 243, 0.71);";
shadows[2] =
  "0px 1px 5px rgba(57, 72, 103, 0.15), 0px 3px 4px rgba(57, 72, 103, 0.1), 0px 2px 4px rgba(57, 72, 103, 0.14)";
shadows[3] =
  "0px 1px 5px rgba(57, 72, 103, 0.15), 0px 3px 4px rgba(57, 72, 103, 0.1), 0px 2px 4px rgba(57, 72, 103, 0.14)";
shadows[4] =
  "0px 1px 10px rgba(57, 72, 103, 0.18), 0px 4px 5px rgba(57, 72, 103, 0.12), 0px 2px 4px rgba(57, 72, 103, 0.14)";
shadows[6] =
  "0px 3px 5px rgba(57, 72, 103, 0.2), 0px 1px 18px rgba(57, 72, 103, 0.11), 0px 6px 10px rgba(57, 72, 103, 0.09)";
shadows[9] =
  "0px 5px 6px rgba(57, 72, 103, 0.18), 0px 3px 16px rgba(57, 72, 103, 0.12), 0px 9px 12px rgba(57, 72, 103, 0.1)";

const grey = {
  50: "#F6F7F8",
  100: "#E8EAED",
  200: "#C3CAD4",
  300: "#9EA8B7",
  400: "#8791A4",
  500: "#6B778C",
  600: "#54617B",
  700: "#364461",
  750: "#6B778C",
  900: "#262B3A",
  inactive: "#858FA5",
  smoke: "#F5F5F5"
};

const common = {
  black: "#000",
  white: "#fff"
};

export const palette = {
  common,
  primary: {
    50: "#E3F2FD",
    100: "#BBDEFB",
    light: "#178FFF",
    ultraLight: "#EBF1FF",
    main: "#0C5DD6",
    // Make dark the same as main
    dark: "#0C5DD6",
    contrastText: common.white
  },
  grey,
  success: {
    dark: "#13785F",
    main: "#019A73",
    50: "#DDF3ED",
    contrastText: common.white
  },
  warning: {
    dark: "#986106",
    main: "#EC9708",
    50: "#FFF5E5",
    contrastText: common.white
  },
  error: {
    main: "#D4393B",
    50: "#FEECEF",
    // Make dark the same as main
    dark: "#D4393B",
    contrastText: common.white
  },
  merrigold: {
    dark: "#986106",
    main: "#F2BA20",
    50: "#FAEFD1",
    contrastText: common.white
  },
  violet: {
    main: "#A426DF",
    50: "#F5EBFB",
    // Make dark the same as main
    dark: "#A426DF",
    contrastText: common.white
  },
  koolaid: {
    main: "#A97F39",
    50: "#F9D4DF",
    // Make dark the same as main
    dark: "#E53F70",
    contrastText: common.white
  },
  activeColors: {
    blueText: "#0366D6",
    blueBackground: "#DBEDFF",
    blueBackground2: "#4292FD",
    warningTableRow: "rgba(255,204,0,0.1)",
    warningTableRowSticky:
      "linear-gradient(rgba(255,204,0,0.1), rgba(255,204,0,0.1)), linear-gradient(white, white)"
  },
  visualizations: {
    blue: "#1C7AF9",
    red: "#D73377",
    grey: "#BEC4CD",
    yellow: "#FFB400",
    green: "#1C9C76",
    darkRed: "#B83700",
    orange: "#FE4E00",
    darkBlue: "#1B065E",
    brown: "#8F6400"
  }
};

export const themeOptions: ThemeOptions = {
  palette: {
    ...palette,
    secondary: palette.koolaid,
    text: {
      primary: grey[900],
      secondary: grey[500],
      hint: grey[300],
      disabled: grey[300]
    },
    background: {
      default: common.white,
      paper: common.white
    }
  },
  typography: {
    fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
    h1: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 64,
      lineHeight: "90px",
      letterSpacing: -0.5,
      color: grey[900]
    },
    h2: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 40,
      lineHeight: "72px",
      letterSpacing: -0.25,
      color: grey[900]
    },
    h3: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 32,
      lineHeight: "48px",
      letterSpacing: -0.15,
      color: grey[900]
    },
    h4: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 24,
      lineHeight: "32px",
      color: grey[900]
    },
    h5: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 20,
      lineHeight: "24px",
      letterSpacing: -0.15,
      color: grey[900]
    },
    h6: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 16,
      lineHeight: "24px",
      color: grey[900]
    },
    subtitle1: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 20,
      lineHeight: "24px",
      letterSpacing: -0.15,
      color: grey[600]
    },
    subtitle2: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 20,
      lineHeight: "32px",
      letterSpacing: 0.25,
      color: grey[900]
    },
    body1: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 16,
      lineHeight: "24px",
      letterSpacing: -0.1,
      color: grey[900]
    },
    body2: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 14,
      lineHeight: "21px",
      color: grey[900]
    },
    overline: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 12,
      lineHeight: "15px",
      color: grey[700],
      textTransform: "none"
    },
    button: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 14,
      lineHeight: "16px",
      letterSpacing: 0.25,
      textTransform: "none",
      "&:disabled": {
        pointerEvents: "none"
      }
    },
    caption: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 12,
      lineHeight: "14px",
      letterSpacing: 0.1,
      color: grey[500]
    }
  },
  shadows,
  overrides: {
    MuiLink: {
      underlineHover: {
        textDecoration: "underline",
        textDecorationColor: "transparent",
        transition: transitions.create(["text-decoration-color"], {
          duration: transitions.duration.shortest
        })
      }
    },
    MuiTypography: {
      root: {
        "& .nav": {
          fontFamily: "Inter",
          color: grey[500],
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: 10,
          lineHeight: "20px",
          wordWrap: "break-word",
          maxHeight: "25px",
          alignItems: "center",
          textAlign: "center"
        },
        "& .active": {
          color: grey[900]
        }
      }
    },
    MuiTabs: {
      indicator: {
        backgroundColor: palette.merrigold.main,
        height: 4
      }
    },

    MuiPaper: {
      rounded: {
        borderRadius: 8
      }
    },

    MuiFormLabel: {
      root: {
        fontWeight: 500,
        "&$focused": {
          color: palette.primary.light
        }
      }
    },

    MuiTableSortLabel: {
      icon: {
        transitionProperty: "opacity, transform, color",
        opacity: 1,
        color: grey[100]
      },
      active: {
        color: grey[600]
      },
      root: {
        transitionProperty: "box-shadow, color",
        color: grey[600],
        "&:hover": {
          color: grey[700],
          "&& $icon": {
            opacity: 1,
            color: grey[500]
          },
          "&$active": {
            "&& $icon": {
              color: palette.primary.light
            }
          }
        },
        "&$active": {
          "&& $icon": {
            color: palette.primary.light
          }
        }
      }
    },

    MuiDialogTitle: {
      root: {
        paddingTop: 24,
        padding: 16,
        paddingLeft: 24
      }
    },
    MuiDialogContent: {
      root: {
        padding: 16,
        paddingLeft: 24
      }
    },

    MuiDialogActions: {
      root: {
        padding: 16,
        paddingLeft: 24
      }
    },

    MuiButton: {
      root: {
        textTransform: "unset",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 14,
        lineHeight: "16px",
        padding: "8px 16px",
        transitionProperty:
          "background-color, box-shadow, border, color, opacity"
      },

      // Contained buttons
      contained: {
        backgroundColor: palette.grey[50],
        color: grey[500],
        boxShadow: shadows[1],
        "&:hover": {
          boxShadow: shadows[2],
          backgroundColor: "#F1F3F7"
        },
        "&:active": {
          boxShadow: shadows[4],
          backgroundColor: "#F1F3F7"
        },
        "&:disabled": {
          boxShadow: "none",
          backgroundColor: grey[200],
          color: grey[100]
        }
      },
      containedPrimary: {
        transitionProperty:
          "background-color, box-shadow, border, color, opacity",
        color: palette.primary.contrastText,
        boxShadow: shadows[1],
        "&:hover": {
          backgroundColor: palette.primary.main,
          opacity: 0.8,
          color: palette.primary.contrastText,
          boxShadow: shadows[2]
        },
        "&:active": {
          backgroundColor: palette.primary.main,
          opacity: 0.8,
          color: palette.primary.contrastText,
          boxShadow: shadows[4]
        }
      },
      containedSecondary: {
        transitionProperty:
          "background-color, box-shadow, border, color, opacity",
        boxShadow: shadows[1],
        "&:not($disabled)": {
          "&.primary50": {
            backgroundColor: palette.primary[50],
            color: palette.primary.light
          },
          "&.error": {
            backgroundColor: palette.error.main,
            color: palette.error.contrastText
          },
          "&.success": {
            backgroundColor: palette.success.main,
            color: palette.success.contrastText
          },
          "&.warning": {
            backgroundColor: palette.warning.main,
            color: palette.warning.contrastText
          },
          "&:hover": {
            opacity: 0.8,
            boxShadow: shadows[2],
            "&.error": {
              backgroundColor: palette.error.main,
              color: palette.error.contrastText
            },
            "&.success": {
              backgroundColor: palette.success.main,
              color: palette.success.contrastText
            },
            "&.warning": {
              backgroundColor: palette.warning.main,
              color: palette.warning.contrastText
            }
          },
          "&:active": {
            opacity: 0.8,
            boxShadow: shadows[4],
            "&.error": {
              backgroundColor: palette.error.main,
              color: palette.error.contrastText
            },
            "&.success": {
              backgroundColor: palette.success.main,
              color: palette.success.contrastText
            },
            "&.warning": {
              backgroundColor: palette.warning.main,
              color: palette.warning.contrastText
            }
          }
        }
      },

      // Text buttons
      text: {
        color: grey[500],
        padding: "8px 16px",
        "&:disabled": {
          color: grey[200]
        }
      },
      textPrimary: {
        "&:hover": {
          color: palette.primary.main,
          backgroundColor: palette.primary[50]
        },
        "&:active": {
          color: palette.primary.main,
          backgroundColor: palette.primary[50]
        }
      },
      textSecondary: {
        "&:not($disabled)": {
          "&.error": {
            color: palette.error.main,
            "&:hover": {
              color: palette.error.main,
              backgroundColor: palette.error[50]
            },
            "&:active": {
              color: palette.error.main,
              backgroundColor: palette.error[50]
            }
          },
          "&.success": {
            color: palette.success.main,
            "&:hover": {
              color: palette.success.main,
              backgroundColor: palette.success[50]
            },
            "&:active": {
              color: palette.success.main,
              backgroundColor: palette.success[50]
            }
          },
          "&.warning": {
            color: palette.warning.main,
            "&:hover": {
              color: palette.warning.main,
              backgroundColor: palette.warning[50]
            },
            "&:active": {
              color: palette.warning.main,
              backgroundColor: palette.warning[50]
            }
          }
        }
      },

      // Outlined buttons
      outlined: {
        borderWidth: "2px !important",
        borderStyle: "solid",
        borderColor: grey[200],
        color: grey[500],
        "&:disabled": {
          borderColor: grey[100],
          color: grey[100]
        },
        "&:hover": {
          backgroundColor: grey[100]
        },
        "&:active": {
          backgroundColor: grey[100]
        }
      },
      outlinedPrimary: {
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: palette.primary.main,
        "&:hover": {
          backgroundColor: palette.primary[50]
        },
        "&:active": {
          backgroundColor: palette.primary[50]
        }
      },
      outlinedSecondary: {
        borderWidth: 2,
        borderStyle: "solid",
        "&:disabled": {
          borderColor: grey[100],
          color: grey[100]
        },
        "&:not($disabled)": {
          "&.error": {
            borderColor: palette.error.main,
            color: palette.error.main,
            "&:hover": {
              backgroundColor: palette.error[50]
            },
            "&:active": {
              backgroundColor: palette.error[50]
            }
          },
          "&.success": {
            borderColor: palette.success.main,
            color: palette.success.main,
            "&:hover": {
              backgroundColor: palette.success[50]
            },
            "&:active": {
              backgroundColor: palette.success[50]
            }
          },
          "&.warning": {
            borderColor: palette.warning.main,
            color: palette.warning.main,
            "&:hover": {
              backgroundColor: palette.warning[50]
            },
            "&:active": {
              backgroundColor: palette.warning[50]
            }
          }
        }
      },

      // Large buttons
      sizeLarge: {
        fontSize: 16,
        lineHeight: "26px"
      }
    },

    MuiTableCell: {
      head: {
        fontWeight: 600,
        color: grey[600]
      },
      body: {
        color: grey[600],
        fontWeight: "normal"
      }
    },

    MuiTooltip: {
      tooltip: {
        backgroundColor: grey[500],
        color: common.white,
        padding: 8,
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 12,
        lineHeight: "14px",
        letterSpacing: 0.1
      }
    },

    MuiTablePagination: {
      caption: {
        fontWeight: 400
      }
    },

    // Inputs
    MuiInputLabel: {
      root: {
        color: grey[500],
        fontWeight: 400
      }
    },

    MuiInputBase: {
      root: {
        fontFamily: "Inter",
        fontWeight: 400,

        color: grey[400],
        "&:not($disabled)": {
          "&$error": {
            color: palette.error.main
          },
          "&:hover": {
            "&:not($error)": {
              color: grey[500]
            }
          },
          "&$focused": {
            "&:not($error)": {
              color: grey[500]
            }
          },
          "&:active": {
            "&:not($error)": {
              color: grey[500]
            }
          }
        }
      },
      input: {
        color: grey[900]
      }
    },

    MuiOutlinedInput: {
      root: {
        borderRadius: 8,

        "&$focused": {
          boxShadow: "0px 0px 0px 4px rgba(36, 109, 218, 0.15)",
          "&$error": {
            boxShadow: "0px 0px 0px 4px #FFDEDE"
          }
        }
      },
      notchedOutline: {}
    },

    MuiFilledInput: {
      root: {
        color: grey[400],
        backgroundColor: grey[50],
        "&:hover": {
          backgroundColor: grey[100]
        },
        "&$focused": {
          backgroundColor: grey[50]
        },
        "&:active": {
          backgroundColor: grey[50]
        }
      },

      input: {
        color: grey[700],
        "&:hover": {
          color: grey[700]
        },
        "&$focused": {
          color: grey[700]
        },
        "&:active": {
          color: grey[700]
        }
      },

      underline: {
        borderBottom: `1px solid ${grey[400]}`,
        "&:hover": {
          borderBottomColor: grey[400]
        },
        "&:after": {
          borderBottom: `2px solid ${palette.primary.light}`,
          borderBottomColor: palette.primary.light
        }
      }
    },

    MuiInput: {
      underline: {
        borderBottom: `1px solid ${grey[400]}`,
        "&:after": {
          borderBottom: `2px solid ${palette.primary.light}`,
          borderBottomColor: palette.primary.light
        }
      }
    },

    MuiCheckbox: {
      root: {
        color: grey.inactive
      },
      colorPrimary: {
        "&$checked": {
          color: palette.primary.light
        }
      }
    },

    MuiRadio: {
      root: {
        color: grey.inactive
      },
      colorPrimary: {
        "&$checked": {
          color: palette.primary.light
        }
      }
    },

    MuiSwitch: {
      thumb: {
        "&:not($disabled)": {
          boxShadow:
            "0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)"
        }
      },
      switchBase: {
        "&$colorPrimary": {
          "&$checked": {
            color: palette.primary.light
          },
          "&$checked + $track": {
            backgroundColor: palette.primary[100]
          }
        }
      },
      track: {
        backgroundColor: grey[100]
      }
    },

    MuiSlider: {
      colorPrimary: {
        color: palette.primary.light,
        "& $valueLabel": {
          color: palette.primary.light
        }
      },
      thumbColorPrimary: {
        "&:not($disabled)": {
          color: palette.primary.light,
          backgroundColor: palette.primary.light
        }
      }
    },

    // Chips
    MuiChip: {
      // Default
      root: {
        fontFamily: "Inter",
        fontWeight: "normal",
        fontSize: 14,
        backgroundColor: grey[100],
        "&:focus": {
          backgroundColor: grey[100],
          "&$deletable": {
            backgroundColor: grey[100]
          }
        },
        "&$clickable": {
          "&:hover": {
            backgroundColor: grey[100]
          }
        }
      },
      deleteIcon: {
        color: grey[300],
        "&:hover": {
          color: grey[500]
        }
      },

      // Primary
      colorPrimary: {
        backgroundColor: palette.primary[50],
        color: palette.primary.main,
        "&$clickable": {
          "&:hover": {
            backgroundColor: palette.primary[100]
          }
        },
        "&:focus": {
          backgroundColor: palette.primary[50],
          "&$deletable": {
            backgroundColor: palette.primary[50]
          }
        }
      },
      deleteIconColorPrimary: {
        color: palette.primary.main,
        opacity: 0.5,
        "&:hover": {
          color: palette.primary.main,
          opacity: 1
        }
      },

      // Secondary (i.e. "success", "warning", "error")
      colorSecondary: {
        "&.success": {
          backgroundColor: palette.success[50],
          color: palette.success.main,
          "&$clickable": {
            "&:hover": {
              backgroundColor: palette.success[50]
            }
          },
          "&:focus": {
            backgroundColor: palette.success[50],
            "&$deletable": {
              backgroundColor: palette.success[50]
            }
          },
          "& $deleteIcon": {
            color: palette.success.main,
            opacity: 0.5,
            "&:hover": {
              color: palette.success.main,
              opacity: 1
            }
          },
          "& $avatar": {
            backgroundColor: palette.success.main
          }
        },
        "&.error": {
          backgroundColor: palette.error[50],
          color: palette.error.main,
          "&$clickable": {
            "&:hover": {
              backgroundColor: palette.error[50]
            }
          },
          "&:focus": {
            backgroundColor: palette.error[50],
            "&$deletable": {
              backgroundColor: palette.error[50]
            }
          },
          "& $deleteIcon": {
            color: palette.error.main,
            opacity: 0.5,
            "&:hover": {
              color: palette.error.main,
              opacity: 1
            }
          },
          "& $avatar": {
            backgroundColor: palette.error.main
          }
        },
        "&.warning": {
          backgroundColor: palette.warning[50],
          color: palette.warning.main,
          "&$clickable": {
            "&:hover": {
              backgroundColor: palette.warning[50]
            }
          },
          "&:focus": {
            backgroundColor: palette.warning[50],
            "&$deletable": {
              backgroundColor: palette.warning[50]
            }
          },
          "& $deleteIcon": {
            color: palette.warning.main,
            opacity: 0.5,
            "&:hover": {
              color: palette.warning.main,
              opacity: 1
            }
          },
          "& $avatar": {
            backgroundColor: palette.warning.main
          }
        }
      }
    }
  },
  props: {
    MuiLink: {
      variant: "body1"
    }
  }
};
