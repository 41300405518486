import Cookies from "js-cookie";
import {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
  useMemo
} from "react";

import { useSnackBarMessage, CookiesValues } from "Shared";
import { useMeLazyQuery, UserFragment } from "Shared/graphql/generated";

import { useExchangeToken } from "./hooks/useExchangeToken";
import { useLogout } from "./hooks/useLogout";

type AuthContext = {
  user?: UserFragment | null;
  isTokenExpired: boolean;
  isTokenLoading: boolean;
  isInitializing: boolean;
  isLoggingOut: boolean;
  logout: () => void;
  handleOneTimeToken: (token: string) => Promise<void>;
};

export const AuthContext = createContext<AuthContext>({
  user: undefined,
  isTokenExpired: false,
  isTokenLoading: false,
  isInitializing: true,
  isLoggingOut: false,
  logout: () => {},
  handleOneTimeToken: (token: string): Promise<void> =>
    token as unknown as Promise<void>
});

export const AuthProvider: React.FC = ({ children }) => {
  const [isInitializing, setIsInitializing] = useState<boolean>(true);
  const [user, setUser] = useState<UserFragment | undefined | null>();
  const { showError } = useSnackBarMessage();
  const [meLazyQuery] = useMeLazyQuery({ fetchPolicy: "no-cache" });
  const {
    exchangeToken,
    isLoading: isTokenLoading,
    isExpired
  } = useExchangeToken();
  const { doLogout, isLoading: isLoggingOut } = useLogout();

  const setAuthData = useCallback(async () => {
    const res = await meLazyQuery();

    if (res.error) {
      showError("Unable to login");
      return;
    }
    setUser(res.data?.me);
  }, [meLazyQuery, showError]);

  useEffect(() => {
    const auth = async () => {
      const {
        signedIn: { key }
      } = CookiesValues;

      const signed = Cookies.get(key);

      if (signed) {
        await setAuthData();
      }

      setIsInitializing(false);
    };

    void auth();
  }, [setAuthData]);

  const handleOneTimeToken = useCallback(
    async (token: string) => {
      const res = await exchangeToken({ oneTimeToken: token });

      if (res) {
        const { user } = res;

        setUser(user);
      }
    },
    [exchangeToken]
  );

  const logout = useCallback(async () => {
    setUser(undefined);

    await doLogout();
  }, [doLogout]);

  const auth = useMemo(
    () => ({
      user,
      isTokenLoading,
      logout,
      handleOneTimeToken,
      isTokenExpired: isExpired,
      isInitializing,
      isLoggingOut
    }),
    [
      handleOneTimeToken,
      isExpired,
      isInitializing,
      isLoggingOut,
      isTokenLoading,
      logout,
      user
    ]
  );

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
