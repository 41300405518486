import { useCallback } from "react";

import { useResponseHandler, useSnackBarMessage } from "Shared";
import {
  CreateAffiliationTaskSessionsInput,
  VerificationTaskFragment,
  GetVerificationTaskDocument,
  useCreateAffiliationTaskSessionsMutation,
  CreateAffiliationTaskSessionsMutationOptions
} from "Shared/graphql/generated";

const successSaveAndDeferMessage = "Verification marked as 'save and defer'";
const successCompleteMessage = "Verification is completed";

export const useCreateAffiliationTaskSessions = (
  options?: CreateAffiliationTaskSessionsMutationOptions
) => {
  const { handleResponse } = useResponseHandler();
  const { showSuccess } = useSnackBarMessage();

  const [mutation, { loading }] = useCreateAffiliationTaskSessionsMutation({
    refetchQueries: [GetVerificationTaskDocument],
    fetchPolicy: "no-cache",
    ...options
  });

  const createAffiliationTask = useCallback(
    async ({
      variables,
      isComplete
    }: {
      variables: CreateAffiliationTaskSessionsInput;
      isComplete?: boolean;
    }): Promise<VerificationTaskFragment | undefined | null> => {
      const res = await mutation({
        variables
      });
      const result = res.data?.createAffiliationTaskSessions;
      if (result) {
        const { response, isSuccess } = handleResponse(result);
        if (isSuccess) {
          showSuccess(
            isComplete ? successCompleteMessage : successSaveAndDeferMessage
          );
          return response.task;
        }
      }
    },
    [mutation, handleResponse, showSuccess]
  );

  return {
    createAffiliationTask,
    isLoading: loading
  };
};
