import { HCPTable } from "OrganizationValidation/components";
import { HCPTaskData } from "OrganizationValidation/hooks";
import { ExpandSection, GenericContainer } from "Shared";

type PreviouslyVerifiedHCPTaskProps = Omit<
  HCPTaskData,
  "initialValues" | "initialPhoneNumbers"
>;

export const PreviouslyVerifiedHCPTask = ({
  isLoading,
  rows,
  count,
  pagination
}: PreviouslyVerifiedHCPTaskProps) => {
  return (
    <ExpandSection title="Previously Verified HCPs">
      <GenericContainer
        isLoading={isLoading}
        data={rows}
        noDataMessage="No Organization Task Found"
      >
        {(rows) => (
          <HCPTable
            rows={rows}
            count={count}
            pagination={pagination}
            readonly
          />
        )}
      </GenericContainer>
    </ExpandSection>
  );
};
