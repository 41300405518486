import { TaskStatus } from "Shared/graphql/generated";

type TPossibleColorStatus = "primary" | "textPrimary" | "secondary";

export const getVerificationTaskStatusData = (
  completed: boolean,
  status: string = TaskStatus.Incomplete
): {
  status: string;
  color: TPossibleColorStatus;
} => {
  if (completed) {
    return { status: "Completed", color: "primary" };
  }

  if (status === TaskStatus.Deferred) {
    return { status: "Saved and deferred", color: "secondary" };
  }

  return { status: "Incomplete", color: "textPrimary" };
};
