import omit from "lodash/omit";
import { useCallback } from "react";

import {
  findDifference,
  TOrgVerificationInitialValues,
  transformInitialValuesTOHoursOfOperation,
  useModal
} from "Shared";
import {
  OrganizationSessionUpdateInput,
  TaskStatus
} from "Shared/graphql/generated";
import {
  canComplete,
  canSaveAndDefer,
  getOrgValidationVariables
} from "OrganizationValidation/helpers";
import { useOrgDataContext } from "OrganizationValidation/context";

import { useUpdateOrganizationTaskSession } from "./useUpdateOrganizationTaskSession";
import { useCreateOrganizationTaskSessions } from "./useCreateOrganizationTaskSessions";

export type TUseOrganizationActionsProps = {
  initialValues: TOrgVerificationInitialValues;
  updatedValues: TOrgVerificationInitialValues;
  verificationTaskId: string;
  isValid: boolean;
  sessionId?: string;
};

export const useOrganizationActions = ({
  initialValues,
  updatedValues,
  verificationTaskId,
  isValid,
  sessionId
}: TUseOrganizationActionsProps) => {
  const { isOpen, closeModal, openModal } = useModal();

  const { createOrgTaskSession } = useCreateOrganizationTaskSessions();
  const { isUpdating, updateOrgTask } = useUpdateOrganizationTaskSession();
  const { setOrgData } = useOrgDataContext();

  const isSaveAndDeferEnabled =
    isValid && canSaveAndDefer(updatedValues.phoneNumbers);
  const isCompleteButtonEnabled =
    isValid && canComplete(updatedValues.phoneNumbers);
  const isUpdateButtonEnabled = isValid && !isUpdating;

  const onSaveAndDefer = useCallback(async () => {
    const changedValues = findDifference(initialValues, updatedValues);

    const variables = getOrgValidationVariables({
      formData: {
        phoneNumbers: updatedValues.phoneNumbers,
        notes: updatedValues.notes,
        ...changedValues
      },
      verificationTaskId,
      status: TaskStatus.Deferred
    });

    if (variables) {
      await createOrgTaskSession({ variables, isComplete: false });
      setOrgData(variables.taskSessions?.[0].update);
    }
  }, [
    createOrgTaskSession,
    initialValues,
    setOrgData,
    updatedValues,
    verificationTaskId
  ]);

  const onComplete = useCallback(async () => {
    const changedValues = findDifference(initialValues, updatedValues);

    const variables = getOrgValidationVariables({
      formData: {
        phoneNumbers: updatedValues.phoneNumbers,
        notes: updatedValues.notes,
        ...changedValues
      },
      verificationTaskId,
      status: TaskStatus.Complete
    });

    if (variables) {
      await createOrgTaskSession({ variables, isComplete: true });
      setOrgData(variables.taskSessions[0].update);
      closeModal();
    }
  }, [
    closeModal,
    createOrgTaskSession,
    initialValues,
    setOrgData,
    updatedValues,
    verificationTaskId
  ]);

  const onUpdate = useCallback(async () => {
    const newValues = findDifference(initialValues, updatedValues);

    if (sessionId) {
      const newValuesWithoutTransformation = omit(
        newValues,
        "phoneNumbers",
        "hoursOfOperationOrganization"
      );
      const operationHours = transformInitialValuesTOHoursOfOperation(
        [],
        newValues.hoursOfOperationOrganization
      );
      const updateData: OrganizationSessionUpdateInput = {
        ...newValuesWithoutTransformation,
        operationHours
      };

      await updateOrgTask({
        id: sessionId,
        update: updateData
      });
      setOrgData(updateData);
    }
  }, [initialValues, sessionId, setOrgData, updateOrgTask, updatedValues]);

  return {
    onComplete,
    onSaveAndDefer,
    onUpdate,
    closeModal,
    openModal,
    isOpen,
    isSaveAndDeferEnabled,
    isCompleteButtonEnabled,
    isUpdateButtonEnabled
  };
};
