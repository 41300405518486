import { useEffect } from "react";
import { useFormikContext } from "formik";

import { useVerifyCallContext } from "OrganizationValidation/context";

import { TFromPhoneNumber } from "Shared";

export const WatchForVerifyPhoneContacts = ({
  children
}: {
  children: JSX.Element;
}) => {
  const { isValid, values } = useFormikContext<{
    phoneNumbers: TFromPhoneNumber[];
  }>();

  const { setIsVerifyCallSelected, setPhoneNumbers } = useVerifyCallContext();

  useEffect(() => {
    setIsVerifyCallSelected(isValid);
  }, [isValid, setIsVerifyCallSelected]);

  useEffect(() => {
    setPhoneNumbers(values.phoneNumbers);
  }, [values, setPhoneNumbers]);

  return children;
};
