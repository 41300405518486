import { Link } from "react-router-dom";
import { Box, makeStyles, Typography, Button } from "@material-ui/core";

import { Logo } from "Shared";
import { RouteName, useAuth } from "App/routes";

import { GoBackLink } from "./GoBackLink";

const useStyles = makeStyles((theme) => ({
  headerPaper: {
    padding: "12px 40px",
    borderBottom: `1px solid ${theme.palette.grey[100]}`
  },
  signText: {
    fontWeight: 600,
    color: theme.palette.grey[500]
  },
  signButton: {
    marginLeft: 20
  }
}));

export const Header = (): JSX.Element => {
  const classes = useStyles();
  const { logout, user } = useAuth();
  const userEmail = user?.email ?? "";

  return (
    <Box className={classes.headerPaper}>
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="center">
          <Link to={RouteName.TaskSelection}>
            <Logo />
          </Link>
          <GoBackLink />
        </Box>
        <Box display="flex" alignItems="center">
          <Typography variant="body2">{userEmail}</Typography>
          <Button onClick={logout} className={classes.signButton}>
            <Typography variant="body2" className={classes.signText}>
              Sign Out
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
