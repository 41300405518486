import { useState } from "react";

import { TPagination, TPaginationLimit } from "../../Shared";

export const usePagination = (): TPagination => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState<TPaginationLimit>(25);

  return {
    page,
    setPage,
    limit,
    setLimit
  };
};
