import orderBy from "lodash/orderBy";
import { useMemo } from "react";

import { ESortDirection, useFormikField, TAutocomplete } from "Shared";

import { FormikAutocompleteSingleComponent } from "./index";

export type FormikSingleAutocompleteProps = Omit<
  TAutocomplete,
  "handleChange" | "onBlur" | "autocomplete" | "autocompleteValue" | "multiple"
>;

export const FormikAutocompleteSingle = (
  props: FormikSingleAutocompleteProps
) => {
  const { options, sort, name } = props;
  const formikPassedProps = useFormikField<string>(name);

  const sortedOptions = useMemo(
    () =>
      orderBy(
        options,
        [(option) => (sort ? sort(option) : option.label)],
        ESortDirection.ASC
      ),
    [options, sort]
  );

  const passedProps = {
    ...props,
    options: sortedOptions
  };

  return (
    <FormikAutocompleteSingleComponent
      {...formikPassedProps}
      {...passedProps}
    />
  );
};
