import { Navigate, useLocation } from "react-router-dom";

import { RouteName } from "App";
import { useAuth } from "Auth";

export const ProtectedRoute: React.FC = ({ children }) => {
  const { user } = useAuth();

  // if they try to visit /settings but aren’t logged in,
  // after we redirect them and they log in,
  // we should take them back to /settings, not dashboard.
  // state={{ path: location.pathname }}
  const location = useLocation();

  return user ? (
    <>{children}</>
  ) : (
    <Navigate
      replace
      to={`/${RouteName.SignIn}`}
      state={{ path: location.pathname }}
    />
  );
};
