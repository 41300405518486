import { useEffect, useState } from "react";
import { Formik } from "formik";
import { Box, CircularProgress, Container } from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
import { Navigate, useLocation } from "react-router";

import { useSetDocumentTitle, useUrlQuery, PageProps } from "Shared";
import { RouteName, useAuth } from "App";

import { RequestTokenMutationVariables } from "Shared/graphql/generated";

import { PaperWithLogo, Confirmation, SignInForm } from "./components";
import { signinInitialValues, signinValidationSchema } from "./authHelpers";

import { useRequestToken } from "./hooks";

const SIGN_IN_LABEL = "Sign in";
const CONFIRMATION_LABEL = "Please check your email";
const TOKEN_KEY = "token";

export const SignIn = ({ title }: PageProps) => {
  const [email, setEmail] = useState<string | null>(null);
  const {
    handleOneTimeToken,
    user,
    isTokenLoading,
    isTokenExpired,
    isInitializing,
    isLoggingOut
  } = useAuth();
  const { searchParams } = useUrlQuery();
  const location = useLocation();

  useSetDocumentTitle(title);

  useEffect(() => {
    const token = searchParams.get(TOKEN_KEY);

    if (token) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      handleOneTimeToken(token);
    }
  }, [searchParams, handleOneTimeToken]);

  const { isLoading, requestToken } = useRequestToken();

  const onSubmit = async ({ email }: RequestTokenMutationVariables) => {
    const res = await requestToken({ email });
    if (res) {
      setEmail(email);
    }
  };

  const redirectRoute =
    (location.state as { path?: string })?.path ||
    `/${RouteName.TaskSelection}`;

  if (isInitializing || isLoggingOut) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        flexDirection="column"
      >
        <CircularProgress />
      </Box>
    );
  }

  return user ? (
    <Navigate replace to={redirectRoute} />
  ) : (
    <Container>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        flexDirection="column"
      >
        {isTokenLoading ? (
          <CircularProgress />
        ) : (
          <>
            {isTokenExpired && (
              <MuiAlert severity="error">
                The link used is expired. Please enter your email address and
                try again.
              </MuiAlert>
            )}

            <PaperWithLogo label={email ? CONFIRMATION_LABEL : SIGN_IN_LABEL}>
              <Box mt={3}>
                <Formik
                  initialValues={signinInitialValues}
                  validationSchema={signinValidationSchema}
                  onSubmit={onSubmit}
                >
                  {!email ? (
                    <SignInForm isLoading={isLoading} />
                  ) : (
                    <>
                      <Confirmation
                        email={email}
                        goBack={() => setEmail(null)}
                      />
                    </>
                  )}
                </Formik>
              </Box>
            </PaperWithLogo>
          </>
        )}
      </Box>
    </Container>
  );
};
