import { useSearchParams } from "react-router-dom";

export const useUrlQuery = (): {
  addQueryParam: (key: string, value: string) => void;
  removeQueryParam: (key: string) => void;
  searchParams: URLSearchParams;
} => {
  const [searchParams, setSearchParams] = useSearchParams();

  const addQueryParam = (key: string, value: string) => {
    searchParams.append(key, value);
    setSearchParams(searchParams);
  };

  const removeQueryParam = (key: string) => {
    searchParams.delete(key);
    setSearchParams(searchParams);
  };

  return { addQueryParam, removeQueryParam, searchParams };
};
