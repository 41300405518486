import { TextField, Box, Typography } from "@material-ui/core";
import { memo } from "react";

import type { TextFieldProps } from "@material-ui/core";

export type TextFieldInputProps = {
  name: string;
  subText?: string;
  shrink?: boolean;
} & TextFieldProps;

export const TextFieldInput = memo(
  ({
    name,
    subText,
    shrink,
    autoComplete = "chrome-off",
    ...rest
  }: TextFieldInputProps): JSX.Element => {
    return (
      <>
        <TextField
          fullWidth
          variant="outlined"
          autoComplete={autoComplete}
          type="text"
          id={name}
          InputLabelProps={{
            shrink
          }}
          {...rest}
        />
        {subText && (
          <Box pr={2} pl={2}>
            <Typography variant="caption">{subText}</Typography>
          </Box>
        )}
      </>
    );
  }
);
