import { useCallback } from "react";

import { useResponseHandler, useSnackBarMessage } from "Shared";
import {
  FindOrganizationTaskDocument,
  UpdateOrganizationCallSessionInput,
  useUpdateOrganizationTaskSessionMutation
} from "Shared/graphql/generated";

export const useUpdateOrganizationTaskSession = () => {
  const [mutate, { loading }] = useUpdateOrganizationTaskSessionMutation({
    refetchQueries: [FindOrganizationTaskDocument]
  });

  const { handleResponse } = useResponseHandler();
  const { showSuccess } = useSnackBarMessage();

  const updateOrgTask = useCallback(
    async (variables: UpdateOrganizationCallSessionInput) => {
      const res = await mutate({ variables });
      const result = res.data?.updateOrganizationTaskSession;

      if (result) {
        const { response, isSuccess } = handleResponse(result);

        if (isSuccess) {
          showSuccess("Organization task information updated");
        }

        return response;
      }
    },
    [handleResponse, mutate, showSuccess]
  );

  return {
    isUpdating: loading,
    updateOrgTask
  };
};
