import {
  createContext,
  useState,
  useContext,
  useCallback,
  useMemo
} from "react";

import {
  findSomeRowsAreDirty,
  TTableState
} from "OrganizationValidation/helpers";

import { PopupOnLeave } from "Shared";

export const PreventNavigationPopupContext = createContext<{
  setIsOrgFormIsDirty: (isOpenned: boolean) => void;
  setTableFormDirtyRows: (options: TTableState) => void;
  makeRowDirty: ({ isDirty, id }: { isDirty: boolean; id: string }) => void;
}>({
  setTableFormDirtyRows: () => {},
  setIsOrgFormIsDirty: () => {},
  makeRowDirty: () => {}
});

// we need this context to track forms' states
// and showing alert popup to prevent navigation
// by showing alert popup
export const PreventNavigationPopupContextProvider: React.FC = ({
  children
}) => {
  const { Provider } = PreventNavigationPopupContext;

  const [tableFormDirtyRows, setTableFormDirtyRows] = useState<TTableState>({});
  const [isOrgFormIsDirty, setIsOrgFormIsDirty] = useState<boolean>(false);

  // This is high order function that locally geters data in
  // in the 'localData' variable and then returns nested function that
  // takes data from the certain form
  const memoizedTableFormChange = useCallback(
    ({ id, isDirty }: { isDirty: boolean; id: string }) => {
      setTableFormDirtyRows((prevState: TTableState) => {
        if (
          !(id in prevState) ||
          (id in prevState && prevState[id] !== isDirty)
        ) {
          const newState = { ...prevState, [id]: isDirty };
          return newState;
        }

        return prevState;
      });
    },
    []
  );

  const someFormsAreDirty = findSomeRowsAreDirty(tableFormDirtyRows);
  const isDirty = someFormsAreDirty || isOrgFormIsDirty;

  const values = useMemo(
    () => ({
      setIsOrgFormIsDirty,
      setTableFormDirtyRows,
      makeRowDirty: memoizedTableFormChange
    }),
    [memoizedTableFormChange]
  );

  return (
    <Provider value={values}>
      <PopupOnLeave dirty={isDirty} />
      {children}
    </Provider>
  );
};

export const usePreventNavigationPopupContext = () =>
  useContext(PreventNavigationPopupContext);
