import { Box, Typography } from "@material-ui/core";

type InfoItemProps = {
  label: string;
  content: string;
};

export const InfoItem = ({ label, content }: InfoItemProps): JSX.Element => {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h6">{label}</Typography>
      <Typography variant="body2">{content}</Typography>
    </Box>
  );
};
