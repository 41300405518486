import { Box, makeStyles, TextField } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { useField } from "formik";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  goToButton: {
    display: "inline-flex",
    position: "absolute",
    right: "20px",
    top: "18px"
  }
});

export type UrlTextFieldProps = {
  name: string;
  label: string;
};

export const UrlTextField = ({ name, label }: UrlTextFieldProps) => {
  const [{ value, ...rest }, { touched, error }] = useField<string>(name);
  const { goToButton } = useStyles();
  const url = value?.replace(/https?:/, "");

  return (
    <Box position="relative">
      <TextField
        fullWidth
        variant="outlined"
        type="text"
        label={label}
        InputLabelProps={{
          shrink: true
        }}
        value={value}
        {...rest}
        error={touched && !!error}
        helperText={touched && error}
      />
      {value && (
        <Link to={url} target="_blank" className={goToButton}>
          <OpenInNewIcon fontSize="small" />
        </Link>
      )}
    </Box>
  );
};
