import { AcceptingNewPatients } from "Shared/types";

const AcceptingNewPatientsMap = {
  [AcceptingNewPatients.YES]: true,
  [AcceptingNewPatients.NO]: false,
  [AcceptingNewPatients.UNKNOWN]: null
};

export const transformAcceptingNewPatientToVariable = (
  option: keyof typeof AcceptingNewPatients
) => {
  if (option in AcceptingNewPatientsMap) {
    return AcceptingNewPatientsMap[option];
  }

  return undefined;
};

export const transformAcceptingNewPatientToInitialValue = (
  option: boolean | null | undefined
) => {
  if (option === true) {
    return AcceptingNewPatients.YES;
  }
  if (option === false) {
    return AcceptingNewPatients.NO;
  }
  if (option === null || option === undefined) {
    return AcceptingNewPatients.UNKNOWN;
  }

  return undefined;
};
