export type DateRangeValues = {
  days: boolean[];
  open: string;
  close: string;
};

export enum DateRangeKeys {
  OPEN = "open",
  CLOSE = "close",
  DAYS = "days"
}

export const DAYS_OF_THE_WEEK = [
  { label: "Su", index: 0 },
  { label: "M", index: 1 },
  { label: "T", index: 2 },
  { label: "W", index: 3 },
  { label: "Th", index: 4 },
  { label: "F", index: 5 },
  { label: "Sa", index: 6 }
];
