import { Container, Box } from "@material-ui/core";

export const SectionContainer: React.FC = ({ children }) => {
  return (
    <Container
      component={Box}
      mb={2}
      bgcolor="#fff"
      paddingY={2}
      borderRadius={8}
    >
      <>{children}</>
    </Container>
  );
};
