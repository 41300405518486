import { Grid, makeStyles, Typography } from "@material-ui/core";

import { formatDateString, ShortText } from "Shared";

const useStyles = makeStyles(() => ({
  boldText: {
    fontWeight: 600
  }
}));

export type StatusInfoProps = {
  lastUpdatedBy?: string;
  lastUpdatedOn?: string;
  completedBy?: string;
  completedOn?: string;
};

export const StatusInfo = ({
  lastUpdatedBy,
  lastUpdatedOn,
  completedBy,
  completedOn
}: StatusInfoProps) => {
  const { boldText } = useStyles();

  return (
    <Grid spacing={2} container>
      {completedBy && (
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2" className={boldText}>
                Task Completed by:
              </Typography>
              <ShortText title={completedBy} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" className={boldText}>
                Completed Date:
                <Typography variant="body2">
                  {formatDateString(completedOn || "")}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {lastUpdatedBy && (
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2" className={boldText}>
                Last Updated by:
              </Typography>
              <ShortText title={lastUpdatedBy} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" className={boldText}>
                Last Updated Date:
                <Typography variant="body2">
                  {formatDateString(lastUpdatedOn || "")}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
