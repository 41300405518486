import { memo } from "react";
import { Box, Button, makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import { Theme } from "Shared/styles";

import { FormikDateRange } from "../formik";

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    minWidth: "5%",
    padding: "0"
  },
  applyButton: {
    backgroundColor: theme.palette.primary.ultraLight,
    color: theme.palette.primary.main
  }
}));

type OperationHoursProps = {
  dateRangeName: string;
  disableDelete: boolean;
  onApply: () => void;
  onRemove: () => void;
};

export const OperationHours = memo(
  ({
    dateRangeName,
    onApply,
    onRemove,
    disableDelete
  }: OperationHoursProps) => {
    const { deleteButton, applyButton } = useStyles();

    return (
      <>
        <Box mt={3} key={dateRangeName} display="flex" marginTop="12px">
          <FormikDateRange name={dateRangeName} />
          <Button
            onClick={onRemove}
            disabled={disableDelete}
            className={deleteButton}
          >
            <DeleteIcon color="inherit" />
          </Button>
        </Box>
        <Button
          onClick={onApply}
          color="primary"
          variant="contained"
          className={applyButton}
        >
          Apply 24/7
        </Button>
      </>
    );
  }
);
