import { Collapse, Grid, Box } from "@material-ui/core";

import { FormikTextField, AddLanguagesSpoken, FormikInputMask } from "Shared";

export type CollapseFormProps = {
  isOpen: boolean;
  readonly: boolean;
};

const noteStyles = {
  backgroundColor: "#fff",
  borderRadius: "8px"
};

export const CollapseForm = ({ isOpen, readonly }: CollapseFormProps) => {
  return (
    <Collapse in={isOpen}>
      <Box paddingY={3}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormikTextField name="firstName" label="First Name" required />
              </Grid>
              <Grid item xs={6}>
                <FormikTextField name="middleName" label="Middle Name" />
              </Grid>
              <Grid item xs={6}>
                <FormikTextField name="lastName" label="Last Name" required />
              </Grid>
              <Grid item xs={6}>
                <FormikTextField name="nickname" label="Nickname" />
              </Grid>
              <Grid item xs={12}>
                <FormikTextField name="email" label="Person Org Email" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormikTextField name="department" label="Department" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikTextField
                      name="departmentRole"
                      label="Department Title"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <AddLanguagesSpoken disabled={readonly} />
                  </Grid>
                  <Grid item xs={4}>
                    <FormikInputMask
                      mask="999-999-9999"
                      name="appointmentPhoneNumber"
                      label="Appointment Phone #"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FormikTextField
                      name="appointmentPhoneNumberExt"
                      label="Phone Ext."
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormikInputMask
                      mask="999-999-9999"
                      name="directPhoneNumber"
                      label="Direct Office Phone #"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormikInputMask
                      mask="999-999-9999"
                      name="personalPhoneNumber"
                      label="Personal Phone #"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormikInputMask
                      mask="999-999-9999"
                      name="directFaxNumber"
                      label="Direct Fax Number"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <FormikTextField
                  style={noteStyles}
                  name="notes"
                  variant="outlined"
                  multiline
                  rows={8}
                  placeholder="Notes"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Collapse>
  );
};
