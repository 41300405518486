import { makeStyles, Menu as MuiMenu, MenuItem } from "@material-ui/core";

import type { MenuProps } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  menuItem: {
    fontSize: "14px",
    padding: "3px 16px"
  }
}));

type FormikMenuProps = MenuProps & {
  items: {
    label: string;
    value?: string;
  }[];
};

export const FormikMenu = ({ items, ...rest }: FormikMenuProps) => {
  const { menuItem } = useStyles();

  return (
    <MuiMenu {...rest}>
      {items.map(({ label, value }) => (
        <MenuItem key={`${value || ""}${label}`} className={menuItem}>
          {label}
        </MenuItem>
      ))}
    </MuiMenu>
  );
};
