import {
  nullValuesToUndefined,
  transformPhoneNumbersToInitialValues,
  transformOperationHoursToInitialValues,
  TFromPhoneNumber,
  DateRangeValues
} from "Shared";
import { OrganizationTaskFragment } from "Shared/graphql/generated";
import { Complete } from "Shared/types";

export type TOrgVerificationInitialValues = Complete<{
  phoneNumbers?: TFromPhoneNumber[];
  orgName?: string;
  orgAddressLine1?: string;
  orgAddressLine2?: string;
  orgAddressLocality?: string;
  orgAddressPostalCode?: string;
  orgAddressRegion?: string;
  orgFaxNumber?: string;
  notes?: string;
  hoursOfOperationOrganization?: DateRangeValues[];
  domainName?: string;
  affiliationVerificationUrl?: string;
  affiliationVerificationUrlDetail?: string;
}>;

export const transformOrgTaskToInitialValues = (
  organizationTask: OrganizationTaskFragment
): TOrgVerificationInitialValues => {
  const {
    orgName,
    orgAddressLine1,
    orgAddressLine2,
    orgAddressLocality,
    orgAddressPostalCode,
    orgAddressRegion,
    orgFaxNumber,
    domainName,
    affiliationVerificationUrl,
    affiliationVerificationUrlDetail
  } = nullValuesToUndefined(organizationTask?.data);

  const initialPhoneNumbers = transformPhoneNumbersToInitialValues(
    organizationTask?.phoneNumbers
  );

  const { hoursOfOperationOrganization } =
    transformOperationHoursToInitialValues(
      organizationTask?.data?.operationHours || []
    );

  // TODO: update we don't need "|| undefined" for values after nullValuesToUndefined, need to update nullValuesToUndefined return type first
  return {
    phoneNumbers: initialPhoneNumbers,
    orgName: orgName || undefined,
    orgAddressLine1: orgAddressLine1 || undefined,
    orgAddressLine2: orgAddressLine2 || undefined,
    orgAddressLocality: orgAddressLocality || undefined,
    orgAddressPostalCode: orgAddressPostalCode || undefined,
    orgAddressRegion: orgAddressRegion || undefined,
    orgFaxNumber: orgFaxNumber || undefined,
    notes: organizationTask?.lastSession?.notes || undefined,
    hoursOfOperationOrganization,
    domainName: domainName || undefined,
    affiliationVerificationUrl: affiliationVerificationUrl || undefined,
    affiliationVerificationUrlDetail:
      affiliationVerificationUrlDetail || undefined
  };
};
