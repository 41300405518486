import React from "react";
import * as FullStory from "@fullstory/browser";
import ReactDOM from "react-dom";

import { AppRoutes, ApolloClientProvider } from "App";
import { AuthProvider } from "Auth";
import { ThemeProvider, SnackbarProvider, FeatureFlagsProvider } from "Shared";
import { FeatureFlagInitializer } from "Shared/FeatureFlags/FeatureFlagInitializer";
import { FullStoryInitializer } from "Shared/FullStory/FullStoryInitializer";

// init FullStory
const ORG_ID = process.env["REACT_APP_FULLSTORY_ORG_ID"];
if (ORG_ID) {
  FullStory.init({ orgId: ORG_ID });
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <SnackbarProvider>
        <ApolloClientProvider>
          <FeatureFlagsProvider>
            <AuthProvider>
              <FeatureFlagInitializer />
              {ORG_ID && <FullStoryInitializer />}
              <AppRoutes />
            </AuthProvider>
          </FeatureFlagsProvider>
        </ApolloClientProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
