import * as Yup from "yup";

const errorMessage = "Please enter a valid email address.";

const emailValidation = Yup.string().email(errorMessage).required(errorMessage);

export const signinInitialValues = {
  email: ""
};

export const signinValidationSchema = Yup.object().shape({
  email: emailValidation
});
