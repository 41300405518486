import * as Yup from "yup";

import { isValueURL } from "../isValidUrl";

import { ValidationType } from "./type";

export const urlValidation = ({ type, field }: ValidationType) =>
  Yup.string().test(
    type,
    `${field} must be a valid URL. Example: https://www.h1.co/`,
    (value) => {
      if (value) {
        return isValueURL(value);
      }
      return true;
    }
  );
