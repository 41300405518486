import Cookies from "js-cookie";

import { useCallback } from "react";

import { CookiesValues, useResponseHandler, useSnackBarMessage } from "Shared";
import { useLogoutMutation } from "Shared/graphql/generated";

export const useLogout = () => {
  const { showError } = useSnackBarMessage();
  const { handleResponse } = useResponseHandler();

  const [logoutMutation, { loading }] = useLogoutMutation({
    fetchPolicy: "no-cache"
  });

  const doLogout = useCallback(async () => {
    const res = await logoutMutation();
    const result = res.data?.logout;
    if (result) {
      const { response, isSuccess } = handleResponse(result);

      if (!isSuccess) {
        showError(response.message);
        return;
      }

      const {
        signedIn: { key }
      } = CookiesValues;

      Cookies.remove(key);
    }
  }, [logoutMutation, handleResponse, showError]);

  return { doLogout, isLoading: loading };
};
