import { acceptPatientsOptions } from "../../constants";

import { FormikRadioGroup } from "./FormikRadioGroup/FormikRadioGroupContainer";

export type FormikAcceptNewPatientsProps = {
  label?: string;
  disabled?: boolean;
};

const fieldName = "isAcceptNewPatients";

export const FormikAcceptNewPatients = ({
  label,
  disabled = false
}: FormikAcceptNewPatientsProps) => {
  return (
    <FormikRadioGroup
      disabled={disabled}
      label={label}
      name={fieldName}
      options={acceptPatientsOptions}
    />
  );
};
