import flatMapDeep from "lodash/flatMapDeep";

import { DateRangeValues } from "Shared";
import {
  OperationHourType,
  OperationHoursFragment
} from "Shared/graphql/generated";

// our current format is an array of {days: boolean[], ...} we need to transform that into an array of
// {day: number}
const transformDaysArray = ({
  type,
  values
}: {
  type: OperationHourType;
  values: DateRangeValues[];
}): OperationHoursFragment[] => {
  const hours = flatMapDeep(values, ({ days, open, close }) =>
    days.reduce((accumulator: OperationHoursFragment[], day, index) => {
      if (day) {
        const operationHour: OperationHoursFragment = {
          day: index.toString(),
          open,
          close,
          type
        };
        accumulator.push(operationHour);
      }
      return accumulator;
    }, [])
  );

  return hours;
};

export const transformInitialValuesTOHoursOfOperation = (
  hoursOfOperationDepartment: DateRangeValues[] = [],
  hoursOfOperationOrg: DateRangeValues[] = []
): OperationHoursFragment[] | undefined => {
  const departmentHours = transformDaysArray({
    type: OperationHourType.Department,
    values: hoursOfOperationDepartment
  });

  const organizationHours = transformDaysArray({
    type: OperationHourType.Organization,
    values: hoursOfOperationOrg
  });

  const operationHours = [...departmentHours, ...organizationHours];
  if (operationHours.length) {
    return operationHours;
  }
};

const daysArray = new Array(7).fill(false);

const getCombinedDays = (operationHours: OperationHoursFragment[]) => {
  const keyMap = {} as Record<string, DateRangeValues>;
  operationHours.forEach(({ day, close, open }) => {
    const key = `${open}${close}`;
    // if there is no key matching the corresponding open/closing time, initialize the Date Range Values
    if (!keyMap[key]) {
      keyMap[key] = {
        days: [...daysArray],
        open,
        close
      };
    }

    keyMap[key].days[+day] = true;
  });

  return keyMap;
};

export const getHoursByType = ({
  operationHours,
  operationType
}: {
  operationHours: OperationHoursFragment[];
  operationType: OperationHourType;
}) => {
  const filteredHoursByType = operationHours.filter(
    ({ type }) => type === operationType
  );

  const combinedDays = Object.values(getCombinedDays(filteredHoursByType));

  return combinedDays;
};

export const transformOperationHoursToInitialValues = (
  operationHours: OperationHoursFragment[]
): {
  hoursOfOperationDepartment?: DateRangeValues[];
  hoursOfOperationOrganization?: DateRangeValues[];
} => {
  const hoursOfOperationOrganization = getHoursByType({
    operationHours,
    operationType: OperationHourType.Organization
  });

  const hoursOfOperationDepartment = getHoursByType({
    operationHours,
    operationType: OperationHourType.Department
  });

  return {
    hoursOfOperationDepartment,
    hoursOfOperationOrganization
  };
};
