import { ComponentType, ReactNode } from "react";
import { generatePath, Route } from "react-router-dom";

import { isArray } from "lodash";

import { SignIn, TaskSelection } from "Auth";

import { ValidationForm } from "AffiliationValidation/sections";

import {
  AffiliationValidation,
  NPISearch,
  OrgIdSearch,
  OrganizationValidationPage
} from "./Pages";

import type { Params } from "react-router-dom";

export enum RouteName {
  TaskSelection = "task-selection",
  SignIn = "signin",
  AffiliationValidation = "affiliation-validation",
  NPISearch = "npi-search",
  OrganizationValidation = "organization-validation",
  OrganizationValidationWithParams = `organization-validation/:orgId`,
  OrgIdSearch = "org-id-search"
}

enum RouteTitle {
  TaskSelection = "BPO - Select Task Type",
  SignIn = "BPO - Sign in",
  AffiliationValidation = "BPO - ",
  NPISearch = "BPO - NPI Search",
  OrganizationValidation = "BPO Org Task - ",
  OrgIdSearch = "BPO - Org ID Search"
}

type UnionRouteName = RouteName | NestedRouteName;

export enum NestedRouteName {
  Task = "task"
}

enum NestedRoutTitle {
  Task = "BPO - Select Task Type"
}

export type RouteConfig = {
  path: string;
  component: ComponentType<{ title: string }>;
  title: string;
  protected?: boolean;
  nested?: Record<NestedRouteName, RouteConfig>;
};

const NestedRoutes: Record<NestedRouteName, RouteConfig> = {
  [NestedRouteName.Task]: {
    path: `${NestedRouteName.Task}/:taskId`,
    component: ValidationForm,
    title: NestedRoutTitle.Task
  }
};

const Routes: Record<RouteName, RouteConfig> = {
  [RouteName.SignIn]: {
    path: RouteName.SignIn,
    component: SignIn,
    title: RouteTitle.SignIn
  },
  [RouteName.AffiliationValidation]: {
    path: `${RouteName.AffiliationValidation}/:npi`,
    component: AffiliationValidation,
    title: RouteTitle.AffiliationValidation,
    protected: true,
    nested: {
      [NestedRouteName.Task]: NestedRoutes[NestedRouteName.Task]
    }
  },
  [RouteName.TaskSelection]: {
    path: RouteName.TaskSelection,
    component: TaskSelection,
    title: RouteTitle.TaskSelection,
    protected: true
  },
  [RouteName.OrgIdSearch]: {
    path: RouteName.OrgIdSearch,
    component: OrgIdSearch,
    title: RouteTitle.OrgIdSearch,
    protected: true
  },
  [RouteName.OrganizationValidationWithParams]: {
    path: `${RouteName.OrganizationValidationWithParams}`,
    component: OrganizationValidationPage,
    title: RouteTitle.OrganizationValidation,
    protected: true
  },
  [RouteName.OrganizationValidation]: {
    path: `${RouteName.OrganizationValidation}`,
    component: OrganizationValidationPage,
    title: RouteTitle.OrganizationValidation,
    protected: true
  },
  [RouteName.NPISearch]: {
    path: RouteName.NPISearch,
    component: NPISearch,
    title: RouteTitle.NPISearch,
    protected: true
  }
};

const RoutesList: Record<UnionRouteName, RouteConfig> = {
  ...Routes,
  ...NestedRoutes
};

const routesForMap = Object.values(Routes);

type BuiltElement = {
  path: string;
  nested?: BuiltElement[];
  element: ReactNode;
};

const buildElements = (routes: RouteConfig[]): BuiltElement[] =>
  routes.map(({ component, path, title, nested }) => {
    let nestedRoutes: BuiltElement[] = [];
    if (nested) {
      nestedRoutes = [...buildElements(Object.values(nested))];
    }

    const Element = component;

    return {
      path,
      nested: nestedRoutes.length ? nestedRoutes : [],
      element: <Element title={title} />
    };
  });

// Returns created routes and nested ones
export const recursiveBuildRoutes = (routes: BuiltElement[]) => {
  return routes.map(({ element, path, nested }) => (
    <Route key={path} element={element} path={path}>
      {nested ? recursiveBuildRoutes(nested) : null}
    </Route>
  ));
};

export const authenticatedRoutes = buildElements(
  routesForMap.filter((route) => route.protected)
);
export const unauthenticatedRoutes = buildElements(
  routesForMap.filter((route) => !route.protected)
);

export function generateRoutePath({
  name,
  params,
  isRelative
}: {
  // sometimes we need to generate absolute path from several route's name
  // Example: route/:routeParam/nestedRoute/:nestedRouteParam
  name: UnionRouteName | UnionRouteName[];
  params?: Params;
  isRelative?: boolean;
}): string {
  let result = null;
  if (isArray(name)) {
    const concatedPath = name
      .map((pathName) => {
        const { path } = RoutesList[pathName];
        return path;
      })
      .join("/");

    result = generatePath(concatedPath, params);
  } else {
    const { path } = RoutesList[name];
    result = generatePath(path, params);
  }

  return `${isRelative ? "" : "/"}${result}`;
}
