import { useState } from "react";
import { Link } from "react-router-dom";

import { TableCell, TableRow, Box } from "@material-ui/core";

import {
  FormikAcceptNewPatients,
  FormikAutocompleteSingle,
  ShortText,
  ToggleIcon
} from "Shared";
import { workStatusOptions } from "Shared/constants";

import {
  RouteName,
  generateRoutePath,
  NestedRouteName
} from "App/routes/routesHelpers";

import {
  CollapseForm,
  ManagedDirtyRow,
  VerifyButton,
  useHCPTableContext,
  useStyles
} from "./index";

import { RowProps } from "./Row";

type RowFormProps = Omit<RowProps, "initialValues">;

export const RowForm = ({
  completedBy,
  completedOn,
  id,
  npi,
  designation,
  department,
  hcpName,
  specialty,
  onHCPTaskSubmit
}: RowFormProps) => {
  const {
    rowWithoutPaddings,
    stickyLeftExpand,
    stickyLeftName,
    stickyRight,
    link
  } = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const { readonly } = useHCPTableContext();

  const completedDate = new Date(completedOn).toLocaleDateString("en-US");
  const hcpPath = generateRoutePath({
    name: [RouteName.AffiliationValidation, NestedRouteName.Task],
    params: { npi, taskId: id }
  });

  return (
    <>
      <ManagedDirtyRow id={id}>
        <TableCell className={stickyLeftExpand}>
          <ToggleIcon isOpen={isOpen} onToggle={() => setIsOpen(!isOpen)} />
        </TableCell>
        <TableCell className={stickyLeftName}>
          <Link className={link} to={hcpPath} target="_blank">
            {hcpName}
          </Link>
        </TableCell>
        <TableCell>{npi}</TableCell>
        <TableCell>{designation}</TableCell>
        <TableCell>{specialty}</TableCell>
        <TableCell>{department}</TableCell>
        <TableCell align="center">
          <FormikAcceptNewPatients disabled={readonly} />
        </TableCell>
        <TableCell>
          <Box minWidth={180}>
            <FormikAutocompleteSingle
              options={workStatusOptions}
              name="affiliationStatus"
              disabled={readonly}
            />
          </Box>
        </TableCell>
        {readonly ? (
          <>
            <TableCell>{completedDate}</TableCell>
            <TableCell>
              <ShortText width="80px" title={completedBy} />
            </TableCell>
          </>
        ) : (
          <TableCell className={stickyRight}>
            <VerifyButton
              taskId={id}
              hcpName={hcpName}
              onSuccessfulSubmit={onHCPTaskSubmit}
            />
          </TableCell>
        )}
      </ManagedDirtyRow>
      <TableRow>
        <TableCell className={rowWithoutPaddings} colSpan={12}>
          <CollapseForm isOpen={isOpen} readonly={readonly} />
        </TableCell>
      </TableRow>
    </>
  );
};
