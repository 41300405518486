import { memo, useCallback } from "react";

import { TAutocompleteOption, AutocompleteField, TAutocomplete } from "Shared";

import { FormikSingleAutocompleteProps } from "./";

type FormikSingleAutocompleteComponentProps = FormikSingleAutocompleteProps & {
  setFieldValue: (value: string) => void;
  value: string;
  onBlur: TAutocomplete["onBlur"];
};

export const FormikAutocompleteSingleComponent = memo(
  (props: FormikSingleAutocompleteComponentProps): JSX.Element => {
    const { value, options, setFieldValue } = props;

    const initialLabel =
      options.find((option) => option.value === value)?.label || value || "";

    const autocompleteValue: TAutocompleteOption = {
      value: value || "",
      label: initialLabel
    };

    const autocompleteOptions = [autocompleteValue, ...options];

    const handleChange = useCallback(
      (_: React.ChangeEvent<Record<string, unknown>>, options) => {
        const option = options as TAutocompleteOption;
        const value = option?.value;
        if (value) {
          setFieldValue(value);
        }
      },
      [setFieldValue]
    );

    const passedProps = {
      ...props,
      options: autocompleteOptions,
      autocompleteValue,
      handleChange
    };

    return <AutocompleteField {...passedProps} />;
  }
);
