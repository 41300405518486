import { useEffect } from "react";
import { Box, Button, makeStyles } from "@material-ui/core";
import { useFormikContext } from "formik";

import { TOrgVerificationInitialValues, ConfirmAlertPopup } from "Shared";
import { useOrganizationActions } from "OrganizationValidation/hooks/useOrganizationActions";
import { usePreventNavigationPopupContext } from "OrganizationValidation/context";

const useStyles = makeStyles(() => ({
  saveButtons: {
    display: "inline-flex",
    marginRight: 10,
    minWidth: "180px"
  }
}));

type SaveButtonProps = {
  taskId: string;
  completed: boolean;
  sessionId?: string;
};

export const OrganizationActionsContainer = ({
  completed,
  taskId,
  sessionId
}: SaveButtonProps) => {
  const { saveButtons } = useStyles();

  const { setIsOrgFormIsDirty } = usePreventNavigationPopupContext();
  const { isValid, values, initialValues, dirty } =
    useFormikContext<TOrgVerificationInitialValues>();

  const {
    isCompleteButtonEnabled,
    isSaveAndDeferEnabled,
    isUpdateButtonEnabled,
    isOpen,
    openModal,
    closeModal,
    onComplete,
    onSaveAndDefer,
    onUpdate
  } = useOrganizationActions({
    initialValues,
    updatedValues: values,
    isValid,
    verificationTaskId: taskId,
    sessionId
  });

  useEffect(() => {
    if (dirty) {
      setIsOrgFormIsDirty(true);
    }
  }, [dirty, setIsOrgFormIsDirty]);

  return (
    <Box>
      <ConfirmAlertPopup
        onCancel={closeModal}
        onConfirm={onComplete}
        isOpen={isOpen}
      />
      <Box>
        {completed ? (
          <Button
            className={saveButtons}
            onClick={onUpdate}
            variant="contained"
            type="submit"
            color="primary"
            disabled={!isUpdateButtonEnabled}
          >
            Update
          </Button>
        ) : (
          <>
            <Button
              className={saveButtons}
              onClick={onSaveAndDefer}
              variant="contained"
              type="submit"
              disabled={!isSaveAndDeferEnabled}
            >
              Save and Defer
            </Button>
            <Button
              className={saveButtons}
              onClick={openModal}
              variant="contained"
              type="submit"
              color="primary"
              disabled={!isCompleteButtonEnabled}
            >
              Complete
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};
