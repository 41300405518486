import { useCallback, useState } from "react";

type UseModalResponse = {
  isOpen: boolean;
  closeModal: () => void;
  openModal: () => void;
};

export const useModal = (): UseModalResponse => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const closeModal = useCallback(() => setIsOpen(false), []);
  const openModal = useCallback(() => setIsOpen(true), []);

  return {
    isOpen,
    closeModal,
    openModal
  };
};
