import { useState, useEffect, useRef, useContext } from "react";
import {
  UNSAFE_NavigationContext as NavigationContext,
  useNavigate
} from "react-router";

export const useNavigationBlocker = ({
  setIsOpen,
  shouldBlock
}: {
  setIsOpen: (value: boolean) => void;
  shouldBlock: boolean;
}) => {
  const navigate = useNavigate();
  const { navigator } = useContext(NavigationContext);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const unblockHandle = useRef<any>(null);

  const [nextPath, setNextPath] = useState<string>("");

  const onConfirm = () => {
    if (unblockHandle) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      unblockHandle.current();
    }
    navigate(nextPath);
  };

  useEffect(() => {
    if (shouldBlock) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
      unblockHandle.current = (navigator as any).block(
        ({ location }: { location: Location }) => {
          setNextPath(location.pathname);
          setIsOpen(true);
          return false;
        }
      );
    }
    return function () {
      // eslint-disable-next-line  @typescript-eslint/no-unsafe-call
      unblockHandle.current !== null && unblockHandle.current();
    };
  });

  return { unblockHandle, onConfirm };
};
