import { useState } from "react";
import { Box, Button, Grid } from "@material-ui/core";
import { Formik, Form } from "formik";

import {
  Modal,
  ModalAction,
  ModalContent,
  FormikTextField,
  FormikInputMask,
  TAddPhone,
  addPhoneInitialValues,
  addPhoneValidationSchema,
  transformNumberToConcatString,
  FormikAutocompleteSingle,
  phoneTypeOptions
} from "Shared";

export type AddPhoneNumberProps = {
  onAdd: (phone: TAddPhone) => void;
};

export const AddPhoneNumber = ({ onAdd }: AddPhoneNumberProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);

  const handleSubmit = (values: TAddPhone) => {
    const correctNumber = transformNumberToConcatString(values.number);
    onAdd({ ...values, number: correctNumber, isNotSaved: true });
    handleClose();
  };

  return (
    <>
      <Button onClick={() => setIsOpen(true)} color="primary">
        + Add phone number
      </Button>
      <Modal title="Add phone number" isOpen={isOpen} onClose={handleClose}>
        <Formik
          initialValues={addPhoneInitialValues}
          validationSchema={addPhoneValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ isValid }) => (
            <Form>
              <ModalContent>
                <Box width={"450px"}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <FormikInputMask
                        mask="999-999-9999"
                        name="number"
                        label="Phone Number"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormikTextField name="extension" label="Phone Ext." />
                    </Grid>
                    <Grid item xs={4}>
                      <FormikAutocompleteSingle
                        options={phoneTypeOptions}
                        label="Type"
                        name="type"
                        required
                      />
                    </Grid>
                  </Grid>
                </Box>
              </ModalContent>
              <ModalAction>
                <Button
                  variant="contained"
                  color="default"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!isValid}
                >
                  Submit
                </Button>
              </ModalAction>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
