import { makeStyles, Box, FormControl, Grid } from "@material-ui/core";
import { useCallback, useState } from "react";

import { transformCorrectPhone } from "AffiliationValidation/helpers";

import {
  useFormikField,
  Theme,
  RespondentDetails,
  DID_NOT_CALL,
  TFromPhoneNumber,
  TFromPhoneCallStatus
} from "Shared";

import { DeleteAddedPhone } from "./DeleteAddedPhone";
import { PhoneCheckboxes } from "./PhoneCheckboxes";

import type { ArrayHelpers } from "formik/dist/FieldArray";

const useStyles = makeStyles((theme: Theme) => ({
  sectionBorderColor: {
    borderColor: theme.palette.grey[100]
  }
}));

export type PhoneRowProps = {
  name: string;
  readonly?: boolean;
  canDelete?: boolean;
  isNotSaved?: boolean;
  remove?: ArrayHelpers["remove"];
  index: number;
};

export const PhoneRow = ({
  name,
  index,
  canDelete,
  readonly,
  remove,
  isNotSaved
}: PhoneRowProps): JSX.Element => {
  const { sectionBorderColor } = useStyles();
  const [isShow, setIsShow] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const { value, setFieldValue } = useFormikField<TFromPhoneNumber>(name);

  const toggleExpand = useCallback(() => {
    setIsShow(!isShow);
  }, [isShow, setIsShow]);

  const { callStatus, type, number } = value;

  const canBeDeleted = !!(isNotSaved && canDelete && !readonly);

  const onDelete = useCallback(
    (index: number) => {
      if (remove) {
        remove(index);
      }
    },
    [remove]
  );

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const eventValue = event.target.value as TFromPhoneCallStatus;

      const called = eventValue !== DID_NOT_CALL;

      setFieldValue({
        ...value,
        called,
        callStatus: eventValue
      });
    },
    [value, setFieldValue]
  );

  const correctFormatNumber = transformCorrectPhone(number);

  const onDeleteConfirm = useCallback(() => {
    onDelete(index);
    setIsAlertOpen(false);
  }, [setIsAlertOpen, index, onDelete]);

  const onCancelDelete = useCallback(() => {
    setIsAlertOpen(false);
  }, [setIsAlertOpen]);

  return (
    <Box
      display="flex"
      alignItems="center"
      marginY={2}
      padding={2}
      className={sectionBorderColor}
      borderRadius={6}
      border={1}
    >
      <Box display="flex" flexGrow={1}>
        <FormControl component="fieldset" fullWidth>
          <Grid container>
            <DeleteAddedPhone
              correctFormatNumber={correctFormatNumber}
              isAlertOpen={isAlertOpen}
              canBeDeleted={canBeDeleted}
              type={type}
              setIsAlertOpen={setIsAlertOpen}
              onDelete={onDelete}
              onCancel={onCancelDelete}
              onConfirm={onDeleteConfirm}
            />
            <PhoneCheckboxes onChange={onChange} callStatus={callStatus} />
          </Grid>
          <RespondentDetails
            name={name}
            isShow={isShow}
            toggleExpand={toggleExpand}
          />
        </FormControl>
      </Box>
    </Box>
  );
};
