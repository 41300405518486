import { TextFieldInput, useFormikField, TextFieldInputProps } from "Shared";

export const FormikTextField = (props: TextFieldInputProps): JSX.Element => {
  const { name } = props;
  const { value, onBlur, onChange, helperText, error } =
    useFormikField<string>(name);

  return (
    <TextFieldInput
      {...props}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      helperText={helperText}
      error={error}
    />
  );
};
