export enum AcceptingNewPatients {
  YES = "YES",
  NO = "NO",
  UNKNOWN = "UNKNOWN"
}

export enum SearchBy {
  ORG_ID = "orgId",
  ORG_NPI = "orgNPI"
}

export enum AlertButtonType {
  BLUE = "BLUE",
  WARNING = "WARNING"
}

export enum ESortDirection {
  ASC = "asc",
  DESC = "desc"
}
