import { useEffect } from "react";

import { useAuth } from "Auth";

import { useFeatureFlags } from "./useFeatureFlags";

export const FeatureFlagInitializer = () => {
  const { user } = useAuth();
  const { setUser } = useFeatureFlags();

  useEffect(() => {
    if (!user) return;
    void setUser(user);
  }, [user, setUser]);

  return null;
};
