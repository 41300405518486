import { Button as MIButton } from "@material-ui/core";

import type { ButtonProps as MIButtonProps } from "@material-ui/core";

export type ButtonProps = MIButtonProps;

export const Button: React.FC<MIButtonProps> = ({
  children,
  variant = "contained",
  ...rest
}) => {
  return (
    <MIButton variant={variant} color="primary" {...rest}>
      {children}
    </MIButton>
  );
};
