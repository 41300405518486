import * as Yup from "yup";

import { zipCodeValidation } from "Shared/utils/formikValidations/zipCodeValidation";

export const transformCorrectPhone = (phone?: string) => {
  if (phone) {
    const phoneNumbers = phone.replace(/[()-]/g, "");
    let correctPhone = "";

    for (let i = 0; i < phoneNumbers.length; i++) {
      if (i === 3 || i === 6) correctPhone += `-`;
      correctPhone += phoneNumbers[i];
    }

    return correctPhone;
  }

  return "N/A";
};

export const newAffiliationInitialValues = {
  name: "",
  phone: "",
  city: "",
  state: "",
  zip: ""
};

const cityStateValidations = Yup.string().when("zip", {
  is: (zip: string) => !!zip,
  then: Yup.string().test(
    "city/state OR zip code",
    "Enter city/state OR zip code",
    (value) => !value
  )
});

export const newAffiliationValidationSchema = Yup.object().shape({
  name: Yup.string().required("Organization Name is required"),
  phone: Yup.string(),
  city: cityStateValidations
    .when("zip", {
      is: (zip: string) => !zip,
      then: Yup.string().test(
        "city/state OR zip code",
        "Enter city/state OR zip code",
        (value) => !!value
      )
    })
    .when("state", {
      is: (state: string) => !state,
      then: Yup.string().test(
        `state is necessary`,
        `Enter State`,
        (value) => !value
      )
    }),
  state: cityStateValidations,
  zip: zipCodeValidation
});
