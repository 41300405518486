import { createContext, useContext, useMemo, useState } from "react";

import { TFromPhoneNumber } from "Shared";

export const VerifyCallContext = createContext<{
  isVerifyCallSelected: boolean;
  setIsVerifyCallSelected: (isVerifyCallSelected: boolean) => void;
  phoneNumbers: TFromPhoneNumber[];
  setPhoneNumbers: (phoneNumbers: TFromPhoneNumber[]) => void;
} | null>(null);

// we need this context to track if agent select valid call status
export const VerifyCallContextProvider: React.FC = ({ children }) => {
  const [isVerifyCallSelected, setIsVerifyCallSelected] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState<TFromPhoneNumber[]>([]);

  const contextValues = useMemo(
    () => ({
      isVerifyCallSelected,
      setIsVerifyCallSelected,
      phoneNumbers,
      setPhoneNumbers
    }),
    [
      isVerifyCallSelected,
      setIsVerifyCallSelected,
      phoneNumbers,
      setPhoneNumbers
    ]
  );

  return (
    <VerifyCallContext.Provider value={contextValues}>
      {children}
    </VerifyCallContext.Provider>
  );
};

export const useVerifyCallContext = () => {
  const context = useContext(VerifyCallContext);

  if (!context) {
    throw new Error("Use VerifyCallContext inside VerifyCallContextProvider");
  }

  return context;
};
