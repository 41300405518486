import {
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  Box,
  Typography
} from "@material-ui/core";
import { memo, useCallback } from "react";

import { TAutocompleteOption } from "Shared";

export type RadioGroupProps = {
  options: TAutocompleteOption[];
  label?: React.ReactNode;
  name: string;
  disabled?: boolean;
  setFieldValue: (value: string) => void;
  value: string;
};

export const RadioGroupField = memo(
  ({
    options,
    label,
    setFieldValue,
    value,
    disabled = false
  }: RadioGroupProps): JSX.Element => {
    const onChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(e.target.value);
      },
      [setFieldValue]
    );

    return (
      <FormControl component="fieldset">
        <Box display="flex" alignItems="center">
          {label && <Typography style={{ width: 120 }}>{label}</Typography>}
          <RadioGroup row onChange={onChange} value={value}>
            <Box display="flex">
              {options.map(({ label, value }) => (
                <FormControlLabel
                  disabled={disabled}
                  key={label}
                  value={value}
                  control={<Radio color="primary" />}
                  label={<Typography variant="caption">{label}</Typography>}
                  labelPlacement="top"
                />
              ))}
            </Box>
          </RadioGroup>
        </Box>
      </FormControl>
    );
  }
);
