import { Box, Container, Typography } from "@material-ui/core";

export type SectionContainerProps = React.FC<{
  label: string;
}>;

export const SectionContainer: SectionContainerProps = ({
  label,
  children
}) => {
  return (
    <Box paddingY={2} paddingBottom={20}>
      <Container>
        <Typography variant="h6">{label}</Typography>
        <Box mt={3}>{children}</Box>
      </Container>
    </Box>
  );
};
