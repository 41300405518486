import * as React from "react";
import { MuiThemeProvider, CssBaseline } from "@material-ui/core";

import { theme } from "./theme";

export type ThemeProviderProps = React.FC<{ noCssBaseline?: boolean }>;

export const ThemeProvider: ThemeProviderProps = ({
  children,
  noCssBaseline = false
}) => (
  <MuiThemeProvider theme={theme}>
    {!noCssBaseline && <CssBaseline />}
    <>{children}</>
  </MuiThemeProvider>
);
