import { useCallback } from "react";

import { useResponseHandler, useSnackBarMessage } from "Shared";
import {
  CallTaskSessionFragment,
  GetVerificationTaskDocument,
  useUpdateAffiliationTaskSessionMutation,
  UpdateAffiliationTaskSessionMutationVariables,
  UpdateAffiliationTaskSessionMutationOptions
} from "Shared/graphql/generated";

const successMessage = "Verification successfully updated";

export const useUpdateAffiliationTaskSession = (
  options?: UpdateAffiliationTaskSessionMutationOptions
) => {
  const { handleResponse } = useResponseHandler();
  const { showSuccess } = useSnackBarMessage();

  const [mutation, { loading }] = useUpdateAffiliationTaskSessionMutation({
    fetchPolicy: "no-cache",
    refetchQueries: [GetVerificationTaskDocument],
    ...options
  });

  const updateAffiliationTask = useCallback(
    async (
      variables: UpdateAffiliationTaskSessionMutationVariables
    ): Promise<CallTaskSessionFragment | undefined | null> => {
      const res = await mutation({
        variables
      });
      const result = res.data?.updateAffiliationTaskSession;
      if (result) {
        const { response, isSuccess } = handleResponse(result);
        if (isSuccess) {
          showSuccess(successMessage);
          return response.callSession;
        }
      }
    },
    [mutation, handleResponse, showSuccess]
  );

  return {
    updateAffiliationTask,
    isLoading: loading
  };
};
