import { createStyles, makeStyles } from "@material-ui/core";
import React from "react";

type FieldsetProps = React.HTMLAttributes<HTMLFieldSetElement>;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      border: "none",
      margin: 0,
      padding: 0
    }
  })
);

export const Fieldset = (props: FieldsetProps) => {
  const classes = useStyles();

  return <fieldset {...props} className={classes.root} />;
};
