import { Box } from "@material-ui/core";

import { FormikAddField } from "Shared";

export const AddOrgNpiAndTaxId = () => {
  return (
    <>
      <Box paddingBottom="10px">
        <FormikAddField fieldLabel="Org NPI" name="orgNpi" />
      </Box>
      <FormikAddField fieldLabel="Tax ID" name="orgTaxId" />
    </>
  );
};
