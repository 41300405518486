import { Divider, Grid } from "@material-ui/core";

import { VerifyPhoneContacts } from "Shared/components/PhoneRow";

import { ConfirmPersonWork, VerifyWorkOrganization } from "./index";

type VerifyWorkProps = {
  completed?: boolean;
};

export const VerifyWork = ({ completed }: VerifyWorkProps) => {
  return (
    <>
      <VerifyPhoneContacts readonly={completed} canDelete sectionBorder />
      <Grid container>
        <Grid item xs={6}>
          <VerifyWorkOrganization />
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          style={{ marginRight: "-1px" }}
        />
        <Grid item xs={6}>
          <ConfirmPersonWork />
        </Grid>
      </Grid>
    </>
  );
};
