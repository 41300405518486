import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from "@material-ui/core";
import { ChangeEvent, memo } from "react";

import { DID_NOT_CALL, TFromPhoneCallStatus } from "Shared";

import { PHONE_ROW_CHECKBOXES } from "./helpers";

export type PhoneCheckboxesProps = {
  callStatus: TFromPhoneCallStatus;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const PhoneCheckboxes = memo(
  ({ callStatus, onChange }: PhoneCheckboxesProps) => {
    return (
      <Grid item xs={9}>
        <RadioGroup
          value={callStatus}
          onChange={onChange}
          defaultValue={DID_NOT_CALL}
        >
          <Box display="flex" alignItems="center" paddingBottom={2}>
            {PHONE_ROW_CHECKBOXES.map(({ label, value }) => (
              <FormControlLabel
                key={label}
                value={value}
                control={<Radio color="primary" />}
                label={<Typography variant="caption">{label}</Typography>}
                labelPlacement="top"
              />
            ))}
          </Box>
        </RadioGroup>
      </Grid>
    );
  }
);
