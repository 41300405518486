import { useCallback } from "react";

import { useResponseHandler } from "Shared";
import { useStartVerificationMutation } from "Shared/graphql/generated";

import { TNpiValues } from "../npiHelpers";

export const useStartVerification = () => {
  const { handleResponse } = useResponseHandler();

  const [startVerificationMutation, { loading, data }] =
    useStartVerificationMutation();

  const startVerification = useCallback(
    async (variables: TNpiValues) => {
      const res = await startVerificationMutation({ variables });
      const startVerification = res.data?.startVerification;

      if (startVerification) {
        const { isSuccess } = handleResponse(startVerification);

        return {
          isSuccess,
          tasks: startVerification.verification?.tasks ?? []
        };
      }
    },
    [startVerificationMutation, handleResponse]
  );

  return {
    isLoading: loading,
    startVerification,
    data
  };
};
