import * as Yup from "yup";

import { transformNumberToConcatString } from "../dataTransformation";

import { ValidationType } from "./type";

export const phoneNumberValidation = ({ type, field }: ValidationType) =>
  Yup.string().test(type, `${field} must be 10 digits`, (value) => {
    if (value) {
      const number = transformNumberToConcatString(value);
      const isMaxLength = number.length > 9;

      return isMaxLength || number.length === 0;
    }
    return true;
  });
