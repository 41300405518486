import {
  FormControlLabel,
  makeStyles,
  Paper,
  Radio,
  Typography
} from "@material-ui/core";
import { ReactNode } from "react";

import { Theme } from "Shared";

const useStyles = makeStyles(({ palette }: Theme) => ({
  card: {
    margin: "0 15px",
    padding: "20px 64px",
    display: "flex",
    height: "150px",
    width: "300px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    "&:hover": {
      backgroundColor: palette.primary.ultraLight
    }
  },
  active: {
    backgroundColor: palette.primary.ultraLight
  },
  caption: {
    display: "flex"
  }
}));

type CardsWithLogoProps = {
  icon: ReactNode;
  isActive: boolean;
  label: string;
  onCLick: () => void;
};

export const CardWithLogo = ({
  label,
  isActive,
  icon,
  onCLick
}: CardsWithLogoProps) => {
  const { card, active, caption } = useStyles();

  return (
    <Paper
      className={`${card} ${isActive ? active : ""}`}
      variant="outlined"
      onClick={onCLick}
    >
      {icon}
      <FormControlLabel
        key={label}
        control={<Radio checked={isActive} color="primary" />}
        label={
          <Typography variant="caption" className={caption}>
            {label}
          </Typography>
        }
        labelPlacement="end"
      />
    </Paper>
  );
};
