import { Box } from "@material-ui/core";
import { ChangeEvent, useCallback } from "react";

import { useFormikField } from "../hooks";

import { TimePicker } from "./TimePicker";
import { DatesRange } from "./DatesRange";
import { DateRangeKeys, DateRangeValues } from "./helpers";

export type FormikDateRangeProps = {
  name: string;
  readonly?: boolean;
};

export const FormikDateRange = ({ name, readonly }: FormikDateRangeProps) => {
  const { value, setFieldValue } = useFormikField<DateRangeValues>(name);

  const onDayBoxClick = useCallback(
    (index: number) => {
      const days = [...value.days];
      days[index] = !days[index];
      const newValue = { ...value, [DateRangeKeys.DAYS]: days };
      setFieldValue(newValue);
    },
    [setFieldValue, value]
  );

  const onChange = useCallback(
    (fieldValue: string | number, key: DateRangeKeys) => {
      const newValue = { ...value, [key]: fieldValue };
      setFieldValue(newValue);
    },
    [setFieldValue, value]
  );

  const handleOpenTime = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value, DateRangeKeys.OPEN);
    },
    [onChange]
  );

  const handleCloseTime = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value, DateRangeKeys.CLOSE);
    },
    [onChange]
  );

  return (
    <Box display="inline-flex" alignItems="center" paddingY={2} flexGrow={1}>
      <Box display="flex" alignItems="center" flexGrow={1}>
        <DatesRange
          values={value.days}
          readonly={readonly}
          onClick={onDayBoxClick}
        />
        <TimePicker
          id="openTime"
          label="Open"
          value={value.open}
          onChange={handleOpenTime}
        />
        <Box height="0" borderBottom="1px solid black" minWidth="11px" />
        <TimePicker
          id="closeTime"
          label="Close"
          value={value.close}
          onChange={handleCloseTime}
        />
      </Box>
    </Box>
  );
};
