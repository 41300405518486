import InputMask from "react-input-mask";

import { TextFieldInput, TextFieldInputProps, useFormikField } from "Shared";

export type FormikInputMaskProps = {
  mask: string;
  name: string;
  shrink?: boolean;
} & TextFieldInputProps;

export const FormikInputMask = ({
  mask,
  name,
  shrink = true,
  ...rest
}: FormikInputMaskProps): JSX.Element => {
  const { value, onBlur, onChange, helperText, error } =
    useFormikField<string>(name);

  return (
    <InputMask mask={mask} onBlur={onBlur} onChange={onChange} value={value}>
      {() => (
        <TextFieldInput
          onBlur={onBlur}
          onChange={onChange}
          name={name}
          shrink={shrink}
          helperText={helperText}
          error={error}
          value={value}
          {...rest}
        />
      )}
    </InputMask>
  );
};
