import { useCallback } from "react";

import { useSnackbarContext } from "Shared";

export const useSnackBarMessage = (): {
  showError: (message?: string) => void;
  showSuccess: (message?: string) => void;
} => {
  const { setSnackbar } = useSnackbarContext();

  const showError = useCallback(
    (message = "Something Went Wrong. Try Again") => {
      setSnackbar({ message, type: "error" });
    },
    [setSnackbar]
  );

  const showSuccess = useCallback(
    (message = "Success!") => {
      setSnackbar({ message, type: "success" });
    },
    [setSnackbar]
  );

  return {
    showError,
    showSuccess
  };
};
