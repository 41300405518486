import { makeStyles, TextField } from "@material-ui/core";
import { ChangeEvent, memo } from "react";

import type { TextFieldProps } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  timeSelector: {
    margin: "0 10px",
    display: "inline-flex",
    flex: 3,
    "& input": {
      fontSize: "11px",
      padding: "10.5px 6px"
    }
  }
}));

export type TimePickerProps = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
} & TextFieldProps;

export const TimePicker = memo(
  ({ id, label, value, onChange }: TimePickerProps) => {
    const { timeSelector } = useStyles();
    return (
      <TextField
        id={id}
        type="time"
        label={label}
        variant="outlined"
        value={value}
        className={timeSelector}
        onChange={onChange}
        InputLabelProps={{
          shrink: true
        }}
      />
    );
  }
);
