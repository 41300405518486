import {
  FormikMultipleAutocompleteProps,
  FormikAutocompleteMultiple
} from "Shared";
import { useAllowedLanguagesQuery } from "Shared/graphql/generated";

export const AddLanguagesSpoken = ({
  disabled,
  ...rest
}: Omit<
  FormikMultipleAutocompleteProps,
  "label" | "name" | "options" | "allowMultiple" | "shrink"
>) => {
  const { loading, data } = useAllowedLanguagesQuery();

  return (
    <>
      <FormikAutocompleteMultiple
        {...rest}
        label="Language(s) Spoken"
        name="spokenLanguages"
        options={data?.allowedLanguages || []}
        disabled={loading || disabled}
        shrink
      />
    </>
  );
};
