import { useEffect } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { useFormikContext } from "formik";

import { useNewAffiliationContext } from "AffiliationValidation/context";

const useStyles = makeStyles(() => ({
  saveButtons: {
    display: "inline-flex",
    marginRight: 10,
    minWidth: "100px"
  }
}));

export type TopSectionActionProps = {
  readonly: boolean;
  completed: boolean;
  isLoading: boolean;
  isSubmitDisabled: boolean;
  isSaveAndDeferDisabled: boolean;
  onSaveAndDefer: () => void;
  onSubmit?: () => void;
};

export const TopSectionAction: React.FC<TopSectionActionProps> = ({
  readonly,
  isSubmitDisabled,
  isSaveAndDeferDisabled,
  completed,
  onSaveAndDefer,
  onSubmit
}) => {
  const { saveButtons } = useStyles();
  const { setIsDirty } = useNewAffiliationContext();
  const { dirty } = useFormikContext();

  useEffect(() => {
    if (readonly) {
      setIsDirty(false);
    } else {
      setIsDirty(dirty);
    }
  }, [dirty, setIsDirty, readonly]);

  const saveAndDeferMessage = "Save And Defer";
  const submitMessage = completed ? "Update" : "Complete";

  return (
    <>
      {!completed && (
        <Button
          className={saveButtons}
          onClick={onSaveAndDefer}
          variant="contained"
          type="submit"
          disabled={isSaveAndDeferDisabled}
        >
          {saveAndDeferMessage}
        </Button>
      )}
      <Button
        className={saveButtons}
        disabled={isSubmitDisabled}
        variant="contained"
        type="submit"
        color="primary"
        onClick={onSubmit}
      >
        {submitMessage}
      </Button>
    </>
  );
};
