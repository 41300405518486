import { Outlet } from "react-router-dom";
import { Box } from "@material-ui/core";

import { ProtectedRoute } from "App/routes";

import { Header } from "./components";

export const AuthLayout = () => (
  <ProtectedRoute>
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Header />
      <Box display="flex" flexGrow={1} component="main" flexDirection="column">
        <Outlet />
      </Box>
    </Box>
  </ProtectedRoute>
);
