import { ServerResponse } from "http";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  HttpLink
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import Cookies from "js-cookie";

import { CookiesValues, useSnackBarMessage } from "Shared";

const uri = process.env.REACT_APP_NOVA_BPO_URI;
const UNAUTHORIZED_MESSAGE = "Unauthorized";

const novaLink = new HttpLink({
  uri,
  credentials: "include"
});

const cache = new InMemoryCache();

export const ApolloClientProvider: React.FC = ({ children }) => {
  const { showError } = useSnackBarMessage();

  const errorHandler = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, extensions }) => {
        if (
          message === UNAUTHORIZED_MESSAGE ||
          (extensions as { response: ServerResponse })?.response?.statusCode ===
            401
        ) {
          const {
            signedIn: { key }
          } = CookiesValues;

          Cookies.remove(key);

          showError("session expired, please log in again");
          setTimeout(() => window.location.reload(), 800);
        } else {
          showError(`[GraphQL Error]: ${message}`);
        }
      });
    }

    if (networkError) {
      showError(`[Network error]: ${networkError.message}`);
    }
  });

  const client = new ApolloClient({
    link: ApolloLink.from([errorHandler, novaLink]),
    cache
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
