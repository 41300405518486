import { Form, Formik } from "formik";
import { Box } from "@material-ui/core";
import { useNavigate } from "react-router";

import {
  GenericSearchComponent,
  FormikTextField,
  FormikAutocompleteSingle,
  SearchBy,
  ButtonOnNext
} from "Shared";
import { searchByOptions } from "Shared/constants";
import {
  useFindOrganizationTaskLazyQuery,
  FindOrganizationTaskQuery
} from "Shared/graphql/generated";

import { generateRoutePath, RouteName } from "App";

import {
  orgIdInitialValues,
  orgIdValidationSchema,
  TOrgIdValues
} from "./orgIdHelpers";

const label =
  "Copy and paste the Organization ID corresponding to a task assigned to you.";

export const OrgIdSearch = () => {
  const navigate = useNavigate();

  const navigateToOrg = (data: FindOrganizationTaskQuery) => {
    const orgId = data.findOrganizationTask?.orgMasterId;

    if (orgId) {
      navigate(
        generateRoutePath({
          name: RouteName.OrganizationValidationWithParams,
          params: { orgId }
        })
      );
    }
  };

  const [getOrgTasks] = useFindOrganizationTaskLazyQuery({
    onCompleted: navigateToOrg
  });

  const onSubmit = async ({ id }: TOrgIdValues) => {
    await getOrgTasks({ variables: { orgId: id } });
  };

  return (
    <GenericSearchComponent label={label}>
      <Formik
        onSubmit={onSubmit}
        initialValues={orgIdInitialValues}
        validationSchema={orgIdValidationSchema}
      >
        {({ values, isValid }) => (
          <Form>
            <Box display="flex" alignItems="center" flexDirection="column">
              <Box display="flex" alignItems="left" my={4}>
                <FormikAutocompleteSingle
                  label="Search by"
                  name="searchBy"
                  options={searchByOptions}
                  style={{ width: 150 }}
                  disableClearable
                />
                <FormikTextField
                  name="id"
                  label={values.searchBy === SearchBy.ORG_ID ? "ID" : "NPI"}
                  style={{ marginLeft: 15 }}
                />
              </Box>
              <Box width={130}>
                <ButtonOnNext disabled={!isValid} />
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </GenericSearchComponent>
  );
};
