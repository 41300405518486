import { Box, Paper, Typography } from "@material-ui/core";

type TGenericSearchComponentProps = React.FC<{
  label: string;
}>;

export const GenericSearchComponent: TGenericSearchComponentProps = ({
  label,
  children
}) => (
  <Box display="flex" alignItems="center" justifyContent="center" flexGrow={1}>
    <Paper>
      <Box paddingX={10} paddingY={8} width={700}>
        <Typography variant="h4" style={{ fontWeight: 400 }}>
          {label}
        </Typography>
        {children}
      </Box>
    </Paper>
  </Box>
);
