import { TCookies } from "Shared/types";
import { TAutocompleteOption } from "Shared";
import { AffiliationStatus, PhoneNumberType } from "Shared/graphql/generated";

import { SearchBy, AcceptingNewPatients } from "../types";

export const searchByOptions = [
  { value: SearchBy.ORG_ID, label: "Org ID" },
  { value: SearchBy.ORG_NPI, label: "Org NPI" }
];

export const acceptPatientsOptions: TAutocompleteOption[] = [
  { label: "Yes", value: AcceptingNewPatients.YES },
  { label: "No", value: AcceptingNewPatients.NO },
  {
    label: "Unknown",
    value: AcceptingNewPatients.UNKNOWN
  }
];

export const workStatusOptions: TAutocompleteOption[] = [
  { label: "Active", value: AffiliationStatus.Active },
  { label: "Not Active - Prior Work", value: AffiliationStatus.PriorWork },
  {
    label: "Not Active - Not in directory ",
    value: AffiliationStatus.NeverAffiliated
  },
  { label: "Unable to verify", value: AffiliationStatus.Unknown }
];

export const phoneTypeOptions = [
  { label: "Organization", value: PhoneNumberType.Organization },
  { label: "Direct", value: PhoneNumberType.Direct },
  { label: "Personal", value: PhoneNumberType.Personal },
  { label: "Unknown", value: PhoneNumberType.Unknown }
];

export const CookiesValues: Record<
  keyof TCookies,
  Record<"key" | "value", string>
> = {
  signedIn: {
    key: "signed",
    value: "true"
  }
};

// this is a list of default keys that we also should send on the server
// even if a user didn't change the value
export const defaultFormFilledParams = ["isAcceptNewPatients"];

// this is a list of form fields that we should not send on the server (affiliation)
export const listExcludedFormFields = [
  "affiliationVerificationUrl",
  "affiliationVerificationUrlDetail"
];
