import * as Yup from "yup";

import { TFromPhoneCallStatus, DID_NOT_CALL } from "Shared";
import { TaskPhoneNumber } from "Shared/graphql/generated";

export const phoneNumbersValidation = Yup.array().test(
  "phone numbers",
  "at least 1 is called",
  (value = []) => {
    const isAllDidNotCalled = value?.every(
      (call: TaskPhoneNumber) =>
        (call.callStatus as TFromPhoneCallStatus) === DID_NOT_CALL
    );
    return !isAllDidNotCalled;
  }
);
