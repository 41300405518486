import { Box } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { Formik, Form } from "formik";

import { GenericContainer, PopupOnLeave } from "Shared";

import { useGetVerificationTask } from "../hooks";
import { affiliationFromValidation } from "../helpers";
import { useNewAffiliationContext } from "../context";

import { TopSection, VerifyWork } from "./subSections";

export const ValidationForm = () => {
  const { isDirty } = useNewAffiliationContext();

  const { taskId = "" } = useParams();

  const {
    isLoading,
    validationFormInitialValues,
    name,
    address,
    completed,
    completedBy,
    lastSession,
    orgType,
    updated
  } = useGetVerificationTask({
    id: taskId
  });

  return (
    <GenericContainer
      isLoading={isLoading}
      data={validationFormInitialValues}
      noDataMessage="No data"
    >
      {() => (
        <Formik
          initialValues={validationFormInitialValues}
          onSubmit={() => {}}
          validationSchema={affiliationFromValidation}
          validateOnMount
          enableReinitialize={true}
        >
          <Form>
            <PopupOnLeave dirty={isDirty} />
            <Box flexGrow={1} display="flex" flexDirection="column">
              <TopSection
                completed={completed || false}
                address={address}
                name={name}
                completedBy={completedBy}
                readonly={completed}
                orgType={orgType}
                taskId={taskId}
                sessionId={lastSession?.id}
                lastSession={lastSession}
                updated={updated}
              />
              <VerifyWork completed={completed} />
            </Box>
          </Form>
        </Formik>
      )}
    </GenericContainer>
  );
};
