import { useField } from "formik";
import { useRef, useEffect, useState } from "react";

export const useFormikField = <T,>(name: string) => {
  const [{ value, onChange, onBlur }, { touched, error }, { setValue }] =
    useField<T>(name);

  // We use this approach because setValue of useField have unstable ref
  // and cannot be passed as prop at memoized components
  const [fieldValue, setFieldValue] = useState(value);
  const currentValueRef = useRef(value);
  useEffect(() => {
    if (fieldValue !== currentValueRef.current) {
      setValue(fieldValue);
      currentValueRef.current = fieldValue;
    }
  }, [fieldValue, setValue]);

  const passedProps = {
    value,
    error: touched && !!error,
    helperText: touched && error,
    setFieldValue,
    onChange,
    onBlur
  };

  return passedProps;
};
