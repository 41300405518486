import { Box, CircularProgress } from "@material-ui/core";
import { Form, Formik } from "formik";

import {
  FormikTextField,
  useSetDocumentTitle,
  GenericSearchComponent,
  ButtonOnNext,
  PageProps
} from "Shared";

import { usePathTaskHandler } from "../Shared/hooks/usePathTaskHandler";

import {
  npiInitialValues,
  npiValidationSchema,
  TNpiValues
} from "./npiHelpers";
import { useStartVerification } from "./hooks";

const label = "Copy and paste the NPI corresponding to a task assigned to you.";

export const NPISearch = ({ title }: PageProps) => {
  const { isLoading, startVerification } = useStartVerification();
  useSetDocumentTitle(title);
  const { pathTaskHandler } = usePathTaskHandler();

  const onSubmit = async ({ npi }: TNpiValues) => {
    const verificationData = await startVerification({ npi });

    const isSuccess = verificationData?.isSuccess;

    if (isSuccess) {
      const { tasks } = verificationData;
      /**
       * NOTE: after loading tasks  we need to select first one
       * to create specific path for navigation
       */
      pathTaskHandler(npi, tasks);
    }
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        flexGrow={1}
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <GenericSearchComponent label={label}>
      <Formik
        onSubmit={onSubmit}
        initialValues={npiInitialValues}
        validationSchema={npiValidationSchema}
      >
        {({ isValid }) => (
          <Form>
            <Box display="flex" alignItems="center" flexDirection="column">
              <Box width={200} my={4}>
                <FormikTextField name="npi" label="Person NPI" />
              </Box>
              <ButtonOnNext disabled={!isValid || isLoading} />
            </Box>
          </Form>
        )}
      </Formik>
    </GenericSearchComponent>
  );
};
