import { Box } from "@material-ui/core";

import { StatusInfo } from "Shared";
import {
  CallTaskSessionFragment,
  UserFragment
} from "Shared/graphql/generated";

import { TopSectionAction } from "./TopSectionAction";

type TopSectionSubmitProps = {
  readonly: boolean;
  completed: boolean;
  isLoading: boolean;
  isSubmitDisabled: boolean;
  isSaveAndDeferDisabled: boolean;
  onSaveAndDefer: () => void;
  onSubmit?: () => void;
  completedBy?: UserFragment | null;
  lastSession?: CallTaskSessionFragment | null;
  updated?: string;
};

export const TopSectionSubmit = ({
  readonly,
  isLoading,
  isSaveAndDeferDisabled,
  completed,
  isSubmitDisabled,
  onSubmit,
  onSaveAndDefer,
  lastSession,
  completedBy,
  updated
}: TopSectionSubmitProps) => {
  const lastUpdate = lastSession?.lastUpdate;

  return (
    <Box>
      <Box marginBottom={2}>
        <TopSectionAction
          isLoading={isLoading}
          isSubmitDisabled={isSubmitDisabled}
          isSaveAndDeferDisabled={isSaveAndDeferDisabled}
          onSaveAndDefer={onSaveAndDefer}
          onSubmit={onSubmit}
          completed={completed}
          readonly={readonly}
        />
      </Box>
      {lastUpdate && (
        <StatusInfo
          lastUpdatedBy={lastUpdate.createdBy?.email}
          lastUpdatedOn={lastUpdate.created}
          completedBy={completedBy?.email}
          completedOn={updated}
        />
      )}
    </Box>
  );
};
