import { TableRow, TableCell, TableHead } from "@material-ui/core";

import { useHCPTableContext, useStyles } from "./index";

export const Header = () => {
  const { readonly } = useHCPTableContext();
  const { stickyLeftExpand, stickyLeftName } = useStyles();

  return (
    <TableHead>
      <TableRow>
        <TableCell className={stickyLeftExpand} />
        <TableCell className={stickyLeftName}>HCP Name</TableCell>
        <TableCell>Person NPI</TableCell>
        <TableCell>Designation(s)</TableCell>
        <TableCell>Speciality</TableCell>
        <TableCell>Department</TableCell>
        <TableCell align="center">Accepting New Patients (Yes/No)</TableCell>
        <TableCell>Work Status</TableCell>
        {readonly ? (
          <>
            <TableCell>Completed on</TableCell>
            <TableCell>Completed by</TableCell>
          </>
        ) : (
          <TableCell />
        )}
      </TableRow>
    </TableHead>
  );
};
