import {
  TFromPhoneNumber,
  THCPTableRow,
  TPagination,
  transformAffiliationTasksToRows,
  transformPhoneNumbersToInitialValues
} from "Shared";

import { usePagination } from "OrganizationValidation/hooks";
import {
  PhoneNumberType,
  useOrgAffiliationTasksQuery
} from "Shared/graphql/generated";

export type HCPTaskData = {
  rows: THCPTableRow[];
  count: number;
  isLoading: boolean;
  initialPhoneNumbers: TFromPhoneNumber[];
  pagination: TPagination;
};

export const useGetHCPTaskData = ({
  orgId,
  completed
}: {
  orgId: string;
  completed: boolean;
}): HCPTaskData => {
  const pagination = usePagination();

  const { loading, data } = useOrgAffiliationTasksQuery({
    variables: {
      filters: { orgId, completed },
      page: {
        limit: pagination.limit,
        offset: pagination.limit * pagination.page
      }
    },
    fetchPolicy: "no-cache"
  });

  const rows = transformAffiliationTasksToRows(
    data?.orgAffiliationTasks.items || []
  );
  const count = data?.orgAffiliationTasks.count || 0;

  // we only need the initial phoneNumbers as values, so we are going to get that from the first index
  // and we need to set default status to DID_NOT_CALL
  const orgPhoneNumbers =
    data?.orgAffiliationTasks.items[0]?.phoneNumbers?.filter(
      ({ type }) => type === PhoneNumberType.Organization
    ) || [];
  const initialPhoneNumbers =
    transformPhoneNumbersToInitialValues(orgPhoneNumbers);

  return {
    rows,
    count,
    isLoading: loading,
    initialPhoneNumbers,
    pagination
  };
};
