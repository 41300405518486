import { useState, useEffect } from "react";

import { useStartVerification } from "NPISearch/hooks";
import { VerificationTaskFragment } from "Shared/graphql/generated";

export const useGetVerifications = (npi?: string) => {
  const { isLoading, startVerification, data } = useStartVerification();
  const { person, tasks } = data?.startVerification.verification || {};

  const [selectedTaskId, setSelectedTaskId] = useState<string | null>(null);
  // TODO: temp solution for update task status after complete
  // we use mutation to get tasks so we can't update cache and display it on UI
  const [taskState, setTaskState] = useState<VerificationTaskFragment[]>([]);

  useEffect(() => {
    if (tasks) setTaskState(tasks);
  }, [tasks]);

  useEffect(() => {
    if (npi) {
      const getVerification = async () => {
        await startVerification({ npi });
      };

      void getVerification();
    }
  }, [npi, startVerification]);

  return {
    isLoading,
    person,
    tasks: taskState,
    updateTasks: setTaskState,
    selectedTaskId,
    setSelectedTaskId
  };
};
