import { memo } from "react";
import { makeStyles, Box, Grid, Typography, Collapse } from "@material-ui/core";

import { FormikTextField } from "Shared";

import { ToggleIcon } from "../common/ToggleIcon";

type RespondentDetailsProps = {
  name: string;
  isShow: boolean;
  toggleExpand: () => void;
};

const useStyles = makeStyles(() => ({
  sectionNameAlight: {
    paddingTop: "12px"
  }
}));

export const RespondentDetails = memo(
  ({ name, isShow, toggleExpand }: RespondentDetailsProps) => {
    const { sectionNameAlight } = useStyles();

    return (
      <>
        <Grid container className={sectionNameAlight}>
          <Box sx={{ display: "flex" }}>
            <Typography variant="h6" display="inline">
              Respondent Details:
            </Typography>
            <Box ml={2}>
              <ToggleIcon isOpen={isShow} onToggle={() => toggleExpand()} />
            </Box>
          </Box>
        </Grid>
        <Collapse in={isShow}>
          <Grid container className={sectionNameAlight}>
            <Grid item>
              <Box>
                <Grid spacing={2} container>
                  <Grid item xs={6}>
                    <FormikTextField
                      name={`${name}.respondentFirstName`}
                      label="First Name"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikTextField
                      name={`${name}.respondentLastName`}
                      label="Last Name"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikTextField
                      name={`${name}.respondentDepartment`}
                      label="Department"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikTextField
                      name={`${name}.respondentTitle`}
                      label="Department Title"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Collapse>
      </>
    );
  }
);
