import { useState } from "react";
import { Button, Typography, Box, makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useField } from "formik";

import { FormikTextField } from "./FormikTextField";

const useStyles = makeStyles({
  boldText: {
    fontWeight: 600
  }
});

export type FormikAddFieldProps = {
  fieldLabel: string;
  btnLabel?: string;
  name: string;
  disabled?: boolean;
};

export const FormikAddField = ({
  fieldLabel,
  btnLabel,
  name,
  disabled
}: FormikAddFieldProps) => {
  const { boldText } = useStyles();
  const [, , helpers] = useField(name);

  const [hasField, setHasField] = useState(false);

  const onRemoveFiled = () => {
    // we need to remove value if a user decides to remove the field
    helpers.setValue("");
    setHasField(false);
  };

  return (
    <>
      <Box>
        {!hasField && !disabled ? (
          <Button
            color="primary"
            variant="text"
            onClick={() => setHasField(true)}
          >
            <Typography variant="caption" color="primary" className={boldText}>
              {btnLabel || `+ Add ${fieldLabel}`}
            </Typography>
          </Button>
        ) : (
          <Box display="flex" paddingY={1}>
            <FormikTextField label={fieldLabel} name={name} />
            {!disabled && (
              <Button onClick={onRemoveFiled}>
                <DeleteIcon />
              </Button>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};
