import { Formik } from "formik";
import { memo } from "react";

import { affiliationsTaskValidation } from "OrganizationValidation/helpers";
import { THCPTableRow } from "Shared";
import { VerificationTaskFragment } from "Shared/graphql/generated";

import { RowForm } from "./RowForm";

export type RowProps = THCPTableRow & {
  onHCPTaskSubmit?: (task: VerificationTaskFragment) => void;
};

export const Row = memo(({ initialValues, ...rest }: RowProps) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}}
      validationSchema={affiliationsTaskValidation}
      validateOnMount
    >
      <RowForm {...rest} />
    </Formik>
  );
});
