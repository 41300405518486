import { useCallback } from "react";

import { MutationResponse } from "Shared/graphql/generated";

import { useSnackBarMessage } from "./useSnackBarMessage";

type TOptions = {
  onFail: (message?: string) => void;
};

// TODO: keep improve this handler
export const useResponseHandler = (options?: TOptions) => {
  const { showError } = useSnackBarMessage();

  const onFail = options?.onFail ? options.onFail : showError;

  const handleResponse = useCallback(
    <TRes extends Omit<MutationResponse, "__typename">>(response: TRes) => {
      const { success, errors, message } = response;
      // if operation is not successful check errors array
      // and if errors array is empty show message
      // and if no errors and message show `Something went wrong` error
      if (!success) {
        if (errors.length) {
          const errorMessages = errors.map(({ message }) => message).join(", ");
          onFail(errorMessages);
        } else if (message) {
          onFail(message);
        } else {
          onFail();
        }
      }

      return { isSuccess: success, response };
    },
    [onFail]
  );

  return { handleResponse };
};
