import {
  createContext,
  ReactNode,
  useContext,
  useCallback,
  useState,
  useMemo
} from "react";
import { Snackbar } from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

import type { AlertProps } from "@material-ui/lab";

type TAlertColor = "success" | "info" | "warning" | "error";

type TSnackbarContent = { message: string; type: TAlertColor; time?: number };

export type TModalsSetSnackBar = ({ message, type }: TSnackbarContent) => void;

export type TSnackbarValue = {
  setSnackbar: TModalsSetSnackBar;
  closeSnackbar: () => void;
};

const defaultValue: TSnackbarValue = {
  setSnackbar: () => {},
  closeSnackbar: () => {}
};

export const SnackbarContext = createContext<TSnackbarValue>(defaultValue);

const { Provider } = SnackbarContext;

type Props = {
  children: ReactNode;
};

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function SnackbarProvider({ children }: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [content, setContent] = useState<null | TSnackbarContent>(null);

  const setSnackbar = useCallback(
    (data: TSnackbarContent) => {
      setContent(data);
      setIsOpen(true);
    },
    [setContent, setIsOpen]
  );

  const closeSnackbar = useCallback(() => {
    setContent(null);
    setIsOpen(false);
  }, [setContent, setIsOpen]);

  const value: TSnackbarValue = useMemo(
    () => ({
      setSnackbar,
      closeSnackbar
    }),
    [setSnackbar, closeSnackbar]
  );

  return (
    <Provider value={value}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={isOpen}
        autoHideDuration={content?.time || 15000}
        onClose={closeSnackbar}
        message={content?.message}
      >
        <Alert onClose={closeSnackbar} severity={content?.type}>
          {content?.message}
        </Alert>
      </Snackbar>
      {children}
    </Provider>
  );
}

export const useSnackbarContext = (): TSnackbarValue =>
  useContext(SnackbarContext);
