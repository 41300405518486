export const initialDateRangeFieldData = {
  days: new Array(7).fill(false),
  open: "09:00",
  close: "17:00"
};

export const twentyFourSevenDateRangeData = {
  days: new Array(7).fill(true),
  open: "00:00",
  close: "23:59"
};
