import { useCallback } from "react";

import { useResponseHandler } from "Shared";
import {
  MutationResponse,
  RequestTokenMutationVariables,
  useRequestTokenMutation
} from "Shared/graphql/generated";

export const useRequestToken = () => {
  const { handleResponse } = useResponseHandler();

  const [requestTokenMutation, { loading }] = useRequestTokenMutation({
    fetchPolicy: "no-cache"
  });

  const requestToken = useCallback(
    async (variables: RequestTokenMutationVariables) => {
      const res = await requestTokenMutation({ variables });
      const requestToken = res.data?.requestToken;

      if (requestToken) {
        const { isSuccess } = handleResponse<MutationResponse>(requestToken);

        return isSuccess;
      }
    },
    [requestTokenMutation, handleResponse]
  );

  return {
    requestToken,
    isLoading: loading
  };
};
