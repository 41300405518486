// TODO: think about return type, currently it's Record<string, unknown>
export const convertEmptyStringToUndefinedForPreviouslyUndefined = <
  T extends Record<string, unknown>
>(
  newObject: T,
  prevObject: T
): Partial<T> => {
  return Object.entries(newObject).reduce((acc: Partial<T>, [key, value]) => {
    acc[key as keyof T] =
      value === "" && prevObject[key] === undefined
        ? undefined
        : (value as T[keyof T]);

    return acc;
  }, {});
};
