import {
  transformOperationHoursToInitialValues,
  transformPhoneNumbersToInitialValues,
  nullValuesToUndefined,
  transformAcceptingNewPatientToInitialValue,
  TValidateTaskFromInitialValues
} from "Shared";
import {
  TaskPhoneNumber,
  VerificationDataFragment
} from "Shared/graphql/generated";

export const getAffiliationFormInitialValues = ({
  taskData,
  phoneNumbers,
  notes
}: {
  taskData?: VerificationDataFragment;
  phoneNumbers?: TaskPhoneNumber[];
  notes?: string | null;
}): TValidateTaskFromInitialValues => {
  const transformedData = nullValuesToUndefined(taskData);
  const { operationHours, isAcceptNewPatients, ...restFormData } =
    transformedData;

  const { hoursOfOperationDepartment, hoursOfOperationOrganization } =
    transformOperationHoursToInitialValues(operationHours || []);
  const initialPhoneNumbers =
    transformPhoneNumbersToInitialValues(phoneNumbers);

  const initialAcceptNewPatient =
    transformAcceptingNewPatientToInitialValue(isAcceptNewPatients);

  const initialValues = {
    notes: notes ?? undefined,
    phoneNumbers: initialPhoneNumbers,
    hoursOfOperationDepartment,
    hoursOfOperationOrganization,
    isAcceptNewPatients: initialAcceptNewPatient,
    ...restFormData
  };

  return initialValues;
};
