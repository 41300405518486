import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from "@material-ui/core";

type ModalProps = React.FC<{
  isOpen: boolean;
  onClose: () => void;
  title: string;
}>;

export const ModalContent = DialogContent;
export const ModalAction = DialogActions;

// it's small abstraction component to build more reusable modals
// use ModalAction and ModalContent as children to build modal view
export const Modal: ModalProps = ({ isOpen, onClose, title, children }) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      {children}
    </Dialog>
  );
};
