import { useParams } from "react-router";
import { Box, makeStyles } from "@material-ui/core";

import {
  GenericContainer,
  Theme,
  useSetDocumentTitle,
  PageProps,
  transformOrgTaskToInitialValues
} from "Shared";
import { useFindOrganizationTaskQuery } from "Shared/graphql/generated";

import {
  PreventNavigationPopupContextProvider,
  OrganizationContextProvider
} from "./context";
import {
  HCPTasksSection,
  OrganizationVerificationSection,
  OrganizationInformationSection
} from "./sections";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    flexGrow: 1,
    display: "flex",
    backgroundColor: theme.palette.grey["50"],
    flexDirection: "column",
    minHeight: "100vh",
    paddingTop: 16
  }
}));

export const OrganizationValidationPage = ({ title }: PageProps) => {
  const { container } = useStyles();
  const { orgId = "" } = useParams<{ orgId: string }>();

  const { loading: isLoading, data } = useFindOrganizationTaskQuery({
    variables: { orgId }
  });

  const taskData = data?.findOrganizationTask;

  const documentTitle = `${title} ${taskData?.organization?.name || ""} | ${
    taskData?.orgMasterId || orgId
  }`;

  useSetDocumentTitle(documentTitle);

  return (
    <GenericContainer
      isLoading={isLoading}
      data={taskData}
      noDataMessage="Organization NPI not found"
    >
      {(data) => {
        const orgDataInitialValues = transformOrgTaskToInitialValues(data);

        return (
          <PreventNavigationPopupContextProvider>
            <Box className={container} paddingTop={4}>
              <OrganizationInformationSection
                orgId={data.orgMasterId}
                orgName={data.organization?.name || undefined}
                orgAddress={
                  data.organization?.institutionAddress?.address || undefined
                }
                orgType={data.organization?.type || undefined}
                // TODO: add orgNPIs
              />
              <OrganizationContextProvider initialValues={orgDataInitialValues}>
                <OrganizationVerificationSection
                  initialValues={orgDataInitialValues}
                  taskId={data.id}
                  sessionId={data.lastSession?.id}
                  lastUpdatedBy={data.lastSession?.lastUpdate?.createdBy?.email}
                  lastUpdatedOn={data.lastSession?.lastUpdate?.created}
                  completedBy={data.completedBy?.email}
                  completedOn={data.updated}
                />
                <HCPTasksSection orgId={orgId} />
              </OrganizationContextProvider>
            </Box>
          </PreventNavigationPopupContextProvider>
        );
      }}
    </GenericContainer>
  );
};
