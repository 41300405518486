import { Box, Grid } from "@material-ui/core";
import { Form } from "formik";

import {
  AddStates,
  FormikTextField,
  VerifyPhoneContacts,
  ExpandSection,
  FormikAddHoursOfOperations,
  FormikInputMask,
  StatusInfoProps,
  StatusInfo
} from "Shared";
import { OrganizationActionsContainer } from "OrganizationValidation/components";
import { OperationHourType } from "Shared/graphql/generated";

export type OrganizationSectionFormProps = StatusInfoProps & {
  taskId: string;
  sessionId?: string;
};

export const OrganizationSectionForm = ({
  completedBy,
  completedOn,
  lastUpdatedBy,
  lastUpdatedOn,
  taskId,
  sessionId
}: OrganizationSectionFormProps) => {
  const isCompleted = !!completedBy;

  return (
    <Form>
      <Box flexGrow={1} display="flex" flexDirection="column">
        {/* Verify work organization details */}
        <ExpandSection title="Organization Task Verification">
          <Box position="relative">
            <Box
              display="flex"
              flexDirection="column"
              position="absolute"
              right="20px"
              top="-40px"
            >
              <OrganizationActionsContainer
                completed={isCompleted}
                taskId={taskId}
                sessionId={sessionId}
              />
            </Box>
            <Grid container>
              <Grid item xs={6} />
              <Grid item xs={12} md={6}>
                <Box paddingY={3}>
                  <StatusInfo
                    lastUpdatedBy={lastUpdatedBy}
                    lastUpdatedOn={lastUpdatedOn}
                    completedBy={completedBy}
                    completedOn={completedOn}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={8}>
                <VerifyPhoneContacts readonly={isCompleted} canDelete />
              </Grid>
              <Grid item xs={4}>
                <FormikTextField
                  name="notes"
                  variant="outlined"
                  multiline
                  rows={4}
                  placeholder="Notes"
                  disabled={isCompleted}
                  label="Organization Task Verification Notes"
                />
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormikTextField
                      required
                      name="orgName"
                      label="Organization Name"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      name="domainName"
                      label="Org (Domain) URL"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      name="affiliationVerificationUrl"
                      label="Affiliation Verification URL"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      name="affiliationVerificationUrlDetail"
                      label="Affiliation Verification URL Details"
                      multiline
                      rows={3}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikAddHoursOfOperations
                      type={OperationHourType.Organization}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormikTextField
                      name="orgAddressLine1"
                      label="Address Line 1"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      name="orgAddressLine2"
                      label="Address Line 2 (Suite, Floor, etc.)"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormikTextField name="orgAddressLocality" label="City" />
                  </Grid>
                  <Grid item xs={4}>
                    <AddStates name="orgAddressRegion" />
                  </Grid>
                  <Grid item xs={4}>
                    <FormikTextField
                      name="orgAddressPostalCode"
                      label="Zip Code"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormikInputMask
                      mask="999-999-9999"
                      name="orgFaxNumber"
                      label="Fax Number"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </ExpandSection>
      </Box>
    </Form>
  );
};
