import { useEffect } from "react";
import * as FullStory from "@fullstory/browser";

import { useAuth } from "Auth";

export const FullStoryInitializer = () => {
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      FullStory.identify(user.id, {
        displayName: user.email,
        email: user.email
      });
    }
  }, [user]);

  return null;
};
