import { ReactNode } from "react";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
  makeStyles
} from "@material-ui/core";

import { Theme, AlertButtonType } from "Shared";

import { Button } from "./common";

const descriptionMessage = "Are you sure you want to leave the form?";

const useStyles = makeStyles((theme: Theme) => ({
  warningColor: {
    backgroundColor: theme.palette.warning.main,
    "&:hover": {
      backgroundColor: theme.palette.warning.light
    }
  },
  blueButton: {
    backgroundColor: theme.palette.visualizations.blue,
    "&:hover": {
      backgroundColor: theme.palette.visualizations.darkBlue
    }
  }
}));

export type AlertPopupMessage = {
  content: ReactNode;
  cancelBtnText?: string;
  confirmBtnText?: string;
};

type AlertPopupProps = {
  onConfirm: () => void;
  onCancel: () => void;
  description?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  confirmButtonType?: AlertButtonType;
};

export function AlertPopup({
  onConfirm,
  onCancel,
  description = descriptionMessage,
  cancelButtonText = "Stay",
  confirmButtonText = "Leave",
  confirmButtonType = AlertButtonType.WARNING
}: AlertPopupProps): JSX.Element {
  const { warningColor, blueButton } = useStyles();
  const isWarning = confirmButtonType === AlertButtonType.WARNING;
  return (
    <>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          data-testid="alertContent"
        >
          <Box width="500px">{description}</Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box mr={2}>
          <Button
            type="reset"
            onClick={onCancel}
            variant="text"
            data-testid="alertOnConfirm"
          >
            {cancelButtonText}
          </Button>
        </Box>
        <Button
          onClick={onConfirm}
          autoFocus
          data-testid="alertOnCancel"
          className={isWarning ? warningColor : blueButton}
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </>
  );
}
