type Keys = string[];

// This is used for finding default data that exclude
// src/Shared/utils/diff.ts findDifference function

export const getDefaultTransformedFormData = <
  T extends Record<string, unknown>
>(
  formData: T,
  keys: Keys
) => {
  return keys.reduce((accumulator: Record<string, unknown>, key: string) => {
    if (key in formData) {
      accumulator = { ...accumulator, [key]: formData[key] };
    }
    return accumulator;
  }, {});
};
