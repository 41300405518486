import { Box, Typography, makeStyles } from "@material-ui/core";
import LocationCityIcon from "@material-ui/icons/LocationCity";

import { Theme, Chip, getAddressString } from "Shared";
import {
  OrgNPIsMenu,
  SectionContainer
} from "OrganizationValidation/components";
import { AddressFragment } from "Shared/graphql/generated";

const useStyles = makeStyles((theme: Theme) => ({
  cityIcon: {
    color: theme.palette.grey["500"]
  },
  darkGrey: {
    color: theme.palette.grey["600"]
  },
  addressTitle: {
    whiteSpace: "pre-line"
  },
  inlineFlex: {
    display: "inline-flex",
    marginRight: 10
  }
}));

export type OrganizationInformationSectionProps = {
  orgId?: string;
  orgName?: string;
  orgNPIs?: string[]; // TODO: where are NPIs come from?
  orgType?: string;
  orgAddress?: AddressFragment;
};

export const OrganizationInformationSection = ({
  orgName,
  orgId,
  orgType = "Unknown",
  orgNPIs = [],
  orgAddress
}: OrganizationInformationSectionProps): JSX.Element => {
  const { cityIcon, addressTitle, inlineFlex, darkGrey } = useStyles();

  const addressStr = getAddressString(orgAddress);

  return (
    <SectionContainer>
      <Typography variant="h4" className={inlineFlex}>
        {orgName}
      </Typography>
      <Chip label={orgType} />
      <Box mt={5} display="flex" fontSize="16px" alignItems="center">
        <Box mr={1}>
          <LocationCityIcon className={cityIcon} />
        </Box>
        <Typography className={addressTitle}>{addressStr}</Typography>
        <Box marginX={30}>
          <Typography variant="h6" className={`${inlineFlex} ${darkGrey}`}>
            Organization Id
          </Typography>
          <Typography className={inlineFlex}>{orgId}</Typography>
        </Box>
        <OrgNPIsMenu orgNPIs={orgNPIs} />
      </Box>
    </SectionContainer>
  );
};
