import { Box } from "@material-ui/core";

import { Button } from "Shared";

export const ButtonOnNext = ({ disabled }: { disabled: boolean }) => (
  <Box width={130}>
    <Button disabled={disabled} fullWidth type="submit">
      Next
    </Button>
  </Box>
);
