import { Box, makeStyles } from "@material-ui/core";

import { Theme } from "Shared";
import { Person, VerificationTaskFragment } from "Shared/graphql/generated";

import { PersonDetails, AffiliationTasksList } from "./index";

export type AsideProps = {
  person: Person;
  tasks: VerificationTaskFragment[];
  setSelectedTaskId: (id: string) => void;
  selectedTaskId: string | null;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    borderRight: `1px solid ${theme.palette.grey["200"]}`,
    flexGrow: 1,
    flexDirection: "column",
    display: "flex",
    minWidth: "350px",
    maxWidth: "440px"
  },
  selectedBox: {
    padding: "40px 0"
  }
}));

export const Aside = ({ person, tasks }: AsideProps) => {
  const { container, selectedBox } = useStyles();

  return (
    <>
      <Box component="aside" className={container}>
        <Box className={selectedBox}>
          <PersonDetails {...person} />
        </Box>
        <Box paddingY={3} borderTop={1}>
          <AffiliationTasksList tasks={tasks} />
        </Box>
      </Box>
    </>
  );
};
