import { IconButton } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import type { IconButtonProps } from "@material-ui/core";

type ToggleIconProps = Omit<IconButtonProps, "onClick"> & {
  isOpen: boolean;
  onToggle: () => void;
  ariaLabel?: string;
};

export const ToggleIcon = ({
  isOpen,
  onToggle,
  ariaLabel = "expand row",
  size = "small"
}: ToggleIconProps) => {
  return (
    <IconButton aria-label={ariaLabel} size={size} onClick={() => onToggle()}>
      {isOpen ? <ExpandLessIcon /> : <KeyboardArrowDownIcon />}
    </IconButton>
  );
};
