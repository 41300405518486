import { Form } from "formik";
import { Grid, Box, Typography } from "@material-ui/core";

import { FormikTextField, Button } from "Shared";

type SignInFormProps = {
  isLoading: boolean;
};

export const SignInForm = ({ isLoading }: SignInFormProps) => (
  <>
    <Form>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={9}>
          <FormikTextField
            autoComplete="email"
            name="email"
            label="Email Address"
            type="email"
          />
        </Grid>
        <Grid item xs={9}>
          <Box display="flex" mt={6} justifyContent="center">
            <Button type="submit" disabled={isLoading}>
              Sign in
            </Button>
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Box maxWidth="350px" whiteSpace="normal">
            <Typography align="center">
              You will receive an email with a password-free single use link to
              access the site.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Form>
  </>
);
