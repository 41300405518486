import { Formik } from "formik";

import { verifyHCPPhoneNumbersValidation } from "OrganizationValidation/helpers";
import { HCPTaskData } from "OrganizationValidation/hooks";
import { VerifyPhoneContacts } from "Shared";

import { WatchForVerifyPhoneContacts } from "./WatchForVerifyPhoneContacts";

export type CallAndVerifyDetailsFormProps = Pick<
  HCPTaskData,
  "initialPhoneNumbers"
>;

export const CallAndVerifyDetailsForm: React.FC<
  CallAndVerifyDetailsFormProps
> = ({ initialPhoneNumbers }) => {
  return (
    <Formik
      initialValues={{ phoneNumbers: initialPhoneNumbers }}
      validationSchema={verifyHCPPhoneNumbersValidation}
      onSubmit={() => {}}
      validateOnMount
    >
      <WatchForVerifyPhoneContacts>
        <VerifyPhoneContacts />
      </WatchForVerifyPhoneContacts>
    </Formik>
  );
};
