import { TextField, Box, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { TAutocompleteOption } from "Shared";

import type { TextFieldProps } from "@material-ui/core";

export type TAutocomplete = {
  required?: boolean;
  label?: string;
  autoComplete?: string;
  name: string;
  options: TAutocompleteOption[];
  subText?: string;
  shrink?: boolean;
  sort?: (option: TAutocompleteOption) => number;
  disableClearable?: boolean;
  disabled?: boolean;
  style?: Record<string, string | number>;
  multiple?: boolean;
  handleChange: (
    event: React.ChangeEvent<Record<string, unknown>>,
    options: TAutocompleteOption | TAutocompleteOption[] | null
  ) => void;
  onBlur: (event: React.ChangeEvent<HTMLDivElement>) => void;
  autocompleteValue: TAutocompleteOption[] | TAutocompleteOption | null;
};

export type AutocompleteProps = TAutocomplete &
  Omit<TextFieldProps, "variant" | "onBlur">;

export const AutocompleteField = ({
  required,
  label,
  value,
  subText,
  shrink,
  options,
  disabled = false,
  disableClearable = false,
  autoComplete = "chrome-off",
  error,
  helperText,
  onBlur,
  style,
  name,
  multiple,
  handleChange,
  autocompleteValue
}: AutocompleteProps): JSX.Element => {
  return (
    <>
      <Autocomplete
        multiple={multiple}
        options={options}
        getOptionLabel={(option) => option?.label || ""}
        getOptionSelected={(option, value) => option.value === value.value}
        filterSelectedOptions
        onChange={handleChange}
        value={autocompleteValue}
        onBlur={onBlur}
        disabled={disabled}
        disableClearable={disableClearable}
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            name={name}
            style={style}
            value={value}
            label={label}
            variant="outlined"
            error={error}
            helperText={helperText}
            autoComplete={autoComplete}
            InputLabelProps={{
              shrink
            }}
          />
        )}
      />
      {subText && (
        <Box pr={2} pl={2}>
          <Typography variant="caption">{subText}</Typography>
        </Box>
      )}
    </>
  );
};
