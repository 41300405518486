import { TableRow } from "@material-ui/core";
import { useEffect } from "react";
import { useFormikContext } from "formik";

import { usePreventNavigationPopupContext } from "OrganizationValidation/context";

import { useHCPTableContext, useStyles } from "./index";

type ManagedDirtyRowProps = React.FC<{
  id: string;
}>;

export const ManagedDirtyRow: ManagedDirtyRowProps = ({ children, id }) => {
  const { topRow, activeTopRow } = useStyles();
  const { readonly } = useHCPTableContext();
  const { dirty } = useFormikContext();

  const { makeRowDirty } = usePreventNavigationPopupContext();

  useEffect(() => {
    if (!readonly) {
      makeRowDirty({ isDirty: dirty, id });
    }
  }, [dirty, makeRowDirty, readonly, id]);

  return (
    <TableRow className={dirty ? activeTopRow : topRow}>{children}</TableRow>
  );
};
