import { createContext, useContext } from "react";

const hcpTableContextValues = {
  readonly: false
};
const HCPTableContext = createContext(hcpTableContextValues);

export type THCPTableContextValues = typeof hcpTableContextValues;

export const HCPTableContextProvider: React.FC<THCPTableContextValues> = ({
  readonly,
  children
}) => {
  return (
    <HCPTableContext.Provider value={{ readonly }}>
      {children}
    </HCPTableContext.Provider>
  );
};

export const useHCPTableContext = () => useContext(HCPTableContext);
