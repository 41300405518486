import { Box, Container, Typography, makeStyles } from "@material-ui/core";
import { memo, useCallback } from "react";

import {
  PhoneRow,
  handleAddPhone,
  AddPhoneNumber,
  TFromPhoneNumber,
  Theme,
  TAddPhone
} from "Shared";

import type { ArrayHelpers } from "formik/dist/FieldArray";

type FieldArrayItemProps = {
  value: TFromPhoneNumber[];
  fieldName: string;
  readonly?: boolean;
  canDelete?: boolean;
  sectionBorder?: boolean;
  push: ArrayHelpers["push"];
  remove: ArrayHelpers["remove"];
};

const useStyles = makeStyles((theme: Theme) => ({
  sectionBorderColor: {
    borderColor: theme.palette.grey[100]
  }
}));

export const FieldArrayItem = memo(
  ({
    value,
    readonly,
    fieldName,
    canDelete,
    sectionBorder,
    push,
    remove
  }: FieldArrayItemProps) => {
    const { sectionBorderColor } = useStyles();

    const onAdd = useCallback(
      (phone: TAddPhone) => handleAddPhone(phone, push),
      [push]
    );

    return (
      <Box
        paddingY={2}
        borderBottom={sectionBorder ? 1 : 0}
        className={sectionBorderColor}
      >
        <fieldset disabled={readonly} style={{ border: "none" }}>
          <Container>
            <Box display="flex" alignItems="center">
              <Typography variant="h6">
                Call and verify phone contact details:
              </Typography>
              <Box ml={2}>
                <AddPhoneNumber onAdd={onAdd} />
              </Box>
            </Box>
            {value.map(({ number, isNotSaved }, i) => (
              <PhoneRow
                name={`${fieldName}.${i}`}
                index={i}
                key={number}
                canDelete={canDelete}
                isNotSaved={isNotSaved}
                remove={remove}
                readonly={readonly}
              />
            ))}
          </Container>
        </fieldset>
      </Box>
    );
  }
);
