import { Box, CircularProgress, Typography } from "@material-ui/core";
import { ReactNode } from "react";

export type GenericContainerProps<TData> = {
  isLoading: boolean;
  data: TData | undefined | null;
  children: (data: TData) => ReactNode;
  noDataMessage?: string;
};

export const GenericContainer = <T,>({
  isLoading,
  data,
  noDataMessage = "Something went wrong",
  children
}: GenericContainerProps<T>) => {
  if (isLoading) {
    return (
      <Box mt={8} display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (data) {
    return <>{children(data)}</>;
  }

  return (
    <Box mt={8} display="flex" justifyContent="center">
      <Typography>{noDataMessage}</Typography>
    </Box>
  );
};
